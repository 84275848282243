// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Deals_wrapper__edcVS {
  margin-right: 1rem;
}
.Deals_wrapper-header__c2ug\\+ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.Deals_wrapper-header-buttons__28GuT {
  display: flex;
  justify-content: end;
  gap: 1rem;
}
.Deals_wrapper-header-line__wTb86 {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 1rem;
  align-items: center;
}
.Deals_wrapper-header-line-element__0FQfm {
  display: flex;
}
.Deals_wrapper-stats__Eo1ny {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.Deals_wrapper__edcVS .Deals_input__CIXXD {
  width: 100%;
  height: 45px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid rgba(136, 134, 134, 0.5);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Deals/Deals.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAEJ;AADI;EACE,aAAA;EACA,oBAAA;EACA,SAAA;AAGN;AAAI;EACE,WAAA;EACA,aAAA;EACA,oBAAA;EACA,SAAA;EACA,mBAAA;AAEN;AADM;EACE,aAAA;AAGR;AAEE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,0CAAA;EACA,mBAAA;AADJ","sourcesContent":[".wrapper{\n  margin-right: 1rem;\n  &-header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 1rem;\n    &-buttons{\n      display: flex;\n      justify-content: end;\n      gap: 1rem;\n    }\n\n    &-line{\n      width: 100%;\n      display: flex;\n      justify-content: end;\n      gap: 1rem;\n      align-items: center;\n      &-element{\n        display: flex;\n      }\n    }\n  }\n\n  &-stats{\n    display: flex;\n    gap: 1rem;\n    margin-bottom: 1rem;\n  }\n\n  .input{\n    width: 100%;\n    height: 45px;\n    padding: 12px 20px;\n    box-sizing: border-box;\n    border: 1px solid rgba(136, 134, 134, .5);\n    border-radius: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Deals_wrapper__edcVS`,
	"wrapper-header": `Deals_wrapper-header__c2ug+`,
	"wrapper-header-buttons": `Deals_wrapper-header-buttons__28GuT`,
	"wrapper-header-line": `Deals_wrapper-header-line__wTb86`,
	"wrapper-header-line-element": `Deals_wrapper-header-line-element__0FQfm`,
	"wrapper-stats": `Deals_wrapper-stats__Eo1ny`,
	"input": `Deals_input__CIXXD`
};
export default ___CSS_LOADER_EXPORT___;
