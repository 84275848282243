import {createSlice} from "@reduxjs/toolkit";

interface ISortAndFilterTrip{
    startDate: number,
    endDate: number,
    lowCarbon: boolean,
    visibility: string,
    type: string,
}

const initialStateSortAndFilter: ISortAndFilterTrip ={
    startDate: 0,
    endDate: 0,
    lowCarbon: false,
    visibility: "",
    type:""
}

const sortAndFilterSlice = createSlice({
    name:"sortAndFilterTrip",
    initialState: initialStateSortAndFilter,
    reducers:{
        setStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setEndDate: (state, action)=> {
            state.endDate = action.payload
        },
        setLowCarbon: (state, action)=>{
            state.lowCarbon = action.payload
        },
        setVisibility: (state, action) => {
            state.visibility = action.payload
        }
    }
})

export const actions = {
    ...sortAndFilterSlice.actions,
};

export const { setStartDate, setEndDate,  setVisibility, setLowCarbon } = sortAndFilterSlice.actions
export default sortAndFilterSlice.reducer;