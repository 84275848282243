import * as React from "react";
import style from "./ProfilePTM.module.scss";

interface IProfilePTMProps {}

const ProfilePTM: React.FunctionComponent<IProfilePTMProps> = (props) => {
	return (
		<>
			<div className={style["wrapper"]}>
				<div className={style["wrapper-profile-img"]}>
					<img src="/img/profile-img.jpg" alt="profile" />
				</div>
				<div className={style["wrapper-info"]}>
					<div className={style["wrapper-info-userName"]}>Johanna Moro</div>
					<div className={style["wrapper-info-age"]}>Femme, 24 ans</div>
					<div className={style["wrapper-info-status"]}>Bro'Kit</div>
				</div>
			</div>
			<div className={style["essential"]}>
				<div className={style["essential__title"]}>Informations essentielles</div>
				<div className={style["essential-wrapper"]}>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Langue parlé</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Français</li>
							<li className={style["essential-container__tag"]}>Anglais</li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>L'exp. de voyage</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Baroudeur (5-10 pays)</li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>MBTI</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>ENFP</li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Personnalité</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Extraverti</li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Caractère</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Réservé</li>
						</ul>
					</div>
				</div>
			</div>
			<div className={style["essential"]}>
				<div className={style["essential__title"]}>Les habitudes de voyage</div>
				<div className={style["essential-wrapper"]}>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Elle fume</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Tous les jours</li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Elle bois</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>En soirée</li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>{`Pour le ménage, c’est`}</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Rohh ça va</li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>{`Pour la nourriture, c'est`}</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Ici ça grail</li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Habitude de dépense</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Le moins possible </li>
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>En vacances, elle est plus</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>Que la mala</li>
							<li className={style["essential-container__tag"]}>Organisé</li>
							<li className={style["essential-container__tag"]}>Matinale</li>
							<li className={style["essential-container__tag"]}>Immortalisé le mmt</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfilePTM;
