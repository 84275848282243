import style from "./NewLocation.module.scss"
import InputText from "../IU/Input/InputText";
import Loader from "../IU/Loader/Loader";
import {useState} from "react";
import {
    citiesLocation_citiesLocation_cities
} from "../../graphql/queries/__generated__/citiesLocation";
import Button from "../IU/Button/Button";


interface ILocationForm {
    handleSubmit: any,
    formErrors: any,
    location?: citiesLocation_citiesLocation_cities,
    deleteLocationHandler?: any
}
const LocationForm = ({ handleSubmit, formErrors, location, deleteLocationHandler}: ILocationForm) => {
    const [isLoading, setIsLoading] = useState(false);

    return(

        <form onSubmit={(e)=>{handleSubmit(e)}}>
            <div className={style["container"]}>

                        <div className={style["body"]}>
                            <InputText name={"country"} label={"Pays"} isCorrect={!formErrors.hasOwnProperty('countryName')} defaultValue={location?.countryName}/>
                            <div className={style["group"]}>
                                <InputText name={"state"} label={"Région"} isCorrect={!formErrors.hasOwnProperty('stateName')} defaultValue={location?.stateName}/>
                                <InputText name={"city"} label={"Ville"} isCorrect={!formErrors.hasOwnProperty('name')} defaultValue={location?.name}/>
                            </div>

                            <InputText name={"lat"} label={"Latitude"} isCorrect={!formErrors.hasOwnProperty('latitude')} defaultValue={location?.latitude}/>
                            <InputText name={"lon"} label={"Longitude"} isCorrect={!formErrors.hasOwnProperty('longitude')} defaultValue={location?.longitude}/>
                        </div>
                <div className={style['buttons']}>
                    { location?.id &&
                        <Button
                            text={"Supprimer"}
                            color={"#FFF"}
                            backgroundColor={"#DD4646"}
                            width={140}
                            height={50}
                            onClick={() => {
                                deleteLocationHandler(location?.id)
                            }}
                            borderRadius={10}
                        />}
                    <button type={"submit"} className={`${style['btn']} ${(isLoading) && style['btn__disabled']}`} disabled={isLoading}>
                        Soumettre
                        {isLoading &&
                            <div className={style['btn-loader']}>
                                <Loader height={30}/>
                            </div>
                        }
                    </button>
                </div>
            </div>
        </form>
    )
}

export default LocationForm

