import style from './InputWithIcon.module.scss'
import React from "react";



interface IInputWithIconProps {

    placeholder?: string
    prefixIcon?: any
    suffixIcon?: any
    defaultValue?: any
    name: string
}
const InputWithIcon = ({prefixIcon, suffixIcon, name, placeholder,defaultValue}: IInputWithIconProps) => {


    return(
        <div className={style['wrapper']}>
            {prefixIcon && (
                <div style={prefixIcon.style} className={style["prefix-icon"]}>
                    <img src={prefixIcon.url} alt={prefixIcon.alt} />
                </div>
            )}
            <input className={style['wrapper__input']} placeholder={placeholder ? placeholder : ''} name={name}  defaultValue={defaultValue}/>
            {suffixIcon && (
                <div style={suffixIcon.style} className={style["suffix-icon"]}>
                    <img src={suffixIcon.url} alt={suffixIcon.alt} />
                </div>
            )}
        </div>
    )
}

export default InputWithIcon