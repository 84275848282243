import {gql} from "@apollo/client";

export const GET_TRIP = gql`
    query GetTrip($id: String!) {
        trip(id: $id) {
            id
            affiliationLink
            country{
                id
                name
            }
            city{
                id
                name
            }
            continent
            startDate
            endDate
            numberOfNights
            numberOfDays
            departure{
                id
                name
            }
            arrival{
                id
                name
            }
            shortDescription
            longDescription
            price
            departureStation
            type
            travelWith
            visibility
            provider
            numberOfPassengers
            medias {
                id
                url
            }
            categories{
                id
                name
            }
            contexts{
                id
                name
            }
            currency
        }
    }`

export const LIST_TRIP = gql`
    query ListTrip($id: Int!, $limit: Int!, $sort: SortAndFilter) {
        trips(page: $id, limit: $limit, sort: $sort) {
            id
            visibility
            country{
                id
               name
            }
            city{
                id
                name
            }
            categories{
                id
                name
            }
            contexts{
                id
                name
            }
            price
            departure{
                id
                name
            }
            numberOfPassengers
            startDate
            endDate
            longDescription
            shortDescription
            medias{
                id
                url
            }
            inbound{
                departingAt
            }
            outbound{
                arrivingAt
            }
            type
            lowCarbonTravel,
            numberOfNights,
            numberOfDays,
            transportationType,
            createdAt,
            updatedAt,
            provider,
            departureStation,
            departureStation,
            deletedAt
        }
    }`

export const SEARCH_TRIP_ADMIN = gql`
    query TripsAdmin($page: Int!, $limit: Int!, $search: SearchTripInput!, $sort: SortAndFilter!) {
        tripsAdmin(page: $page, limit: $limit, search: $search, sortAndFilter: $sort) {
            trips{
                id
                visibility
                country{
                    id
                   name
                }
                city{
                    id
                    name
                }
                categories{
                    id
                    name
                }
                contexts{
                    id
                    name
                }
                price
                departure{
                    id
                    name
                }
                numberOfPassengers
                startDate
                endDate
                longDescription
                shortDescription
                medias{
                    id
                    url
                }
                inbound{
                    departingAt
                }
                outbound{
                    arrivingAt
                }
                type
                lowCarbonTravel,
                numberOfNights,
                numberOfDays,
                transportationType,
                createdAt,
                updatedAt,
                provider,
                departureStation,
                departureStation,
                deletedAt
            }
            totalCount
            
        }
    }`


export const GET_TRIP_CONTEXTS = gql`
    query getTripContexts($page: Int!, $limit: Int!){
        tripContexts(page: $page, limit: $limit){
            id
            name
            media{
                id
                url
                name
            }
        }
    }
`

export const GET_TRIP_CATEGORIES = gql`
    query getTripCategories($page: Int!, $limit: Int!){
        tripCategories(page: $page, limit: $limit){
             id
            name
            icon{
                id
                url
                name
            }
            media{
                id
                url
                name
            }
        }
    }
`

export const SEARCH_TRIPS = gql`
  query SearchTrips(
    $search: SearchTripInput!
    $page: Int!
    $limit: Int!
    $orderBy: Int
  ) {
    searchTrips(
      search: $search
      page: $page
      limit: $limit
      orderBy: $orderBy
    ) {
      id
      provider
      country {
        id
        name
      }
      city {
        id
        name
      }
      continent
      startDate
      endDate
      lowCarbonTravel
      affiliationLink
      numberOfNights
      numberOfDays
      transportationType
      departure {
        id
        name
      }
      arrival {
        id
        name
      }
      shortDescription
      longDescription
      price
      departureStation
      type
      travelWith
      visibility
      numberOfPassengers
      numberOfBaggageChecked
      numberOfBaggageCarriedOn
      categories {
        id
        name
      }
      contexts {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;