// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArrowShort_arrow__Y95RZ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.ArrowShort_arrow--circle__qDi6H {
  border-radius: 50%;
  border: 1px solid #B8B8BC;
}
.ArrowShort_arrow--up__VKTt4 {
  transform: rotate(180deg);
}
.ArrowShort_arrow--left__eTIEb {
  transform: rotate(-90deg);
}
.ArrowShort_arrow--right__IiXcW {
  transform: rotate(90deg);
}
.ArrowShort_arrow--active__dc3vw {
  border-color: #353472;
}
.ArrowShort_arrow__Y95RZ img {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/ArrowShort/ArrowShort.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AACJ;AACI;EACI,kBAAA;EACA,yBAAA;AACR;AAEI;EACI,yBAAA;AAAR;AAGI;EACI,yBAAA;AADR;AAII;EACI,wBAAA;AAFR;AAKI;EACI,qBAAA;AAHR;AAMI;EACI,oBAAA;AAJR","sourcesContent":[".arrow {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 23px;\n    height: 23px;\n    cursor: pointer;\n\n    &--circle {\n        border-radius: 50%;\n        border: 1px solid #B8B8BC;\n    }\n\n    &--up {\n        transform: rotate(180deg);\n    }\n\n    &--left {\n        transform: rotate(-90deg);\n    }\n\n    &--right{\n        transform: rotate(90deg);\n    }\n\n    &--active {\n        border-color: #353472;\n    }\n\n    img {\n        pointer-events: none;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": `ArrowShort_arrow__Y95RZ`,
	"arrow--circle": `ArrowShort_arrow--circle__qDi6H`,
	"arrow--up": `ArrowShort_arrow--up__VKTt4`,
	"arrow--left": `ArrowShort_arrow--left__eTIEb`,
	"arrow--right": `ArrowShort_arrow--right__IiXcW`,
	"arrow--active": `ArrowShort_arrow--active__dc3vw`
};
export default ___CSS_LOADER_EXPORT___;
