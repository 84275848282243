import style from './Contexts.module.scss'
import AddContext from "./AddContext";
import ListContexts from "./ListContexts";
import React, {useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {GET_TRIP_CONTEXTS} from "../../graphql/queries/trip";
import Loader from "../IU/Loader/Loader";
import {
    getTripContexts,
    getTripContexts_tripContexts,
    getTripContextsVariables
} from "../../graphql/queries/__generated__/getTripContexts";

const LIMIT = 10;
const Contexts = () => {

    const [currentContext, setCurrentContext] = useState<getTripContexts_tripContexts>()
    const [page, setPage] = useState(0)
    const [getTripContexts, {loading, error, data}] = useLazyQuery<getTripContexts, getTripContextsVariables>(GET_TRIP_CONTEXTS, { variables: {page: page*LIMIT, limit: LIMIT}})
    const [tripContexts, setTripContexts] = useState<getTripContexts_tripContexts[]>()

    useEffect(()=>{
        getTripContexts()

    },[tripContexts])
    if (loading) return <div className={style['container']}><Loader/></div>;
    if (error) {
        return <div className={style['container']}>`Error! ${error.message}`</div>;
    }
    return(
        <div className={style['container']}>
            <AddContext context={currentContext} setContext={setCurrentContext} setTripContexts={setTripContexts} page={page}/>
            <ListContexts setCurrentContext={setCurrentContext} tripContexts={data?.tripContexts} setTripContexts={setTripContexts} page={page} setPage={setPage}/>
        </div>
    )
}

export default Contexts