import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/queries/user";
import {
  getUser,
  getUserVariables,
} from "../../graphql/queries/__generated__/getUser";
import AddUser from "./AddUser/AddUser";

const User = () => {
  const params = useParams();
  const { loading, error, data } = useQuery<getUser, getUserVariables>(
    GET_USER,
    { variables: { id: params?.id! }, fetchPolicy: "network-only" }
  );
  console.log("Logged Output ~ User ~ data:", data);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>`Error! ${error.message}`</div>;
  return <AddUser user={data?.user} />;
};

export default User;
