import React, {useState} from "react";
import style from "./Navbar.module.scss";
import {useDispatch} from "react-redux";
import {authAction} from "../store/auth-slice";
import {useNavigate} from "react-router-dom";
import {BNA_AUTH_TOKEN, BNA_USER_CONTEXT} from "../globals";
const Navbar = () => {
    const [isShow, setIsShow] = useState(false)
    const dispatch = useDispatch()
    const user  = JSON.parse(localStorage.getItem(BNA_USER_CONTEXT) || '')
    const navigate = useNavigate()

    const logoutHandler = () => {
        dispatch(authAction.logout())
        navigate("/login")
    }

    return(
        <header className={style['header']}>
            <div className={style['header-user']} onClick={()=>{setIsShow(!isShow)}}>
               <div className={style['header-username']}>{`${user?.username}`}</div>
               <div className={style['header-user-background']} style={{background: `url(${user?.media?.url})no-repeat 50%/contain`}}></div>
               <img src={"/svg/arrow-down.svg"} alt={"arrow"}/>
            </div>
            { isShow &&
                <div className={style['dropdown']}>
                    <div className={style['dropdown-option']} onClick={logoutHandler}>
                        <img src="/svg/logout.svg" alt="update"/> Déconnecter
                    </div>
                </div>

            }
        </header>
    )
}
export default Navbar;