import style from "./BestArticleTable.module.scss";
import {bestArticles, bestTrips} from "../../data/TripsData";
import React from "react";

const BestArticleTable = () => {
    return(
        <div className={style['container']}>
            <table>
                <thead className={style['container-header']}>
                <tr>
                    <th className={style['container-header-first']}> </th>
                    <th className={style['container-header-second']}>Date de publication</th>
                    <th className={style['container-header-third']}>Titre</th>
                    <th className={style['container-header-fourth']}>Tags</th>
                    <th className={style['container-header-fifth']}>Visites</th>
                    <th className={style['container-header-sixth']}>Commentaires</th>
                </tr>
                </thead>

                <tbody className={style['container-body']}>
                {bestArticles!.map((article) => (
                        <tr key={article.id} className={style['container-body-row']}>
                            <td className={style['']}>
                                <div className={`${style[`container-body-row-rank-r${article.id}`]} ${style['container-body-row-rank']}`}>
                                    {article.id}
                                </div>

                            </td>
                            <td className={style['container-body-row-createdAt']}>
                                {article.createdAt.toLocaleDateString()}
                            </td>
                            <td className={style['container-body-row-title']}>
                                {article.title}
                            </td>
                            <td className={style['container-body-row-tags']}>
                                {article.tags}
                            </td>
                            <td className={style['container-body-row-views']}>
                                {article.views}
                            </td>
                            <td className={style['container-body-row-comments']}>
                                {article.comments}
                            </td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
        </div>
    )
}

export default BestArticleTable