import style from "./ArrowShort.module.scss";

export default function ArrowShort({ onClick, direction, circle, active }: any) {

  let classes = style.arrow;
  let alt = "";

  if (active && circle) {
    classes += ` ${style["arrow--active"]}`;
  }

  if (circle) {
    classes += ` ${style["arrow--circle"]}`;
  }

  switch (direction) {
    case "up":
      alt = "flèche haut";
      classes += ` ${style["arrow--up"]}`;
      break;

    case "right":
      alt = "flèche droite";
      classes += ` ${style["arrow--right"]}`;
      break;

    case "left":
      alt = "flèche gauche";
      classes += ` ${style["arrow--left"]}`;
      break;

    default:
      alt = "flèche bas";
      break;
  }

  return (
    <div onClick={onClick} className={classes}>
      <img
        src={active ? "/svg/chevron_active.svg" : "/svg/chevron-down.svg"}
        alt={alt}
      />
    </div>
  );
}

