import React, {useEffect, useRef, useState} from "react";
import style from "./ToggleButton.module.scss";

interface IToggleOption {
	label: string;
	value: string;
	onClick: () => void;
}

interface IToggleButtonProps {
	options: IToggleOption[];
}

const ToggleButton: React.FC<IToggleButtonProps> = ({options}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedLabel, setSelectedLabel] = useState(options[0]?.label);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div ref={containerRef} className={style["toggleButtonContainer"]}>
			<button
				className={`${style["toggleButtonContainer-toggleButton"]} ${
					isOpen ? style["toggleButtonContainer-toggleButton__active"] : ""
				}`}
				onClick={() => setIsOpen(!isOpen)}
			>
				{selectedLabel}{" "}
				<span>
					<img src="/svg/dropdown-blue.svg" alt="chevron-down" />
				</span>
			</button>

			{isOpen && (
				<div className={style["toggleButtonContainer-optionsContainer"]}>
					{options
						.filter((option) => option.label !== selectedLabel)
						.map((option, index) => (
							<button
								key={index}
								className={style["toggleButtonContainer-optionButton"]}
								onClick={() => {
									setSelectedLabel(option.label);
									setIsOpen(false);
									option.onClick();
								}}
							>
								{option.label}
							</button>
						))}
				</div>
			)}
		</div>
	);
};

export default ToggleButton;
