import style from './InputError.module.scss'

interface IInputErrorProps {
    message: string;
}

export default function InputError({ message }: IInputErrorProps) {
  return (
    <div className={style["input-error"]}>
        <div className={style["warning-icon"]}>
            <img src="/svg/red_warning.svg" alt="icône avertissement" />
        </div>
        <div className={style["input-error__text"]}>
            {message}
        </div>
    </div>
  )
}
