// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newDeal_wrapper__fqDEQ {
  margin-top: 1.5rem;
  margin-bottom: 6rem;
  box-shadow: 2px 1px 15px 3px rgba(0, 0, 0, 0.38);
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 1rem 2rem 0 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
}
.newDeal_wrapper-header__e7kWA {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.newDeal_wrapper-header-title__zHm0Y {
  font-weight: bold;
  font-size: 1rem;
}
.newDeal_wrapper-line__-1AcS {
  margin-top: 1.3rem;
  margin-bottom: 2rem;
  position: relative;
  border: 1px solid #C4BFBF;
  left: -2rem;
  width: calc(100% + 4rem);
}`, "",{"version":3,"sources":["webpack://./src/components/Deals/newDeal.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EAEA,gDAAA;EACA,qCAAA;EACA,mBAAA;EACA,yBAAA;EAEA,kBAAA;EACA,aAAA;EACA,sBAAA;AAAF;AACE;EACE,gBAAA;EACA,mBAAA;AACJ;AAAI;EACE,iBAAA;EACA,eAAA;AAEN;AACE;EACE,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,wBAAA;AACJ","sourcesContent":[".wrapper{\n  margin-top: 1.5rem;\n  margin-bottom: 6rem;\n  -webkit-box-shadow: 2px 1px 15px 3px rgba(0,0,0,0.38);\n  box-shadow: 2px 1px 15px 3px rgb(0 0 0 / 38%);\n  border: 1px solid rgba(0,0,0,0.14);\n  border-radius: 20px;\n  padding: 1rem 2rem 0 2rem;\n\n  margin-right: 2rem;\n  display: flex;\n  flex-direction: column;\n  &-header{\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    &-title{\n      font-weight: bold;\n      font-size: 1rem;\n    }\n  }\n  &-line{\n    margin-top: 1.3rem;\n    margin-bottom: 2rem;\n    position: relative;\n    border: 1px solid #C4BFBF;\n    left: -2rem;\n    width: calc(100% + 4rem);\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `newDeal_wrapper__fqDEQ`,
	"wrapper-header": `newDeal_wrapper-header__e7kWA`,
	"wrapper-header-title": `newDeal_wrapper-header-title__zHm0Y`,
	"wrapper-line": `newDeal_wrapper-line__-1AcS`
};
export default ___CSS_LOADER_EXPORT___;
