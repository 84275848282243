import style from './GalleryForm.module.scss';
import Button from "../../../IU/Button/Button";
import { useState} from "react";

 const GalleryForm = ({medias , setImages, setOpenModal, isMultiple} :any) => {


     const [currentIndex, setCurrentIndex] = useState(0);

     const clickHandler = () => {
         if (medias.length > 0){
             setImages(medias)
             setOpenModal(false)
         }
     }

     const deleteHandler = () => {

     }
     const handleNext = () => {
         if (currentIndex === medias.length - 1) {
             setCurrentIndex(0);
         } else {
             setCurrentIndex(currentIndex + 1);
         }
     };

     const handlePrev = () => {
         if (currentIndex === 0) {
             setCurrentIndex(medias.length - 1);
         } else {
             setCurrentIndex(currentIndex - 1);
         }
     };

     return (
         <div className={style['wrapper']}>
             <div className={style['wrapper__button']}>
                 <Button text={"Valider"} width={180} height={50} color={"#FFF"} backgroundColor={"#2D2C6C"} borderRadius={10} onClick={clickHandler}/>
             </div>
             <div className={style['wrapper__title']}>
                 Détails des images
             </div>
             <img className={style["carousel__image"]} src={medias[currentIndex].url} alt="carousel" />
             {isMultiple &&
                 <div className={style["carousel"]}>
                 <button className={style["carousel__button"]} onClick={handlePrev}>
                 &lt;
                 </button>
                 <button className={style["carousel__button"]} onClick={handleNext}>
                 &gt;
                 </button>
                 </div>
             }


             <div className={style['wrapper__infos']}>
                 <div className={style['wrapper__infos__details']}>
                     <div className={style['wrapper__infos__details__title']}>{medias[currentIndex].name}</div>
                     <div className={style['wrapper-header-infos']}>
                         {medias[currentIndex].mimeType}
                         {medias[currentIndex].size}
                     </div>
                 </div>
                 <div className={style['wrapper__infos__buttons']}>
                     <Button text={"Supprimer"} height={14} fontSize={0.8} color={"#BA0000"} borderRadius={0}  backgroundColor={"transparent"} textDecoration={"underline"} onClick={deleteHandler}/>
                     <Button text={"Définir par défaut"} height={14} fontSize={0.8} color={"#000"} borderRadius={0}  backgroundColor={"transparent"} textDecoration={"underline"} onClick={deleteHandler}/>
                 </div>

             </div>
             <label>File URL </label>
             <input className={style['wrapper__input--disabled']} readOnly={true} value={medias[currentIndex].url}/>

         </div>
    )
}

export default GalleryForm;