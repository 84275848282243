import React from "react";
import "./App.css";
import Home from "./components/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Deals from "./components/Deals/Deals";
import Deal from "./components/Deals/Deal";
import Users from "./components/User/Users/Users";
import NewDeal from "./components/Deals/newDeal";
import "./styles/_variables.scss";
import UserDetails from "./components/User/UserDetails/UserDetails";
import AddUser from "./components/User/AddUser/AddUser";
import Auth from "./pages/auth";
import ProtectedLayout from "./layout/ProtectedLayout";
import Categories from "./components/Category/Categories";
import User from "./components/User/User";
import Contexts from "./components/Context/Contexts";
import { Helmet } from "react-helmet";
import Localisations from "./components/Localisations/Localisations";
import NewLocation from "./components/Localisations/NewLocation";
import Localisation from "./components/Localisations/Localisation";
import Country from "./components/Localisations/Country";
import Coupons from "./components/Coupons/Coupons/Coupons";
import Coupon from "./components/Coupons/Coupon";
import AddCoupon from "./components/Coupons/addCoupon/AddCoupon";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedLayout>
                <Home />
              </ProtectedLayout>
            }
          />
          <Route path="/login" element={<Auth />} />
          <Route
            path="/deals"
            element={
              <ProtectedLayout>
                <Deals />
              </ProtectedLayout>
            }
          />
          <Route
            path="/coupon"
            element={
              <ProtectedLayout>
                <Coupons />
              </ProtectedLayout>
            }
          />
          <Route
            path="/deals/:id"
            element={
              <ProtectedLayout>
                <Deal />
              </ProtectedLayout>
            }
          />
          <Route
            path="/deals/new-deal"
            element={
              <ProtectedLayout>
                <NewDeal />
              </ProtectedLayout>
            }
          />
          <Route
            path="/coupon/new-coupon"
            element={
              <ProtectedLayout>
                <AddCoupon />
              </ProtectedLayout>
            }
          />
          <Route
            path="/coupon/:id"
            element={
              <ProtectedLayout>
                <Coupon />
              </ProtectedLayout>
            }
          />
          <Route
            path="/deals/categories"
            element={
              <ProtectedLayout>
                <Categories />
              </ProtectedLayout>
            }
          />
          <Route
            path="/deals/contexts"
            element={
              <ProtectedLayout>
                <Contexts />
              </ProtectedLayout>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedLayout>
                <Users />
              </ProtectedLayout>
            }
          />
          <Route
            path="/users/:id"
            element={
              <ProtectedLayout>
                <User />
              </ProtectedLayout>
            }
          />
          <Route
            path="/users/:id/dashboard"
            element={
              <ProtectedLayout>
                <UserDetails />
              </ProtectedLayout>
            }
          />
          <Route
            path="/users/new-user"
            element={
              <ProtectedLayout>
                <AddUser />
              </ProtectedLayout>
            }
          />
          <Route
            path="/locations"
            element={
              <ProtectedLayout>
                <Localisations />
              </ProtectedLayout>
            }
          />
          <Route
            path="/locations/new-location"
            element={
              <ProtectedLayout>
                <NewLocation />
              </ProtectedLayout>
            }
          />
          <Route
            path="/locations/city/:id"
            element={
              <ProtectedLayout>
                <Localisation />
              </ProtectedLayout>
            }
          />
          <Route
            path="/locations/country/:id"
            element={
              <ProtectedLayout>
                <Country />
              </ProtectedLayout>
            }
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
