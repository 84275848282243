import {gql} from "@apollo/client";



export const CREATE_CITY = gql`
mutation createCity($city: CreateCityInput!){
  createCity(city: $city){
    id
    name
    countryName
    stateName
  }
}`

export const UPDATE_CITY = gql`
mutation updateCity($city: UpdateCityInput!, $id: String!){
  updateCity(city: $city, id: $id){
    id
    name
    countryName
    stateName
  }
}`

export const DELETE_CITY = gql`
mutation deleteCity($id: String!){
  deleteCity(id: $id){
   message
   success
  }
}`

export const CREATE_COUNTRY = gql`
mutation createCountry($country: CreateCountryInput!){
  createCountry(country: $country){
    id
    name
    latitude
    longitude
    countryCode
    phoneCode
    emoji
  }
}`

export const UPDATE_COUNTRY = gql`
mutation updateCountry($country: UpdateCountryInput!, $id: String!){
  updateCountry(country: $country, id: $id){
    id
    name
    latitude
    longitude
    countryCode
  }
}`

export const DELETE_COUNTRY = gql`
mutation deleteCountry($id: String!){
  deleteCountry(id: $id){
   message
   success
  }
}`
