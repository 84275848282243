export const tripTest: any = {
    id: 13,
    medias: [],
    country: {
        id: "ChIJvRKrsd9IXj4RpwoIwFYv0zM",
        name: "United Arab Emirates",
        latitude: 23.424076,
        longitude: 53.847818,
    },
    city: {
        id: "ChIJRcbZaklDXz4RYlEphFBu5r0",
        name: "Dubai",
        latitude: 25.2048493,
        longitude: 55.2707828,
    },
    continent: "asia",
    startDate: "2022-09-29T11:53:04Z",
    endDate: "2022-09-29T12:12:55Z",
    lowCarbonTravel: false,
    affiliationLink: "",
    numberOfNights: 2,
    numberOfDays: 3,
    numberOfPassengers: 1,
    companySymbol:
        "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/QR.svg",
    transportationType: "flight",
    departure: {
        id: "ChIJD7fiBh9u5kcRYJSMaMOCCwQ",
        name: "Paris",
        latitude: 48.856614,
        longitude: 2.3522219,
    },
    shortDescription: "Dubai, here we go !!",
    longDescription: "So much things to say ..",
    price: 1215.06,
    departureAirport: "PAR",
    departureStation: "",
    type: "flight",
    travelWith: "solo",
    visibility: "vip",
    categories: [],
    contexts: [],
    reviews: [],
    outbound: [
        {
            id: 5,
            departurePlaceName: "Paris Charles de Gaulle Airport",
            departureCity: "Paris",
            departureCityCountryCode: "FR",
            departurePlaceCode: "CDG",
            arrivalCity: "Doha",
            arrivalCityCountryCode: "QA",
            arrivalPlaceName: "Hamad International Airport",
            arrivalPlaceCode: "DOH",
            departingAt: "2022-12-05T11:10:00Z",
            arrivingAt: "2022-12-05T19:35:00Z",
            duration: {
                year: 0,
                month: 0,
                days: 0,
                hours: 6,
                minutes: 25,
                seconds: 0,
            },
            stationType: "airport",
            direction: "outbound",
            companyName: "Qatar Airways",
            companyLogo:
                "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/QR.svg",
            companySymbol:
                "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/QR.svg",
            flightNumber: "42",
            createdAt: "2022-09-29T11:55:25.351717Z",
            updatedAt: "2022-09-29T11:55:25.351717Z",
        },
        {
            id: 6,
            departurePlaceName: "Hamad International Airport",
            departureCity: "Doha",
            departureCityCountryCode: "QA",
            departurePlaceCode: "DOH",
            arrivalCity: "Dubai",
            arrivalCityCountryCode: "AE",
            arrivalPlaceName: "Dubai International Airport",
            arrivalPlaceCode: "DXB",
            departingAt: "2022-12-06T18:55:00Z",
            arrivingAt: "2022-12-06T21:15:00Z",
            duration: {
                year: 0,
                month: 0,
                days: 0,
                hours: 1,
                minutes: 20,
                seconds: 0,
            },
            stationType: "airport",
            direction: "outbound",
            companyName: "Qatar Airways",
            companyLogo:
                "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/QR.svg",
            companySymbol:
                "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/QR.svg",
            flightNumber: "1018",
            createdAt: "2022-09-29T11:55:25.351717Z",
            updatedAt: "2022-09-29T11:55:25.351717Z",
        },
    ],
    inbound: [
        {
            id: 7,
            departurePlaceName: "Dubai International Airport",
            departureCity: "Dubai",
            departureCityCountryCode: "AE",
            departurePlaceCode: "DXB",
            arrivalCity: "Doha",
            arrivalCityCountryCode: "QA",
            arrivalPlaceName: "Hamad International Airport",
            arrivalPlaceCode: "DOH",
            departingAt: "2022-12-15T00:55:00Z",
            arrivingAt: "2022-12-15T01:15:00Z",
            duration: {
                year: 0,
                month: 0,
                days: 0,
                hours: 1,
                minutes: 20,
                seconds: 0,
            },
            stationType: "airport",
            direction: "inbound",
            companyName: "Qatar Airways",
            companyLogo:
                "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/QR.svg",
            companySymbol:
                "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/QR.svg",
            flightNumber: "1015",
            createdAt: "2022-09-29T11:55:25.351717Z",
            updatedAt: "2022-09-29T11:55:25.351717Z",
        },
        {
            id: 8,
            departurePlaceName: "Hamad International Airport",
            departureCity: "Doha",
            departureCityCountryCode: "QA",
            departurePlaceCode: "DOH",
            arrivalCity: "Paris",
            arrivalCityCountryCode: "FR",
            arrivalPlaceName: "Paris Charles de Gaulle Airport",
            arrivalPlaceCode: "CDG",
            departingAt: "2022-12-15T07:55:00Z",
            arrivingAt: "2022-12-15T13:05:00Z",
            duration: {
                year: 0,
                month: 0,
                days: 0,
                hours: 7,
                minutes: 10,
                seconds: 0,
            },
            stationType: "airport",
            direction: "inbound",
            companyName: "Qatar Airways",
            companyLogo:
                "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/QR.svg",
            companySymbol:
                "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/QR.svg",
            flightNumber: "39",
            createdAt: "2022-09-29T11:55:25.351717Z",
            updatedAt: "2022-09-29T11:55:25.351717Z",
        },
    ],
    totalInboundDuration: {
        hours: 7,
        minutes: 45,
    },
    totalOutboundDuration: {
        hours: 7,
        minutes: 45,
    },
    createdAt: "2022-09-29T11:55:25.351717Z",
    updatedAt: "2022-09-29T11:55:25.351717Z",
};

export const tripData: { total:number, details: { label: string, value: number }[] } = {
    total:203,
    details: [
        {
            label: 'VOL A/R',
            value: 60
        },
        {
            label: 'VOL + Hôtel',
            value: 33
        },
        {
            label: 'Hôtel',
            value: 17
        },
        {
            label: 'Autre',
            value: 3
        },

    ]
}


export const bestTrips = [
    {
        id:1,
        country:{
            id: 'Grèce',
            name: 'Grèce'
        },
        city: {
            id: 'Santorine',
            name: 'Santorine'
        },
        travelWith: "solo",
        clickRate: '22%',
        nbBooking: '100',
        type:'Vol A/R'
    },
    {
        id:2,
        country:{
            id: 'Espagne',
            name: 'Espagne'
        },
        city: {
            id: 'Barcelone',
            name: 'Barcelone'
        },
        travelWith: "solo",
        clickRate: '20%',
        nbBooking: '90',
        type:'Vol A/R'
    },
    {
        id:3,
        country:{
            id: 'Belgique',
            name: 'Belgique'
        },
        city: {
            id: 'Bruges',
            name: 'Bruges'
        },
        travelWith: "Roadtrip",
        clickRate: '18%',
        nbBooking: '87',
        type:'Vol+Hôtel'
    },
    {
        id:4,
        country:{
            id: 'Italie',
            name: 'Italie'
        },
        city: {
            id: 'Venise',
            name: 'Venise'
        },
        travelWith: "Roadtrip",
        clickRate: '18%',
        nbBooking: '87',
        type:'Vol+Hôtel'
    },
    {
        id:5,
        country:{
            id: 'Maroc',
            name: 'Maroc'
        },
        city: {
            id: 'Marrakech',
            name: 'Marrakech'
        },
        travelWith: "solo",
        clickRate: '15%',
        nbBooking: '70',
        type:'Vol+Hôtel'
    },
    {
        id:6,
        country:{
            id: 'Japon',
            name: 'Japon'
        },
        city: {
            id: 'Tokyo',
            name: 'Tokyo'
        },
        travelWith: "Roadtrip",
        clickRate: '12%',
        nbBooking: '68',
        type:'Vol+Hôtel'
    },
    {
        id:7,
        country:{
            id: 'USA',
            name: 'USA'
        },
        city: {
            id: 'Los Angeles',
            name: 'Los Angeles'
        },
        travelWith: "solo",
        clickRate: '11%',
        nbBooking: '50',
        type:'Vol+Hôtel'
    },
];


export const barData = [
    // {
    //     time: new Date("12-01-2021"),
    //     nbUsers: 500,
    //     nbSubscription:250
    // },
    // {
    //     time: new Date("01-02-2022"),
    //     nbUsers: 250,
    //     nbSubscription:200
    // },
    // {
    //     time: new Date("02-01-2022"),
    //     nbUsers: 600,
    //     nbSubscription:350
    // },
    // {
    //     time: new Date("03-01-2022"),
    //     nbUsers: 450,
    //     nbSubscription:200
    // },
    // {
    //     time: new Date("04-01-2022"),
    //     nbUsers: 100,
    //     nbSubscription:70
    // },
    // {
    //     time: new Date("05-01-2022"),
    //     nbUsers: 1000,
    //     nbSubscription:700
    // }, // {
    //     time: new Date("12-01-2021"),
    //     nbUsers: 500,
    //     nbSubscription:250
    // },
    // {
    //     time: new Date("01-02-2022"),
    //     nbUsers: 250,
    //     nbSubscription:200
    // },
    // {
    //     time: new Date("02-01-2022"),
    //     nbUsers: 600,
    //     nbSubscription:350
    // },
    // {
    //     time: new Date("03-01-2022"),
    //     nbUsers: 450,
    //     nbSubscription:200
    // },
    // {
    //     time: new Date("04-01-2022"),
    //     nbUsers: 100,
    //     nbSubscription:70
    // },
    // {
    //     time: new Date("05-01-2022"),
    //     nbUsers: 1000,
    //     nbSubscription:700
    // },
    {
        time: new Date("06-01-2022"),
        nbUsers: 1200,
        nbSubscription:900
    },
    {
        time: new Date("07-01-2022"),
        nbUsers: 1100,
        nbSubscription:750
    },
    {
        time: new Date("08-01-2022"),
        nbUsers: 850,
        nbSubscription:700
    },
    {
        time: new Date("09-01-2022"),
        nbUsers: 900,
        nbSubscription:720
    },
    {
        time: new Date("10-01-2022"),
        nbUsers: 1000,
        nbSubscription:820
    },
    {
        time: new Date("11-01-2022"),
        nbUsers: 800,
        nbSubscription:500
    },

];

export const bestArticles = [
    {
        id: 1,
        createdAt: new Date("02-07-2022"),
        title: 'Comment voyager avec un petit budget?',
        tags: 'Voyage',
        views: 250,
        comments: 20,
    },
    {
        id: 2,
        createdAt: new Date("02-07-2022"),
        title: 'Comment voyager avec un petit budget?',
        tags: 'Voyage',
        views: 230,
        comments: 22,
    },
    {
        id: 3,
        createdAt: new Date("02-07-2022"),
        title: 'Comment voyager avec un petit budget?',
        tags: 'Voyage',
        views: 210,
        comments: 12,
    },
    {
        id: 4,
        createdAt: new Date("02-07-2022"),
        title: 'Comment voyager avec un petit budget?',
        tags: 'Voyage',
        views: 198,
        comments: 2,
    },
    {
        id: 5,
        createdAt: new Date("02-07-2022"),
        title: 'Comment voyager avec un petit budget?',
        tags: 'Voyage',
        views: 190,
        comments: 18,
    },
    {
        id: 6,
        createdAt: new Date("02-07-2022"),
        title: 'Comment voyager avec un petit budget?',
        tags: 'Voyage',
        views: 170,
        comments: 5,
    },
    {
        id: 7,
        createdAt: new Date("02-07-2022"),
        title: 'Comment voyager avec un petit budget?',
        tags: 'Voyage',
        views: 150,
        comments: 13,
    },
];


export const lineData = [
    {
        newUsersDate: new Date("10/19/2022").getTime(),
        newUsersNumber: 10
    },
    {
        newUsersDate: new Date("10/20/2022").getTime(),
        newUsersNumber: 8
    },
    {
        newUsersDate: new Date("10/21/2022").getTime(),
        newUsersNumber: 15
    },
    {
        newUsersDate: new Date("10/22/2022").getTime(),
        newUsersNumber: 2
    },
    {
        newUsersDate: new Date("10/23/2022").getTime(),
        newUsersNumber: 7
    },
    {
        newUsersDate: new Date("10/24/2022").getTime(),
        newUsersNumber: 16
    },
    {
        newUsersDate: new Date("10/25/2022").getTime(),
        newUsersNumber: 20
    },
]

export const viewsData = [
    {
        viewsDate: new Date("10/19/2022").getTime(),
        views: 10
    },
    {
        viewsDate: new Date("10/20/2022").getTime(),
        views: 8
    },
    {
        viewsDate: new Date("10/21/2022").getTime(),
        views: 15
    },
    {
        viewsDate: new Date("10/22/2022").getTime(),
        views: 2
    },
    {
        viewsDate: new Date("10/23/2022").getTime(),
        views: 7
    },
    {
        viewsDate: new Date("10/24/2022").getTime(),
        views: 16
    },
    {
        viewsDate: new Date("10/25/2022").getTime(),
        views: 20
    },
]