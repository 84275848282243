import style from './AddCategory.module.scss'
import {useMutation} from "@apollo/client";
import {
    createTripCategory,
    createTripCategoryVariables
} from "../../graphql/mutations/__generated__/createTripCategory";
import {CREATE_TRIP_CATEGORY, UPDATE_TRIP_CATEGORY} from "../../graphql/mutations/trip";
import { useEffect, useState} from "react";
import FormInput from "../IU/FormInput/FormInput";
import Button from "../IU/Button/Button";
import InputError from "../IU/InputError/InputError";
import {CreateTripCategoryInput} from "../../../__generated__/globalTypes";
import ImageModal from "../Modal/Images/ImageModal";
import {
    updateTripCategory,
    updateTripCategoryVariables
} from "../../graphql/mutations/__generated__/updateTripCategory";
import {GET_TRIP_CATEGORIES} from "../../graphql/queries/trip";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

type errors = {
    [key: string]: boolean;
};

const FORM_NAME = "name";
const FORM_NAME_LABEL = "Nom de la catégorie";
const FORM_NAME_PLACEHOLDER = "Entrer le nom de la catégorie";
const FORM_MEDIA = "tripCategory";
const FORM_ICON = "icon";


const AddCategory = ({category, setCategory,  setTripCategories, page}: any) => {
    const [createTripCategory, ] = useMutation<createTripCategory, createTripCategoryVariables>(CREATE_TRIP_CATEGORY)
    const [updateTripCategory, ] = useMutation<updateTripCategory, updateTripCategoryVariables>(UPDATE_TRIP_CATEGORY)
    const [openImageModal, setOpenImageModal] = useState(false);
    const [openIconModal, setOpenIconModal] = useState(false);
    const [errors, setErrors] = useState<errors>({});
    const [icon, setIcon] = useState<any[]>(category ? [category.icon] : []);
    const [media, setMedia] = useState<any[]>(category ? [category.media] : []);
    const MySwal = withReactContent(Swal)
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    const resetForm = () => {
        setCategory()
        setIcon([] );
        setMedia([])
    }

    useEffect(()=>{
        if (category){
        }else{
            resetForm()
        }

    },[category])

    const formHandler = async (e: any) => {
        e.preventDefault();
        const formData: CreateTripCategoryInput= {
            name: e.target[FORM_NAME].value,
            mediaId: media[0]?.id ? media[0].id : null,
            iconId: icon[0]?.id ? icon[0].id : null,
        }
        if (!category){
            if (media && icon){

                createTripCategory({variables: {tripCategory: formData}, refetchQueries: [{
                        query: GET_TRIP_CATEGORIES, variables:{page: page, limit: 10}}]
                })
                    .then(()=> {
                        Toast.fire({
                            icon: 'success',
                            title: 'Categorie crée'
                        })
                        resetForm()
                        e.target[FORM_NAME].value = ''
                    })
                    .catch(e=>
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong: \n '+ e.message,
                        }))
            }
        }else {
            if (category.name !== formData.name || category?.media?.id !== formData?.mediaId || category?.icon?.id !== formData?.iconId){

                updateTripCategory({variables: {id: category.id,tripCategory: formData}, refetchQueries: [{
                        query: GET_TRIP_CATEGORIES, variables:{page: page, limit: 10}}]
                })
                    .then(()=> {
                        Toast.fire({
                            icon: 'success',
                            title: 'Categorie mise à jour'
                        })
                        resetForm()
                        e.target[FORM_NAME].value = ''
                        setTripCategories([])
                    })
                    .catch(e=>
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong: \n '+ e.message,
                        }))
            }
        }
    }



    return(
        <div className={style['wrapper']}>
            <form className={style['wrapper-form']} onSubmit={formHandler}>
                {openImageModal && <ImageModal setOpenModal={setOpenImageModal} images={media} setImages={setMedia} isMultiple={false} fileType={'tripCategory'}/>}
                {openIconModal && <ImageModal setOpenModal={setOpenIconModal} images={icon} setImages={setIcon} isMultiple={false} fileType={'tripCategory'}/>}

                <FormInput id={FORM_NAME} labelName={FORM_NAME_LABEL} placeholder={FORM_NAME_PLACEHOLDER} defaultValue={category ? category.name :  ''}/>

                <div className={style['wrapper-form-group']}>
                    <div className={style['wrapper-form-field']}>
                        <label htmlFor="images">Média</label>

                        <Button
                            text={"Télécharger depuis l'ordinateur"}
                            color={"#2E3FD8"}
                            width={250}
                            height={50}
                            borderRadius={10}
                            backgroundColor={"rgba(224, 230, 251, 0.33)"}
                            prefixIcon={{
                                url: "/svg/image-icon.svg",
                                alt: "icône image",
                                style: {},
                            }}
                            onClick={() => {
                                setOpenImageModal(true)
                            }}
                            borderColor={"#5663DA"}
                        />
                    </div>
                    {errors.hasOwnProperty(FORM_MEDIA) && (
                        <InputError message="Selectionné ou importé une icone pour la categorie" />
                    )}
                    <div className={style['wrapper-form-field']}>
                        <label htmlFor="images">Icône</label>
                        <Button
                            text={"Télécharger depuis l'ordinateur"}
                            color={"#2E3FD8"}
                            width={250}
                            height={50}
                            borderRadius={10}
                            backgroundColor={"rgba(224, 230, 251, 0.33)"}
                            prefixIcon={{
                                url: "/svg/image-icon.svg",
                                alt: "icône image",
                                style: {},
                            }}
                            onClick={() => {
                                setOpenIconModal(true)
                            }}
                            borderColor={"#5663DA"}/>
                        {errors.hasOwnProperty(FORM_ICON) && (
                            <InputError message="Selectionné ou importé une icone pour la categorie" />
                        )}
                    </div>
                </div>
                <div className={style['wrapper-form-btn']} >
                    <Button
                        text={"Soumettre"}
                        type={"submit"}
                        color={"#FFF"}
                        backgroundColor={"#2D2C6C"}
                        width={140}
                        height={50}
                        borderRadius={10}
                    />
                </div>

            </form>
        </div>
    )
}

export default AddCategory