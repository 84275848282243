// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextEditor_wrapper__CVLjM {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  box-shadow: 2px 1px 15px 3px rgba(0, 0, 0, 0.38);
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 1rem 2rem;
  margin-top: 2rem;
  margin-right: 2rem;
}
.TextEditor_wrapper-header__HmAcJ {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.TextEditor_wrapper-header-title__\\+XiSp {
  margin-left: 5px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 1rem;
}
.TextEditor_wrapper-header-title__\\+XiSp img {
  background: #EBF0FF;
  width: 15px;
  height: 15px;
  padding: 5px;
  border-radius: 5px;
}
.TextEditor_wrapper-header-close__w4-Hq {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/TextEditor/TextEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EAEA,gDAAA;EACA,qCAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;AACJ;AACI;EACE,gBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AACN;AACM;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AACR;AAEI;EACE,eAAA;AAAN","sourcesContent":[".wrapper{\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin-bottom: 1rem;\n  -webkit-box-shadow: 2px 1px 15px 3px rgba(0,0,0,0.38);\n  box-shadow: 2px 1px 15px 3px rgb(0 0 0 / 38%);\n  border: 1px solid rgba(0,0,0,0.14);\n  border-radius: 20px;\n  padding: 1rem 2rem;\n  margin-top: 2rem;\n  margin-right: 2rem;\n\n  &-header{\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n\n    &-title{\n      margin-left: 5px;\n      display: flex;\n      gap: 10px;\n      align-items: center;\n      margin-bottom: 1rem;\n\n      img{\n        background: #EBF0FF;\n        width: 15px;\n        height: 15px;\n        padding: 5px;\n        border-radius: 5px;\n      }\n    }\n    &-close{\n      cursor: pointer;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TextEditor_wrapper__CVLjM`,
	"wrapper-header": `TextEditor_wrapper-header__HmAcJ`,
	"wrapper-header-title": `TextEditor_wrapper-header-title__+XiSp`,
	"wrapper-header-close": `TextEditor_wrapper-header-close__w4-Hq`
};
export default ___CSS_LOADER_EXPORT___;
