import {gql} from "@apollo/client";

export const SHADOW_USER = gql`
	query ShadowUser($userID: String!) {
		shadowUser(userID: $userID)
	}
`;
export const LOGIN = gql`
	query Login($email: String!, $password: String!, $rememberPassword: Boolean!) {
		login(usernameOrEmail: $email, password: $password, rememberPassword: $rememberPassword) {
			user {
				id
				lastName
				firstName
				username
				roles {
					name
				}
				media {
					url
				}
			}
			token
		}
	}
`;

export const GET_USERS = gql`
	query getUsers($page: Int!, $limit: Int!, $sort: SortAndFilter) {
		users(page: $page, limit: $limit, sort: $sort) {
			id
			lastName
			firstName
			username
			email
			phoneNumber
			createdAt
			accountActivated
			accountVerified
			stripeCustomerId
			media {
				id
				name
				url
			}
			roles {
				id
				name
			}
			plan {
				id
				hexID
				name
				price
			}
		}
	}
`;

export const GET_USER = gql`
	query getUser($id: String!) {
		user(id: $id) {
			id
			username
			email
			phoneNumber
			createdAt
			accountActivated
			accountVerified
			gender
			lastName
			firstName
			email
			plan {
				id
				name
				price
			}
			address
			zipCode
			city {
				id
				name
			}
			country {
				id
				name
			}
			phoneNumber
			favoriteDestinations {
				id
				name
			}
			favoriteTripContexts {
				id
				name
			}
			favoriteTripCategories {
				id
				name
			}
			travelingBudget
			createdAt
			accountVerified
			roles {
				id
				name
			}
			stripeCustomerId
			stripeSubscriptionId
			birthdate
			address
			zipCode
			travelWith
			subscribedToNewsLetter
			smsNotification
			emailNotification
			desktopNotification
		}
	}
`;
