import style from './ImageModal.module.scss'
import React, {useState} from "react";
import Download from "./Download/Download";
import Gallery from "./Gallery/Gallery";
import Modal from "../Modal";
import FreePics from "./Pics/FreePics";



const ImageModal = ({ setOpenModal, setImages, images, isMultiple, fileType}: any) => {

    const [isDownloadActive, setIsDownloadActive] = useState(true)
    const [isGalleryActive, setIsGalleryActive] = useState(false)
    const [isUnsplashActive, setIsUnsplashActive] = useState(false)
    const GalleryLazy = ({ setImages, setOpenModal, images, isMultiple }) => {
        return <Gallery setImages={setImages} setOpenModal={setOpenModal} images={images} isMultiple={isMultiple} />;
    };

    return(
        <Modal setOpenModal={setOpenModal}>
            <div className={style['modal-wrapper-header']}>
                <div className={style['modal-wrapper-header-titles']}>
                    <div
                        className={`${isDownloadActive ? style['modal-wrapper-header-titles-active']: style['modal-wrapper-header-titles-title']}`}
                        onClick={()=>{
                            setIsDownloadActive(true)
                            setIsGalleryActive(false)
                            setIsUnsplashActive(false)
                        }}>
                        Télécharger
                    </div>
                    <div
                        className={`${isGalleryActive ? style['modal-wrapper-header-titles-active'] : style['modal-wrapper-header-titles-title']}`}
                        onClick={()=>{
                            setIsDownloadActive(false)
                            setIsGalleryActive(true)
                            setIsUnsplashActive(false)
                        }}>
                        Galerie d'image
                    </div>
                    <div
                        className={`${isUnsplashActive ? style['modal-wrapper-header-titles-active'] : style['modal-wrapper-header-titles-title']}`}
                        onClick={()=>{
                            setIsDownloadActive(false)
                            setIsGalleryActive(false)
                            setIsUnsplashActive(true)
                        }}>
                        Photos Libres
                    </div>
                </div>
            </div>

            <div className={style['modal-wrapper-body']}>
                {isDownloadActive &&
                    <Download setIsDownloadActive={setIsDownloadActive} setIsGalleryActive={setIsGalleryActive} fileType={fileType}/>
                }
                {isGalleryActive &&
                    <GalleryLazy setImages={setImages} setOpenModal={setOpenModal} images={images} isMultiple={isMultiple} />
                }
                {isUnsplashActive &&
                    <FreePics setImages={setImages} setOpenModal={setOpenModal} images={images} isMultiple={isMultiple} />
                }
            </div>
        </Modal>

    )
}
export default ImageModal;