// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_table__xePtl {
  margin: 1rem 1rem 1rem 0;
  overflow-y: auto;
}
.Table_table-header__fnFKl {
  width: 100%;
  height: 10%;
  justify-content: space-between;
  display: flex;
  padding: 1rem 0 0.5rem 0;
}
.Table_table-header-title__2fX\\+X {
  padding-left: 1rem;
  font-size: 0.7rem;
  font-weight: bold;
}
.Table_table-header-date__eU36e {
  width: 9vw;
}
.Table_table-header-date__eU36e input {
  margin: 0;
  border: none;
  background: rgba(142, 187, 255, 0.4);
  font-weight: bold;
  color: #1562D6;
  border-radius: 10px;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  width: 60%;
  font-size: 0.5rem;
}
.Table_table-header-date__eU36e input:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/Table.module.scss"],"names":[],"mappings":"AAAA;EAEE,wBAAA;EACA,gBAAA;AAAF;AAEE;EACE,WAAA;EACA,WAAA;EACA,8BAAA;EACA,aAAA;EACA,wBAAA;AAAJ;AACI;EACE,kBAAA;EACA,iBAAA;EACA,iBAAA;AACN;AACI;EACE,UAAA;AACN;AAAM;EACE,SAAA;EACA,YAAA;EACA,oCAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,UAAA;EACA,iBAAA;AAER;AAAM;EACE,aAAA;AAER","sourcesContent":[".table{\n\n  margin: 1rem 1rem 1rem 0;\n  overflow-y: auto;\n\n  &-header {\n    width: 100%;\n    height: 10%;\n    justify-content: space-between;\n    display: flex;\n    padding: 1rem 0 0.5rem 0;\n    &-title{\n      padding-left: 1rem;\n      font-size: 0.7rem;\n      font-weight: bold;\n    }\n    &-date{\n      width: 9vw;\n      input{\n        margin: 0;\n        border: none;\n        background: rgba(142, 187, 255,.4);\n        font-weight: bold;\n        color: #1562D6;\n        border-radius: 10px;\n        text-align: center;\n        padding: .5rem;\n        cursor: pointer;\n        width: 60%;\n        font-size: 0.5rem;\n      }\n      input:focus{\n        outline: none;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table_table__xePtl`,
	"table-header": `Table_table-header__fnFKl`,
	"table-header-title": `Table_table-header-title__2fX+X`,
	"table-header-date": `Table_table-header-date__eU36e`
};
export default ___CSS_LOADER_EXPORT___;
