import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import ListElement from "../ListElement";
import Path from "../Path/Path";
import Button from "../IU/Button/Button";
import style from "./Deals.module.scss"
import Stats from "../Stats/Stats";
import {SearchTripInput, SortAndFilter} from "../../../__generated__/globalTypes";
import {useLazyQuery} from "@apollo/client";
import { SEARCH_TRIP_ADMIN} from "../../graphql/queries/trip";
import {LIMIT_PAGINATION} from "../IU/Pagination/Pagination";
import Loader from "../IU/Loader/Loader";
import {TripsAdmin, TripsAdmin_tripsAdmin, TripsAdminVariables} from "../../graphql/queries/__generated__/TripsAdmin";
import AutoComplete from "../IU/AutoCompleteInput/AutoComplete";
import DealsFilters from "../Filter/DealsFilters";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {FilterEnum} from "../../enums/filter";
import {setCityIDFilter} from "../../store/filter-trip-slice";

const cleanEmpty = obj => {
    if (Array.isArray(obj)) {
        return obj
            .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
            .filter(v => (!(v == null) || !(v)));
    } else {
        return Object.entries(obj)
            .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
            .reduce((a, [k, v]) => {

               return ((v.length === 0 || v <= 0 ) ? a : (a[k] = v, a))
            }, {});
    }
}
const getFilters = (filter) => {
    const cleanFilter = cleanEmpty(filter)
    const filters: SearchTripInput = {}
    if (Object.keys(cleanFilter).length === 0){
        return filters;
    } else {
        Object.entries(cleanFilter).map(([key, value]) => {
            if (typeof value === 'object'){
                if (Array.isArray(value)){
                    filters[FilterEnum[key]] = value
                } else{
                    // @ts-ignore
                    filters[FilterEnum[key]]= value?.id
                }

            }else{
                filters[FilterEnum[key]] = value
            }

        })
    }
    return filters
}

const getSort = (sort) => {
    const cleanFilter = cleanEmpty(sort)
    const filters: SortAndFilter = {}
    if (Object.keys(cleanFilter).length === 0){
        return filters;
    } else {
        Object.entries(cleanFilter).map(([key, value]) => {

            filters.filters += `${key}=${value},`


        })
    }
    return filters

}
const Deals = ()  => {
    const navigate = useNavigate()
    const queryParameters = new URLSearchParams(window.location.search)
    const p = queryParameters.get("p")
    const newDealHandler = () =>{
            navigate('/deals/new-deal')
    }
    const [page, setPage] = useState(p ? parseInt(p)-1 : 0)
    const { priceMinFilter, priceMaxFilter, continentFilter, departureCityFilter, categoryFilter, departureDateFilter, cityIDFilter } = useSelector((state: RootState) => state.filtersTrip)
    const { startDate, endDate, lowCarbon, visibility, type } = useSelector((state: RootState) => state.sortAndFilter)
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [deals, setDeals] = useState<TripsAdmin_tripsAdmin|null>(null);
    const dispatch = useDispatch();
    const searchFilter:SearchTripInput = getFilters({ continentFilter, departureCityFilter, categoryFilter, cityIDFilter, departureDateFilter, priceMinFilter, priceMaxFilter})
    const sort: SortAndFilter = getSort({ startDate, endDate, lowCarbon, visibility, type })
    const [listTrips] = useLazyQuery<TripsAdmin, TripsAdminVariables>(SEARCH_TRIP_ADMIN, { variables: {page: (page*LIMIT_PAGINATION), limit: LIMIT_PAGINATION, search: searchFilter, sort: sort}})
    const loadListDeals = async () => {
        const res = await listTrips();
        setDeals(res?.data?.tripsAdmin!);
    };
    useEffect(() => {
        loadListDeals()
    }, [priceMinFilter, priceMaxFilter, continentFilter, departureCityFilter, categoryFilter, departureDateFilter, cityIDFilter, page]);
    // if (loading) return <div className={style['wrapper']}><Loader/></div>;
    // if (error) return <div>`Error! ${error.message}`</div>;

    return (
        <div className={style['wrapper']}>
            <div className={style['wrapper-header']}>
                <Path/>
                <div className={style['wrapper-header-buttons']}>
                    <div className={style['']}>
                            <DealsFilters showFilter={showFilter} setShowFilter={setShowFilter}/>
                    </div>

                    <Button
                        text={"Ajouter un bon plan"}
                        color={"#FFF"}
                        width={170}
                        borderRadius={10}
                        backgroundColor={"#343E92"}
                        prefixIcon={{
                            url:"/svg/add.svg",
                            alt:"add",
                        }}
                        onClick={newDealHandler}
                        />
                </div>
            </div>
            <div className={style['wrapper-stats']}>
                <Stats title={"Nombre des bons plans au total"} number={deals?.totalCount} variation={"10%"}/>
                <Stats title={"Nombre des bons plans cliqués"} number={700} variation={"10%"}/>
                <Stats title={"Nombre des bons plans réservés"} number={400} variation={"10%"}/>
            </div>
                <div className={style['wrapper-header-line']}>
                    <div className={style['wrapper-header-line']}>
                        <AutoComplete data={cityIDFilter} name={"searchCity"} setData={(city)=>{dispatch(setCityIDFilter(city))}} type={"city"} placeholder={"Entrer une ville"} defaultValue={cityIDFilter.name}/>
                        <Button
                            text={"Reinitialiser"}
                            color={"#FFF"}
                            width={110}
                            height={35}
                            borderRadius={10}
                            backgroundColor={"#343E92"}
                            onClick={()=>{dispatch(setCityIDFilter(""))}}
                        />
                    </div>

                    <div className={style['wrapper-header-line']}>
                        <Button
                            text={"Exporter"}
                            color={"#000"}
                            width={110}
                            height={35}
                            borderRadius={10}
                            borderColor={"#C4C4C4"}
                            backgroundColor={"transparent"}
                            prefixIcon={{
                                url:"/svg/export.svg",
                                alt:"export",
                            }}
                        />
                        <Button
                            text={"Importer"}
                            color={"#000"}
                            width={110}
                            height={35}
                            borderRadius={10}
                            borderColor={"#C4C4C4"}
                            backgroundColor={"transparent"}
                            prefixIcon={{
                                url:"/svg/import.svg",
                                alt:"import",
                            }}
                        />
                    </div>

                </div>
            <ListElement trips={deals?.trips} page={page} setPage={setPage} totalOfDeals={deals?.totalCount}/>
        </div>
    );
}

export default Deals;