import style from './DateRangeInput.module.scss'
import React, {Fragment, useState} from "react";
import DatePicker from "../DatePicker/DatePicker";

const DateRangeInput = ({startDate, setStartDate, endDate, setEndDate, isDisable}: any) => {


    const [showDate, setShowDate]: any = useState(false);

    const showDatePicker = () => {
        if(!isDisable){
            setShowDate(!showDate)
        }

    }

    const displayDate = (): string => {
        if (startDate && endDate){
            if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
                return  `${startDate.toLocaleDateString('fr-FR', {day: "numeric"})} - ${endDate.toLocaleDateString('fr-FR', {
                    day: "numeric",
                    month: 'short',
                    year: 'numeric'
                })}`
            } else if (startDate.getFullYear() === endDate.getFullYear()) {
                return `${startDate.toLocaleDateString('fr-FR', {
                    day: "numeric",
                    month: "short"
                })} - ${endDate.toLocaleDateString('fr-FR', {day: "numeric", month: 'short', year: 'numeric'})}`
            } else {
                return `${startDate.toLocaleDateString('fr-FR', {
                    day: "numeric",
                    month: 'short',
                    year: 'numeric'
                })} - ${endDate.toLocaleDateString('fr-FR', {day: "numeric", month: 'short', year: 'numeric'})}`
            }
        }
        return ""
    }

    return(
        <Fragment>
            <div className={style['wrapper']} onClick={showDatePicker}>
                <input className={style['wrapper__input']} placeholder={"Choisir une période"} readOnly={true} value={displayDate()} />
                <img src='/svg/calendar.svg' alt='calendar icon' />
            </div>
            {showDate && <DatePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                isFuture={true}
                setShowDate={setShowDate}
            />}
        </Fragment>

    )
}

export default DateRangeInput