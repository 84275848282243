// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gallery_wrapper__BAuUY {
  width: 100%;
  height: 79vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Gallery_wrapper__BAuUY .Gallery_content__tDRWy {
  display: flex;
  height: 72vh;
}
.Gallery_wrapper__BAuUY .Gallery_content-gallery__tiU7G {
  overflow-x: scroll;
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  height: 98%;
  column-gap: 1rem;
  transition: all 0.75s;
  flex: 2 1;
}
.Gallery_wrapper__BAuUY .Gallery_content-gallery__active__FFsqV {
  width: 100%;
}
.Gallery_wrapper__BAuUY .Gallery_content-gallery__disable__5buw6 {
  width: 70%;
}
.Gallery_wrapper__BAuUY .Gallery_content-gallery-image__1WANL {
  cursor: pointer;
  width: 180px;
  height: 180px;
  box-shadow: 0 4px 21px rgba(206, 206, 206, 0.25);
  border-radius: 10px;
}
.Gallery_wrapper__BAuUY .Gallery_content-gallery-image-selected__brEUl {
  border: 1px solid #7683FD;
  padding: 5px;
}
.Gallery_wrapper__BAuUY .Gallery_content-gallery-image__1WANL img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.Gallery_wrapper__BAuUY .Gallery_content-form__NKY\\+5 {
  height: 100%;
  transition: all 0.75s;
  flex: 1 1;
}
.Gallery_wrapper__BAuUY .Gallery_content-form__active__Kyc29 {
  width: 30%;
  opacity: 1;
}
.Gallery_wrapper__BAuUY .Gallery_content-form__disable__\\+x5Rm {
  width: 0;
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Images/Gallery/Gallery.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAAE;EACE,aAAA;EACA,YAAA;AAEJ;AADI;EACE,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,SAAA;AAGN;AADM;EACE,WAAA;AAGR;AAAM;EACE,UAAA;AAER;AACM;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,gDAAA;EACA,mBAAA;AACR;AACQ;EACE,yBAAA;EACA,YAAA;AACV;AAEQ;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AAAV;AAKI;EACE,YAAA;EACA,qBAAA;EACA,SAAA;AAHN;AAKM;EACE,UAAA;EACA,UAAA;AAHR;AAMM;EACE,QAAA;EACA,UAAA;AAJR","sourcesContent":[".wrapper{\n  width: 100%;\n  height: 79vh;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  .content {\n    display: flex;\n    height: 72vh;\n    &-gallery {\n      overflow-x: scroll;\n      padding: 1rem 2rem;\n      display: flex;\n      flex-wrap: wrap;\n      row-gap: 1rem;\n      height: 98%;\n      column-gap: 1rem;\n      transition: all .75s;\n      flex: 2;\n\n      &__active {\n        width: 100%;\n      }\n\n      &__disable {\n        width: 70%;\n      }\n\n      &-image {\n        cursor: pointer;\n        width: 180px;\n        height: 180px;\n        box-shadow: 0 4px 21px rgba(206, 206, 206, 0.25);\n        border-radius: 10px;\n\n        &-selected {\n          border: 1px solid #7683FD;\n          padding: 5px;\n        }\n\n        img {\n          width: 100%;\n          height: 100%;\n          border-radius: 10px;\n          object-fit: cover;\n        }\n      }\n    }\n\n    &-form {\n      height: 100%;\n      transition: all .75s;\n      flex: 1;\n\n      &__active {\n        width: 30%;\n        opacity: 1;\n      }\n\n      &__disable {\n        width: 0;\n        opacity: 0;\n      }\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Gallery_wrapper__BAuUY`,
	"content": `Gallery_content__tDRWy`,
	"content-gallery": `Gallery_content-gallery__tiU7G`,
	"content-gallery__active": `Gallery_content-gallery__active__FFsqV`,
	"content-gallery__disable": `Gallery_content-gallery__disable__5buw6`,
	"content-gallery-image": `Gallery_content-gallery-image__1WANL`,
	"content-gallery-image-selected": `Gallery_content-gallery-image-selected__brEUl`,
	"content-form": `Gallery_content-form__NKY+5`,
	"content-form__active": `Gallery_content-form__active__Kyc29`,
	"content-form__disable": `Gallery_content-form__disable__+x5Rm`
};
export default ___CSS_LOADER_EXPORT___;
