import SignIn from "../../components/SignIn/SignIn";
import AuthLayout from "../../layout/AuthLayout";

function Auth() {

  return (
    <>

      <AuthLayout>
          <SignIn/>
      </AuthLayout>

    </>
  );
}

export default Auth;
