import style from "./Logo.module.scss";
import {NavLink} from "react-router-dom";

export default function Logo({ href }: any) {
  return (
    <div className={style["logo-container"]}>
      {href ? (
        <NavLink to="/">
            <img src="/img/logo.png" alt="Logo Broke and Abroad" />
        </NavLink>
      ) : (
        <img src="/img/logo.png" alt="Logo Broke and Abroad" />
      )}
    </div>
  );
}
