// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthBackground_auth-section__hvX74 {
  position: relative;
  width: 100%;
  padding: 120px 20px 124px;
  min-height: 1300px;
  height: 100vh;
  z-index: 1;
}
@media all and (min-width: 800px) {
  .AuthBackground_auth-section__hvX74 {
    min-height: 1200px;
    padding: 210px 210px 124px 0px;
  }
}
.AuthBackground_auth-section__hvX74::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 14.57%, rgba(0, 0, 0, 0) 50%), rgba(182, 178, 178, 0.16);
}
.AuthBackground_auth-section__hvX74 .AuthBackground_background-image__OS\\+MV {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.7s;
  object-fit: cover;
}
.AuthBackground_auth-section__hvX74 .AuthBackground_background-image--active__ykGZZ {
  opacity: 1;
}
.AuthBackground_auth-section__hvX74 .AuthBackground_background-image-container__RxT\\+U {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/AuthBackground/AuthBackground.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,UAAA;AAAJ;AAEI;EARF;IASI,kBAAA;IACA,8BAAA;EACJ;AACF;AACI;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,gHAAA;AACN;AAOI;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,wBAAA;EACA,iBAAA;AALN;AAOM;EACE,UAAA;AALR;AAQM;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AANR","sourcesContent":[".auth {\n  &-section {\n    position: relative;\n    width: 100%;\n    padding: 120px 20px 124px;\n    min-height: 1300px;\n    height: 100vh;\n    z-index: 1;\n    \n    @media all and (min-width: 800px) {\n      min-height: 1200px;\n      padding: 210px 210px 124px 0px;\n    }\n\n    &::before {\n      content: \"\";\n      position: absolute;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      z-index: 2;\n      background: linear-gradient(\n          180deg,\n          rgba(0, 0, 0, 0.15) 14.57%,\n          rgba(0, 0, 0, 0) 50%\n        ),\n        rgba(182, 178, 178, 0.16);\n    }\n\n    .background-image {\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      opacity: 0;\n      transition: opacity 0.7s;\n      object-fit: cover;\n\n      &--active {\n        opacity: 1;\n      }\n\n      &-container {\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth-section": `AuthBackground_auth-section__hvX74`,
	"background-image": `AuthBackground_background-image__OS+MV`,
	"background-image--active": `AuthBackground_background-image--active__ykGZZ`,
	"background-image-container": `AuthBackground_background-image-container__RxT+U`
};
export default ___CSS_LOADER_EXPORT___;
