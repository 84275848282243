import {configureStore} from "@reduxjs/toolkit";
import authSlice from "./auth-slice";
import tripSlice from "./trip-slice";
import filtersTripSlice from "./filter-trip-slice";
import sortAndFilterSlice from "./sort-slice";

export type RootState = ReturnType<typeof store.getState>
export const store = configureStore({
    reducer: {
        auth: authSlice,
        trip: tripSlice.reducer,
        filtersTrip: filtersTripSlice,
        sortAndFilter: sortAndFilterSlice
    }
})