import { gql } from "@apollo/client";

export const LIST_PLANS = gql`
  query ListPlans {
    plans {
      id
      hexID
      name
      price
      stripePriceID
    }
  }
`;

export const GET_PLAN = gql`
  query plan($productId: String!) {
    getProductById(productId: $productId) {
      id
      name
    }
  }
`;

export const GET_PLAN_BY_STRIPE_PRODUCT = gql`
  query GetPlanByStripeProductID($stripePriceID: String!) {
    GetPlanByStripeProductID(stripePriceID: $stripePriceID) {
      id
      name
      stripePriceID
      price
    }
  }
`;
