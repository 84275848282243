// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Download_download__WOu2K {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Download_download-wrapper__sNDyO {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 770px;
  height: 350px;
  background: rgba(224, 230, 251, 0.33);
  border-radius: 10px;
  background-image: repeating-linear-gradient(-23deg, #e0e6fb, #e0e6fb 10px, transparent 10px, transparent 19px, #e0e6fb 19px), repeating-linear-gradient(67deg, #e0e6fb, #e0e6fb 10px, transparent 10px, transparent 19px, #e0e6fb 19px), repeating-linear-gradient(157deg, #e0e6fb, #e0e6fb 10px, transparent 10px, transparent 19px, #e0e6fb 19px), repeating-linear-gradient(247deg, #e0e6fb, #e0e6fb 10px, transparent 10px, transparent 19px, #e0e6fb 19px);
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.Download_download-wrapper-content__aY5P2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.Download_download-wrapper-content-title__ka3B9 {
  font-weight: bold;
}
.Download_download-wrapper-content-footer__11YQZ {
  font-size: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Images/Download/Download.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,+bAAA;EACA,uDAAA;EACA,6CAAA;EACA,4BAAA;AAEJ;AADI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;AAGN;AAFM;EACE,iBAAA;AAIR;AAFM;EACE,iBAAA;AAIR","sourcesContent":[".download{\n  width: 100%;\n  height: 70vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  &-wrapper{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 770px;\n    height: 350px;\n    background: rgba(224, 230, 251, 0.33);\n    border-radius: 10px;\n    background-image: repeating-linear-gradient(-23deg, #e0e6fb, #e0e6fb 10px, transparent 10px, transparent 19px, #e0e6fb 19px), repeating-linear-gradient(67deg, #e0e6fb, #e0e6fb 10px, transparent 10px, transparent 19px, #e0e6fb 19px), repeating-linear-gradient(157deg, #e0e6fb, #e0e6fb 10px, transparent 10px, transparent 19px, #e0e6fb 19px), repeating-linear-gradient(247deg, #e0e6fb, #e0e6fb 10px, transparent 10px, transparent 19px, #e0e6fb 19px);\n    background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;\n    background-position: 0 0, 0 0, 100% 0, 0 100%;\n    background-repeat: no-repeat;\n    &-content{\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-direction: column;\n      gap: 10px;\n      &-title{\n        font-weight: bold;\n      }\n      &-footer{\n        font-size: 0.5rem;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"download": `Download_download__WOu2K`,
	"download-wrapper": `Download_download-wrapper__sNDyO`,
	"download-wrapper-content": `Download_download-wrapper-content__aY5P2`,
	"download-wrapper-content-title": `Download_download-wrapper-content-title__ka3B9`,
	"download-wrapper-content-footer": `Download_download-wrapper-content-footer__11YQZ`
};
export default ___CSS_LOADER_EXPORT___;
