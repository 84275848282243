import style from "./SignIn.module.scss";
import Button from "../IU/Button/Button";
import AuthInput from "../IU/FormInput/FormInput";
import AuthBackground from "../AuthBackground/AuthBackground";
import Loader from "../IU/Loader/Loader";
import {useEffect, useState} from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Login, Login_login_user,
  LoginVariables,
} from "../../graphql/queries/__generated__/Login";
import { BNA_AUTH_TOKEN, BNA_USER_CONTEXT } from "../../globals";
import {LOGIN} from "../../graphql/queries/user";
import InputError from "../IU/InputError/InputError";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authAction} from "../../store/auth-slice";



function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { loading }] = useLazyQuery<Login, LoginVariables>(LOGIN);
  const [error, setError] = useState<string>("");
  const token = localStorage.getItem(BNA_AUTH_TOKEN)

  const FORM_EMAIL = "email";
  const FORM_PASSWORD = "password";
  const FORM_MEMORIZE_PASSWORD = "memorize-password";
  useEffect(() => {
    if (token) {
      navigate("/");
      return;
    }

  }, [token]);
  const signIn = async (userLogin: LoginVariables) => {
    try {
      const res = await login({
        variables: userLogin,
        fetchPolicy: "network-only"
      });

      if (res?.data?.login) {
        const user = res?.data?.login?.user
        // eslint-disable-next-line array-callback-return
        user?.roles?.map((role)=>{
          if (role.name === "ADMIN"){
            localStorage.setItem(BNA_AUTH_TOKEN, res?.data?.login?.token!);
            localStorage.setItem(BNA_USER_CONTEXT, JSON.stringify(res?.data?.login?.user!));
            dispatch(authAction.setCredentials({user: res?.data?.login?.user!, token: res?.data?.login?.token!}))
            return [];
          }
        })
      }

      setError("Tes identifiants sont incorrects.");
    } catch (e) {}
  };



  const handleOnSubmit = async (e: any) => {
    e.preventDefault();

    setError("");


    const formData: LoginVariables = {
      email: e.target[FORM_EMAIL].value.toLowerCase().trim(),
      password: e.target[FORM_PASSWORD].value.trim(),
      rememberPassword: e.target[FORM_MEMORIZE_PASSWORD].checked,
    };

    signIn(formData);
  };


  return (
    <AuthBackground>

      <div className={style["auth-form-container"]}>
        <h1 className={style["auth-form-title"]}>
          Login Admin
        </h1>
        <form onSubmit={handleOnSubmit} className={style["auth-form"]}>
          {loading ? (
            <div className={style["loading-sign-in"]}>
              <Loader />
            </div>
          ) : (
            <>
              <div className={style["auth-email"]}>
                <AuthInput
                  labelName="Adresse mail"
                  id="email"
                  type="text"
                  placeholder="Entre ton adresse mail..."
                />
              </div>
              <div className={style["auth-password"]}>
                <AuthInput
                  labelName="Mot de passe"
                  id="password"
                  type="password"
                  placeholder="Entre ton mot de passe..."
                />
              </div>
              {error && (
                <InputError message={error} />
              )}
              <div className={style["connection-options"]}>
                <div className={style["save-password"]}>
                  <input type="checkbox" id={FORM_MEMORIZE_PASSWORD} />
                  <label htmlFor={FORM_MEMORIZE_PASSWORD}>Mémoriser mon compte</label>
                </div>
              </div>
              <Button
                type="submit"
                text="SE CONNECTER"
                color="#fff"
                height={47}
                backgroundColor="#FDB242"
                borderRadius={10}
              />
            </>
          )}
        </form>
      </div>
    </AuthBackground>
  );
}

export default SignIn;
