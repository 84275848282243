import style from "./RadioButton.module.scss";
import { Fragment, useState} from "react";


interface IRadioButtonProps {
    data: { id: string, name:string, number?: number }[],
    name:string,
    width?: number,
    defaultValue?: string
}

export default function RadioButton({
    data,
    name,
    width,
    defaultValue
}: IRadioButtonProps){

    const [value, setValue] = useState<string>(defaultValue ? defaultValue : '')
    return(
        <div
            style={{
            width: width ? `${width}px` : "auto",
            }}
            className={style['radio']}
        >
            {data.map((d) => (
                    <Fragment key={name + d.id}>
                        <input type="radio" className="btn-check" name={name} id={name +d.id} value={d.id}
                               autoComplete="off"
                               onChange={e=> {
                                   setValue(e.target.value)
                               }}
                               checked={(d.id === value)} />
                        <label className={`btn--blank ${style['radio-label']}`}
                               htmlFor={name + d.id}>{d.name}</label>
                    </Fragment>
                )
            )}
        </div>
    );
}