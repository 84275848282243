// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__yEgVW {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  font-family: "Poppins", sans-serif;
}
.Modal_modal-wrapper__gj0Ye {
  width: 90vw;
  max-height: 90vw;
  padding: 1rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;EACA,qCAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,kCAAA;AACD;AACC;EACC,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;AACF","sourcesContent":[".modal {\n\twidth: 100vw;\n\theight: 100vh;\n\tbackground-color: rgba(0, 0, 0, 0.75);\n\tposition: fixed;\n\tleft: 0;\n\ttop: 0;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tz-index: 10;\n\tfont-family: \"Poppins\", sans-serif;\n\n\t&-wrapper {\n\t\twidth: 90vw;\n\t\tmax-height: 90vw;\n\t\tpadding: 1rem;\n\t\tborder-radius: 12px;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tz-index: 10;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__yEgVW`,
	"modal-wrapper": `Modal_modal-wrapper__gj0Ye`
};
export default ___CSS_LOADER_EXPORT___;
