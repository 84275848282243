import React, { Fragment, PropsWithChildren} from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar/Sidebar";
import {useLocation} from "react-router-dom";
import {getAuthToken} from "../util/auth";

const Layout: React.FC<PropsWithChildren> = ({children}) => {
    const token = getAuthToken()
    const location = useLocation()
    const isLoginPage = location.pathname.includes("login")



    if (isLoginPage || !token) {
        return (<main>{children}</main>)
    }
    return(
        <Fragment>
            <Sidebar/>
            <div className='main'>
                <Navbar/>
                <main>{children}</main>
            </div>

        </Fragment>
    )
};
export default Layout;