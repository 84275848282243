import {createSlice} from "@reduxjs/toolkit";
import {ITrip} from "../types/trip";

const initialState: ITrip ={
    tripList: []
}
const tripSlice = createSlice({
    name: 'trip',
    initialState,
    reducers: {
        addTrip:(state)=>{

        },
        updateTrip:()=>{

        },
        getTrips:(state, action)=>{
            state.tripList = action.payload
        }

    }
})


export const tripAction = tripSlice.actions;
export default tripSlice