export enum Months {
    Janvier = 'Janvier',
    Fevrier = 'Fevrier',
    Mars = 'Mars',
    Avril = 'Avril',
    Mai = 'Mai',
    Juin = 'Juin',
    Juillet = 'Juillet',
    Aout = 'Aout',
    Septembre = 'Septembre',
    Octobre = 'Octobre',
    Novembre = 'Novembre',
    Decembre = 'Decembre',
}


export const MapMonthToNumber: { [key: string]: number } = {
    [Months.Janvier]: 1,
    [Months.Fevrier]: 2,
    [Months.Mars]: 3,
    [Months.Avril]: 4,
    [Months.Mai]: 5,
    [Months.Juin]: 6,
    [Months.Juillet]: 7,
    [Months.Aout]: 8,
    [Months.Septembre]: 9,
    [Months.Octobre]: 10,
    [Months.Novembre]: 11,
    [Months.Decembre]: 12,
}

export const MapIndexToMonth: { [key: number]: string } = {
    1: Months.Janvier,
    2: Months.Fevrier,
    3: Months.Mars,
    4: Months.Avril,
    5: Months.Mai,
    6: Months.Juin,
    7: Months.Juillet,
    8: Months.Aout,
    9: Months.Septembre,
    10: Months.Octobre,
    11: Months.Novembre,
    12: Months.Decembre,
}

export const MonthsArr = [
    Months.Janvier,
    Months.Fevrier,
    Months.Mars,
    Months.Avril,
    Months.Mai,
    Months.Juin,
    Months.Juillet,
    Months.Aout,
    Months.Septembre,
    Months.Octobre,
    Months.Novembre,
    Months.Decembre,
]