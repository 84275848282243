import React from "react";
import style from "./Sidebar.module.scss";
import SidebarItem from "./SidebarItem";

export const MENU_ITEMS= [
    {value: 'dashboard', label: 'Dashboard', icon: '/svg/dashboard-empty.svg', iconSelected: '/svg/dashboard-full.svg'},
    {value: 'users', label: 'Users', icon: '/svg/users-empty.svg', iconSelected: '/svg/users-full.svg'},
    {value: 'deals', label: 'Bon plans', icon: '/svg/deals-empty.svg', iconSelected: '/svg/deals-full.svg', childrens: [
            {value: 'categories', label: 'Categories'},
            {value: 'contexts', label: 'Contexts'}
        ]},
    // {value: 'articles', label: 'Articles', icon: '/svg/articles-empty.svg', iconSelected: '/svg/articles-full.svg', childrens: [
    //         {value: 'blogCategories', label: 'Categories blog'},
    //     ]},
    {value: 'locations', label: 'Localisations', icon: '/svg/location-empty.svg', iconSelected: '/svg/location-full.svg'},
    {value: 'coupon', label: 'Coupon', icon: '/svg/coupons-empty.svg', iconSelected: '/svg/coupons-full.svg'},
]

const Sidebar = () => {

    return (
        <div className={style['sidebar']}>
            <div className={style['sidebar-logoArea']}>
                <img src={"/img/logo.png"} className={style['sidebar-logoArea-logo']}/>
            </div>
            { MENU_ITEMS.map((item, index) => <SidebarItem key={index} item={item} />) }
        </div>
    )


};

export default Sidebar