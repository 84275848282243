import {createSlice} from "@reduxjs/toolkit";
import {CustomObj} from "../components/Deals/DealFormAffiliate";

export interface IDepartureDateInput{
    day?: number,
    month: number,
    year: number
}
interface IFiltersTrip{
    continentFilter: string,
    departureCityFilter: string,
    priceMinFilter: number,
    priceMaxFilter: number,
    categoryFilter: string[],
    cityIDFilter: CustomObj,
    departureDateFilter: IDepartureDateInput[],
}


const initialState: IFiltersTrip ={
    continentFilter: "",
    departureCityFilter: "",
    priceMinFilter: 0,
    priceMaxFilter: 5000,
    categoryFilter: [],
    cityIDFilter:{id:"", name: ""},
    departureDateFilter:[]
}

const filtersTripSlice = createSlice({
    name: "filtersTrip",
    initialState,
    reducers: {
        setPriceMinFilter: (state, action) => {
            state.priceMinFilter = action.payload;
        },
        setPriceMaxFilter: (state, action) => {
            state.priceMaxFilter = action.payload;
        },
        setDepartureCityFilter: (state, action) => {
            state.departureCityFilter = action.payload
        },

        setContinentFilter: (state, action) => {
            state.continentFilter = action.payload
        },

        setCategoryFilter: (state, action) => {
            state.categoryFilter = action.payload
        },
        setCityIDFilter: (state, action)=>{
            state.cityIDFilter = action.payload
        },
        setDepartureDateFilter: (state, action)=>{
            state.departureDateFilter = action.payload
        }
    },
});

export const actions = {
    ...filtersTripSlice.actions,
};

export const { setPriceMinFilter, setPriceMaxFilter,  setDepartureCityFilter, setContinentFilter, setCategoryFilter, setCityIDFilter, setDepartureDateFilter } = filtersTripSlice.actions


export default filtersTripSlice.reducer;



