// CheckboxRound component

import { useController } from "react-hook-form";
import React from "react";
import style from "./CheckboxRound.module.scss";

const CheckboxRound = ({
  id = "",
  isChecked,
  name = "",
  control,
  setIsChecked = () => {},
  errorClass = "",
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
  });

  return (
    <div className={style.CheckboxRound}>
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={isChecked}
        className={style.input}
        onChange={(e) => {
          onChange(e.target.checked ?? id); // Update the form value with the ID
          setIsChecked(); // Properly handle the product selection change in AddCoupon
        }}
      />
      <label htmlFor={id} className={style.label}>
        <span></span>
      </label>
      {error && <p className={errorClass}>{error.message}</p>}
    </div>
  );
};

export default CheckboxRound;
