import style from "./Button.module.scss";

interface IButtonProps {
    text: string
    color: string
    backgroundColor?: string
    width?: number
    height?: number
    borderRadius: number
    fontSize?: number
    fontWeight?: string
    textDecoration?: string,
    prefixIcon?: any // TODO :: apply correct interface
    suffixIcon?: any // TODO :: apply correct interface
    borderColor?: string
    type?: "button" | "submit" | "reset" | undefined
    onClick?: (e?: any) => void
}

export default function Button({
  text,
  color,
  backgroundColor,
  width,
  height,
  borderRadius,
  fontSize,
  fontWeight,
  prefixIcon,
  suffixIcon,
  borderColor,
  textDecoration,
  type,
  onClick
}: IButtonProps) {
  return (
    <button 
      type={type ? type : "button"}
      onClick={onClick}
      style={{
        width: width ? `${width}px` : "auto",
        fontSize:  fontSize ? `${fontSize}rem` : "0.75rem",
        height: height ? `${height}px` : "auto",
        borderRadius: `${borderRadius}px`,
        background: backgroundColor ? backgroundColor : "transparent",
        fontWeight: fontWeight ? fontWeight : "normal",
        textDecoration: textDecoration ? textDecoration : "none",
        color: color,
        border: borderColor ? `1.2px solid ${borderColor}` : "none",
      }}
      className={
        prefixIcon || suffixIcon
          ? `${style["cta-btn"]} ${style["cta-btn--flex"]}`
          : style["cta-btn"]
      }
    >
      {prefixIcon && (
        <div style={prefixIcon.style} className={style["prefix-icon"]}>
          <img src={prefixIcon.url} alt={prefixIcon.alt} />
        </div>
      )}
      {text}
      {suffixIcon && (
        <div style={suffixIcon.style} className={style["suffix-icon"]}>
          <img src={suffixIcon.url} alt={suffixIcon.alt} />
        </div>
      )}
    </button>
  );
}
