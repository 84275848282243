import _default, {
    Chart as ChartJS,
    ChartOptions,
    BarElement,
     LinearScale, CategoryScale, Title, Tooltip, Legend,
} from "chart.js";
import{Bar} from "react-chartjs-2";
import style from "./BarChartUsers.module.scss";
import {barData} from "../../../data/TripsData";
import {useState} from "react";

const BarChartUsers:React.FC<{ endDate: string }> = (props)  => {
    ChartJS.register(
        BarElement,
        LinearScale,
        CategoryScale,
        Title,
        Tooltip,
        Legend
    );
    ChartJS.defaults.font.family = 'Poppins'
    const legendLabels = ["Nombre total", "Nombre de Bro'Kit"];
    const nbUsers = barData.map((data) => {return data.nbUsers})
    const nbSubscription = barData.map((data) => {return data.nbSubscription})
    const labels = barData.map((data) => {
        const date = new Date(data.time)
        return date.toLocaleDateString('fr-FR',{month:"long"})
    })
    const max = Math.max(...nbUsers)
    const [data, setData] = useState({
        datasets: [
            {
                data: nbSubscription,
                backgroundColor: '#FDAF3A',
                stack: 'Stack 0',
                barPercentage:0.2,
                categoryPercentage: 0.8,
                borderRadius:10,
                label: legendLabels[0]
            },
            {
                data: nbUsers,
                backgroundColor: '#6967DB',
                stack: 'Stack 0',
                barPercentage:0.2,
                categoryPercentage: 0.8,
                borderRadius:10,
                font:{
                    family: 'Poppins',
                    size:8
                },
                label: legendLabels[1]
            },

        ],
        labels,
    })


    const options: ChartOptions<'bar'> = {
        responsive: true,
        plugins:{
            legend: {
                title:{
                },
                position: "bottom",
                align:"center",
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    padding:20,
                    boxWidth:6,
                    font:{
                        family: 'Poppins',
                        size:8
                    }
                },
            },
            tooltip:{
                displayColors:false,
                boxWidth:0,
                backgroundColor:'transparent',
                bodyColor: '#707ADA',
                textDirection: 'ltr',
                bodyFont: {
                    family: 'Poppins',
                    size:30
                },
                titleColor: 'transparent',
            }
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                ticks: {
                    font:{
                        size:7
                    }
                }
            },
            y: {
                min: 0,
                grace:'5%',
                grid: {
                    color: 'rgba(129, 129, 129,0.06)'
                },
                ticks: {
                    font:{
                        size:8
                    }
                }
            },
        },
        maintainAspectRatio: false,
    }



    return (
        <div className={style['bar']}>
            <div className={style['bar-chart']}>
                <Bar data={data} options={options} height={100}/>
            </div>
            <div className={style['bar-infos']}>
                <div className={`${style['bar-infos-articles']} ${style['bar-infos-info']}`}>
                    <div className={style['bar-infos-articles-header']}>
                        <span>Nb des utilisateurs</span>
                    </div>
                    <div className={style['bar-infos-articles-container']}>
                       4300
                    </div>

                </div>
                <div className={`${style['bar-infos-views']} ${style['bar-infos-info']} `}>
                    <div className={style['bar-infos-views-header']}>
                        <span>Nb de Bro'kit</span>
                    </div>
                    <div className={style['bar-infos-views-container']}>
                        7000
                    </div>

                </div>
            </div>

        </div>
    );
}

export default BarChartUsers;