// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioButton_radio__m44zG {
  display: flex;
  width: auto;
  gap: 1rem;
}
.RadioButton_radio__m44zG input[type=radio] {
  opacity: 0.011;
  background: none;
}
.RadioButton_radio__m44zG input[type=radio]:checked + label {
  background: #343E92;
  color: white;
  border-radius: 10px;
}
.RadioButton_radio__m44zG input {
  display: none;
}
.RadioButton_radio-label__faS5g {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #F6F6F6;
  border-radius: 10px;
  text-align: center;
  line-height: 1em;
  width: auto;
  height: 1rem;
  font-weight: bold;
  color: #888686;
  padding: 1rem 2rem 1rem 2rem;
}
.RadioButton_radio-label__faS5g:hover {
  background: #343E92;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/RadioButton/RadioButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,SAAA;AACF;AAAE;EACE,cAAA;EACA,gBAAA;AAEJ;AAAE;EACE,mBAAA;EACA,YAAA;EACA,mBAAA;AAEJ;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;EACA,4BAAA;AAEJ;AAAE;EACE,mBAAA;EACA,YAAA;AAEJ","sourcesContent":[".radio{\n  display: flex;\n  width: auto;\n  gap: 1rem;\n  input[type=\"radio\"] {\n    opacity: 0.011;\n    background: none;\n  }\n  input[type=\"radio\"]:checked + label {\n    background: #343E92;\n    color: white;\n    border-radius: 10px;\n  }\n  input{\n    display: none;\n  }\n  &-label{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    background: #F6F6F6;\n    border-radius: 10px;\n    text-align: center;\n    line-height: 1em;\n    width: auto;\n    height: 1rem;\n    font-weight: bold;\n    color: #888686;\n    padding: 1rem 2rem 1rem 2rem;\n  }\n  &-label:hover{\n    background: #343E92;\n    color: white;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `RadioButton_radio__m44zG`,
	"radio-label": `RadioButton_radio-label__faS5g`
};
export default ___CSS_LOADER_EXPORT___;
