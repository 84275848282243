import React, {Fragment, useState} from "react";
import style from "./Table.module.scss"
import DatePicker from "../IU/DatePicker/DatePicker";
import {tableCategory} from "../../enums/tableCategory";
import {bestTrips} from "../../data/TripsData";
import BestTripTable from "./BestTripTable";
import BestArticleTable from "./BestArticleTable";

const Tables:React.FC<{title: string, width: string, height: string, typeOfTable: string}> = (props) => {

    const [startDate, setStartDate]: any = useState("01");
    const [endDate, setEndDate]: any = useState("19 Oct 2022");
    const [showDate, setShowDate]: any = useState(false);

    const showDateHandler = () => {
        setShowDate(!showDate)
    }
    const isBestTrip = props.typeOfTable === "bestTrip"

    return (
        <div className={style['table']}
             style={{
                 width: props.width ? `${props.width}` : "auto",
                 height: props.height ? `${props.height}` : "auto",
             }}>
            <div className={style['table-header']} style={{
                borderBottom: isBestTrip ? "0.5px solid #9e9d9e" : "",
            }}>
                <div className={style['table-header-title']}>
                    {props.title}
                </div>
                <div className={style['table-header-date']}>
                    <input value={`${startDate} - ${endDate}`} readOnly onClick={showDateHandler}/>
                    {showDate && <DatePicker
                        value={startDate}
                        setValue={setStartDate}
                    />}

                </div>
            </div>
            {isBestTrip && <BestTripTable/>}
            {!isBestTrip && <BestArticleTable/>}

        </div>
    );
}

export default Tables;