// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LineChart_line__ElXxC {
  width: 90%;
  height: 85%;
  margin: auto;
  padding-top: 3%;
}`, "",{"version":3,"sources":["webpack://./src/components/Charts/Line/LineChart.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AACF","sourcesContent":[".line{\n  width: 90%;\n  height: 85%;\n  margin: auto;\n  padding-top: 3%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `LineChart_line__ElXxC`
};
export default ___CSS_LOADER_EXPORT___;
