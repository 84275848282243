import style from "./PriceInput.module.scss"
import {useState} from "react";

export const CURRENCY = [
    {id: 'EUR', label: 'EUR (€)', symbol: '€'},
    {id: 'USD', label: 'USD  ($)', symbol: '$'},
]

interface IPriceInputProps {
    priceName: string
    currencyName: string
    defaultValue?: { price: number, currency: string }


}

const PriceInput = ({priceName, currencyName, defaultValue}: IPriceInputProps) => {
    const findDefaultCurrency = CURRENCY.findIndex(c => c.id === defaultValue?.currency)
    const [inputValue, setInputValue] = useState(defaultValue?.currency ? CURRENCY[findDefaultCurrency].symbol : CURRENCY[0].symbol);
    const [idValue, setIdValue] = useState(defaultValue?.currency ? CURRENCY[findDefaultCurrency].id : CURRENCY[0].id);
    const [showDropDown, setShowDropDown] = useState(false);

    return(
        <div className={style['wrapper']}>
            <input type="number"  className={style["wrapper__input--number"]} name={priceName} defaultValue={defaultValue?.price}/>
            <div className={style["wrapper__input"]}>
                <input
                    className={style["wrapper__input--currency"]}
                    onClick={() => {
                        setShowDropDown(!showDropDown);
                    }}
                    onChange={() => {}}
                    value={inputValue}
                    autoComplete="off"
                    id={idValue}
                    name={currencyName}
                    type="text"
                    contentEditable="false"
                />
                {showDropDown && (
                    <div className={style["dropdown-option"]}>
                        <ul className={style["dropdown-options"]}>
                            {CURRENCY.map((d: any) => (
                                <li
                                    key={d.id}
                                    onClick={() => {
                                        setInputValue(d.symbol);
                                        setIdValue(d.id);
                                        setShowDropDown(false);
                                    }}
                                    style={{color: d.color}}
                                    className={style["currency-option"]}
                                >
                                    {d.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

        </div>
    )
}

export default PriceInput