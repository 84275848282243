import style from "./AddUser.module.scss";
import RadioButton from "../../IU/RadioButton/RadioButton";
import Button from "../../IU/Button/Button";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {createUser, createUserVariables} from "../../../graphql/mutations/__generated__/createUser";
import {CREATE_USER, UPDATE_USER} from "../../../graphql/mutations/user";
import React, {Fragment, useEffect, useRef, useState} from "react";
import Path from "../../Path/Path";
import {useNavigate} from "react-router-dom";
import {getUser_user} from "../../../graphql/queries/__generated__/getUser";
import {updateUser, updateUserVariables} from "../../../graphql/mutations/__generated__/updateUser";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {IResult} from "../../IU/Dropdown/DropDown";
import {GET_CITIES} from "../../../graphql/queries/city";
import {cities, citiesVariables} from "../../../graphql/queries/__generated__/cities";
import {countries, countriesVariables} from "../../../graphql/queries/__generated__/countries";
import {GET_COUNTRIES} from "../../../graphql/queries/country";
import {LIST_PLANS} from "../../../graphql/queries/plan";
import {ListPlans} from "../../../graphql/queries/__generated__/ListPlans";
import Select from "../../IU/Select/Select";
import Pagination, {LIMIT_PAGINATION} from "../../IU/Pagination/Pagination";
import ListOptions from "src/components/ListOptions";
import UserTags from "../Tags/UserTags";
import ListUserGroups from "../ListUserGroups/ListUserGroups";
import ToggleButton from "src/components/IU/ToggleButton/ToggleButton";
import ProfilePTM from "../ProfilePTM/ProfilePTM";
import Modal from "src/components/Modal/Modal";
import ImageSlider from "src/components/IU/Slider/ImageSlider";

export const ACTIVE = [
	{id: "true", name: "Activé"},
	{id: "false", name: "Desactivé"},
];

export const GENDER = [
	{id: "h", name: "Homme"},
	{id: "f", name: "Femme"},
];

export const ROLE = [
	{id: "brokit", name: `Bro’Kit`},
	{id: "brokie", name: "Brokie"},
];

type errors = {
	[key: string]: boolean;
};

interface IUserProps {
	user?: getUser_user;
}

const AddUser = ({user}: IUserProps) => {
	const [showCreatedGroups, setShowCreatedGroups] = useState<boolean>(true);
	const [showPTMProfile, setShowPTMProfile] = useState<boolean>(true);
	const [showImageModale, setShowImageModale] = useState<boolean>(false);
	const [showModifyForm, setShowModifyForm] = useState<boolean>(false);
	const [images, setImages] = useState<string[]>(["/img/user-img1.png", "/img/profile-img.jpg"]);
	const [createUser] = useMutation<createUser, createUserVariables>(CREATE_USER);
	const [updateUser] = useMutation<updateUser, updateUserVariables>(UPDATE_USER);
	const [searchCities] = useLazyQuery<cities, citiesVariables>(GET_CITIES);
	const [searchCountries] = useLazyQuery<countries, countriesVariables>(GET_COUNTRIES);
	const [planSelected, setPlanSelected] = useState<any>(
		user?.plan
			? {
					id: user.plan?.id,
					name: `${user.plan?.name} - ${user.plan?.price}`,
					price: user.plan?.price,
			  }
			: null
	);
	const mapDataToPlanName = () => {
		return dataPlan?.plans?.find((p) => p?.id === user?.plan?.id)?.name;
		// return dataPlan?.plans?.map((p) => {
		// 	return {
		// 		id: p?.hexID,
		// 		name: `${p?.name}`,
		// 	};
		// });
	};
	const inputCountryRef: any = useRef();
	const inputCityRef: any = useRef();
	const [showCountriesDropdown, setShowCountriesDropdown] = useState<boolean>(false);
	const [showCitiesDropdown, setShowCitiesDropdown] = useState<boolean>(false);
	const [country, setCountry] = useState<IResult[]>([]);
	const [selectedCountry, setSelectedCountry] = useState<IResult>({
		id: user?.country?.id!,
		name: user?.country?.name!,
	});
	const [city, setCity] = useState<IResult[]>([]);
	const [selectedCity, setSelectedCity] = useState<IResult>({
		id: user?.city?.id!,
		name: user?.city?.name!,
	});
	const [errors, setErrors] = useState<errors>({});
	const navigate = useNavigate();
	const {loading: loadingPlan, error: errorPlan, data: dataPlan} = useQuery<ListPlans>(LIST_PLANS);
	if (loadingPlan) return <div>Loading...</div>;
	if (errorPlan) return <div>`Error! ${errorPlan.message}`</div>;
	const MySwal = withReactContent(Swal);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	const getInvalidInputs = (mail: string) => {
		let invalidInputs: any = {};

		if (!/^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}$/.test(mail)) invalidInputs["email"] = true;

		return invalidInputs;
	};

	const getFormData = (e): any => {
		const formData: any = {
			lastName: e.target["name"].value,
			firstName: e.target["firstName"].value,
			address: e.target["address"].value,
			gender: e.target["gender"].value,
			zipCode: e.target["zip"].value,
			countryId: selectedCountry?.id,
			cityId: selectedCity?.id,
			phoneNumber: e.target["phoneNumber"].value,
			emailNotification: e.target["emailNotification"].value === "true",
			smsNotification: e.target["smsNotification"].value === "true",
			subscribedToNewsLetter: e.target["subscribedToNewsLetter"].value === "true",
			desktopNotification: e.target["desktopNotification"].value === "true",
		};
		let invalidInputs;
		if (user?.email !== e.target["mail"].value) {
			formData.email = e.target["mail"].value;
			invalidInputs = getInvalidInputs(formData.email);
		}
		if (user?.username !== e.target["username"].value) {
			formData.username = e.target["username"].value;
		}
		if (!user?.id) {
			formData.password = e.target["password"].value;
			formData.confirmPassword = e.target["confirmPassword"].value;
		}
		if (user?.id) {
			formData.accountActivated = e.target["accountActivated"].value === "true";
			formData.accountVerified = e.target["accountVerified"].value === "true";
			formData.stripeCustomerID = e.target["stripeCustomerId"].value === "" ? null : e.target["stripeCustomerId"].value;
			formData.stripeSubscriptionID =
				e.target["stripeSubscriptionId"].value === "" ? null : e.target["stripeSubscriptionId"].value;
			formData.planID = planSelected?.id;
		}
		const emptyFields: errors = {};

		Object.keys(formData).forEach((field: string) => {
			if (field === "username" || field === "firstName" || field === "lastName") {
				if (formData[field] === "") {
					emptyFields[field] = true;
				}
			}
		});

		if (Object.keys(emptyFields).length > 0) {
			setErrors({...emptyFields});
			if (invalidInputs) {
				if (Object.keys(invalidInputs).length > 0) setErrors({...invalidInputs});
			}
			return null;
		}
		setErrors({});

		return formData;
	};
	const formHandler = async (e: any) => {
		e.preventDefault();
		const formData = getFormData(e);
		if (Object.keys(errors).length !== 0) {
			return;
		}
		if (!user) {
			await createUser({
				variables: {
					user: formData,
				},
			})
				.then(() => {
					Toast.fire({
						icon: "success",
						title: "Utilisateur crée",
					});
					navigate("/users");
				})
				.catch((e) =>
					MySwal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong: \n " + e.message,
					})
				);
		} else {
			try {
				await updateUser({
					variables: {
						user: formData,
						id: user.id,
					},
				});

				await Toast.fire({
					icon: "success",
					title: "Utilisateur à jour",
				});
				navigate("/users");
			} catch (e) {
				console.error(e);

				await MySwal.fire({
					icon: "error",
					title: "Oops...",
					// @ts-ignore
					text: "Something went wrong: \n " + e.message,
				});
			}
		}
	};

	const getUserInfo = (field): IResult[] => {
		const resultCities = field.map((c) => {
			return {
				id: c.id,
				name: c.name,
			};
		});

		return resultCities!;
	};

	const search = async (country: string) => {
		if (country.length < 3) {
			setShowCountriesDropdown(false);
			return;
		}

		setShowCountriesDropdown(true);
		try {
			const res = await searchCountries({variables: {country: country}});
			const t = getUserInfo(res?.data?.countries!);
			setCountry(t);
		} catch (e) {
			console.error(e);
		}
	};

	const searchCity = async (city: string) => {
		if (city.length < 3) {
			setShowCitiesDropdown(false);
			return;
		}

		setShowCitiesDropdown(true);
		try {
			const res = await searchCities({variables: {city: city, page: 0, limit: LIMIT_PAGINATION}});
			const t = getUserInfo(res?.data?.cities!);
			setCity(t);
		} catch (e) {
			console.error(e);
		}
	};

	const onSelectCountry = (c: IResult) => {
		setSelectedCountry(c);
		setShowCountriesDropdown(false);
		const parseCountry = c.name.split(",");

		if (parseCountry.length > 0) {
			inputCountryRef.current.value = parseCountry[0];
		}
	};

	const onSelectCity = (c: IResult) => {
		setSelectedCity(c);
		setShowCitiesDropdown(false);
		const parseCity = c.name.split(",");

		if (parseCity.length > 0) {
			inputCityRef.current.value = parseCity[0];
		}
	};

	const mapDataToPlans = () => {
		return dataPlan?.plans?.map((p) => {
			return {
				id: p?.hexID,
				name: `${p?.name} - ${p?.price}`,
			};
		});
	};

	return (
		<>
			{showImageModale && (
				<Modal
					setOpenModal={setShowImageModale}
					backgroundColor="0px 0px 0px 4000px rgba(13, 13, 13, 0.4) "
					boxShadow={"none"}
				>
					<div className={`swiper ${style.imageSlider}`}>
						<ImageSlider images={images} onClose={() => setShowImageModale(false)} />
					</div>
				</Modal>
			)}
			<React.Fragment>
				{!showModifyForm ? (
					<div className={style["wrapper"]}>
						<div className={style["wrapper-left"]}>
							<Path />
							<div className={style["wrapper__container"]}>
								<div className={style["wrapper__container-tags"]}>
									{[
										{
											title: "Centre d'intérêts",
											tags: ["Sport", "Lecture", "Photo/vidéo", "Musique", "Randonnée"],
										},
										{
											title: "Style de voyage",
											tags: ["Hôtel 5*", "Roadtrip", "Sauvage", "Croisière", "Excursion groupée", "En belle loc"],
										},
									].map(({title, tags}, index) => (
										<UserTags title={title} tags={tags} key={index} />
									))}
								</div>
								<div className={style["wrapper__container-images"]} onClick={() => setShowImageModale(true)}>
									<div className={style["img__description_layer"]}></div>
									<img
										src="/img/user-img.png"
										alt="gallery-icon"
										className={style["wrapper__container-images-image"]}
									/>
									<span className={style["wrapper__container-images-icon"]}>
										<img src="/svg/gallery-icon.svg" alt="gallery-icon" />
										<span className={style["wrapper__container-images-icon-text"]}> Photos (2)</span>
									</span>
								</div>
							</div>
							<div className={style["wrapper-groups__container"]}>
								<div className={style["wrapper-groups__container-head"]}>
									<Button
										text={"Groupes crées (2)"}
										type={"button"}
										color={showCreatedGroups ? "#FDB242" : "#989898"}
										backgroundColor={showCreatedGroups ? "#2D2C6C" : "#EEEEEE"}
										width={190}
										height={45}
										borderColor={showCreatedGroups ? "#2D2C6C" : "#989898"}
										borderRadius={51}
										onClick={() => setShowCreatedGroups(true)}
									/>
									<Button
										text={"Groupes rejointes (1)"}
										type={"button"}
										color={!showCreatedGroups ? "#FDB242" : "#989898"}
										backgroundColor={!showCreatedGroups ? "#2D2C6C" : "#EEEEEE"}
										width={190}
										height={45}
										borderColor={!showCreatedGroups ? "#2D2C6C" : "#989898"}
										borderRadius={51}
										onClick={() => setShowCreatedGroups(false)}
									/>
								</div>
								<ListUserGroups showCreatedGroups={showCreatedGroups} />
							</div>
						</div>
						<div className={style["wrapper-right"]}>
							<ToggleButton
								options={[
									{label: "Profil Perfect Travel Match", value: "profilePTM", onClick: () => setShowPTMProfile(true)},
									{label: "Profil Broke&Abroad", value: "profileBNA", onClick: () => setShowPTMProfile(false)},
								]}
							/>
							{showPTMProfile ? <ProfilePTM /> : "<ProfileBNA />"}
							<div className={style["wrapper-groups__container-btns"]}>
								<Button
									text={"Modifier"}
									type={"button"}
									color={"#2D2C6C"}
									backgroundColor={"#fff"}
									width={269}
									height={42}
									borderColor={"#2D2C6C"}
									borderRadius={7}
									onClick={() => setShowModifyForm(true)}
									prefixIcon={{
										url: "/svg/modify-icon.svg",
										alt: "edit",
										style: {},
									}}
								/>
								<Button
									text={"Blocquer"}
									type={"button"}
									color={"#BA0000"}
									backgroundColor={"#fff"}
									width={269}
									height={42}
									borderColor={"#BA0000"}
									borderRadius={7}
									onClick={() => console.log("Blocquer")}
									prefixIcon={{
										url: "/svg/block-icon.svg",
										alt: "edit",
										style: {},
									}}
								/>
							</div>
						</div>
					</div>
				) : (
					<form className={style["form"]} onSubmit={formHandler}>
						<div className={style["form-wrapper"]}>
							<div className={style["form-title"]}>Informations personnelles</div>
							<div className={style["form-group"]}>
								<label htmlFor={"username"}>Pseudo *</label>
								<input
									id={"username"}
									name="username"
									type="text"
									className={style["form-input"]}
									defaultValue={user?.username}
								/>
							</div>
							<div className={style["form-multiple"]}>
								<div className={style["form-group"]}>
									<label htmlFor={"name"}>Nom *</label>
									<input
										id={"name"}
										name="name"
										type="text"
										className={style["form-input"]}
										defaultValue={user?.lastName || ""}
									/>
								</div>
								<div className={style["form-group"]}>
									<label htmlFor={"firstName"}>Prénom *</label>
									<input
										id={"firstName"}
										name="firstName"
										type="text"
										className={style["form-input"]}
										defaultValue={user?.firstName || ""}
									/>
								</div>
							</div>
							<div className={style["form-group"]}>
								<label htmlFor={"mail"}>Adresse mail *</label>
								<input id={"mail"} name="mail" type="text" className={style["form-input"]} defaultValue={user?.email} />
							</div>
							{!user && (
								<Fragment>
									<div className={style["form-group"]}>
										<label htmlFor={"password"}>Mot de passe *</label>
										<input id={"password"} name="password" type="password" className={`${style["form-input"]} `} />
									</div>
									<div className={style["form-group"]}>
										<label htmlFor={"confirmPassword"}>Confirmation du mot de passe *</label>
										<input
											id={"confirmPassword"}
											name="confirmPassword"
											type="password"
											className={style["form-input"]}
										/>
									</div>
								</Fragment>
							)}
							<div className={style["form-group"]}>
								<label>Genre</label>
								<RadioButton name={"gender"} data={GENDER} defaultValue={user?.gender || ""} />
							</div>
							{/* <div className={style["form-group"]}>
								<label>Rôle de l'utilisateur</label>
								<RadioButton name={"role"} data={ROLE} defaultValue={mapDataToPlanName() || ""} />
							</div> */}

							<div className={style["form-multiple"]}>
								<div className={style["form-group"]}>
									<label htmlFor={"address"}>Adresse</label>
									<input
										id={"address"}
										name="address"
										type="text"
										className={style["form-input"]}
										defaultValue={user?.address || ""}
									/>
								</div>
								<div className={style["form-group"]}>
									<label htmlFor={"zip"}>Code Postal</label>
									<input
										id={"zip"}
										name="zip"
										type="text"
										className={style["form-input"]}
										defaultValue={user?.zipCode || ""}
									/>
								</div>
							</div>
							<div className={style["form-multiple"]}>
								<div className={style["locality"]}>
									<div className={style["locality-city"]}>
										<label htmlFor={"country"}>Pays</label>
										<input
											type="text"
											className={style["form-input"]}
											autoComplete="off"
											id="city"
											ref={inputCountryRef}
											defaultValue={user?.country?.name}
											onChange={(e) => {
												e.preventDefault();
												e.target.value = e?.target?.value;
												search(e?.target?.value!);
											}}
										/>
										{showCountriesDropdown && (
											<div className={style["dropdown-cities"]}>
												<ul className={style["dropdown-cities-choices"]}>
													{country?.map((c, i) => {
														return (
															<li
																key={i}
																onClick={(e) => {
																	e.preventDefault();
																	onSelectCountry(c);
																}}
															>
																{c?.name}
															</li>
														);
													})}
												</ul>
											</div>
										)}
									</div>
								</div>
								<div className={style["locality"]}>
									<div className={style["locality-city"]}>
										<label htmlFor={"city"}>Ville</label>
										<input
											type="text"
											className={style["form-input"]}
											autoComplete="off"
											id="city"
											ref={inputCityRef}
											defaultValue={user?.city?.name}
											onChange={(e) => {
												e.preventDefault();
												e.target.value = e?.target?.value;
												searchCity(e?.target?.value!);
											}}
										/>
										{showCitiesDropdown && (
											<div className={style["dropdown-cities"]}>
												<ul className={style["dropdown-cities-choices"]}>
													{city?.map((c, i) => {
														return (
															<li
																key={i}
																onClick={(e) => {
																	e.preventDefault();
																	onSelectCity(c);
																}}
															>
																{c?.name}
															</li>
														);
													})}
												</ul>
											</div>
										)}
									</div>
								</div>
								<div className={style["form-group"]}>
									<label htmlFor={"phoneNumber"}>Téléphone</label>
									<input
										id={"phoneNumber"}
										name="phoneNumber"
										type="text"
										className={style["form-input"]}
										defaultValue={user?.phoneNumber || ""}
									/>
								</div>
							</div>
							{user?.id && (
								<Fragment>
									<div className={style["form-line"]}></div>
									<div className={style["form-title"]}>COMPTE</div>
									<div className={style["form-multiple"]}>
										<div className={style["form-group"]}>
											<label> Compte activé </label>
											<RadioButton
												name={"accountActivated"}
												data={ACTIVE}
												defaultValue={user?.accountActivated.toString()}
											/>
										</div>
										<div className={style["form-group"]}>
											<label> Compte vérifié </label>
											<RadioButton
												name={"accountVerified"}
												data={ACTIVE}
												defaultValue={user?.accountVerified.toString()}
											/>
										</div>
									</div>

									<div className={style["form-line"]}></div>
									<div className={style["form-title"]}>ABONNEMENT</div>
									<div className={style["form-group"]}>
										<label htmlFor={"stripeSubscriptionId"}>ID de souscription Stripe </label>
										<input
											id={"stripeSubscriptionId"}
											name="stripeSubscriptionId"
											type="text"
											className={style["form-input"]}
											defaultValue={user?.stripeSubscriptionId || ""}
										/>
									</div>
									<div className={style["form-group"]}>
										<label htmlFor={"stripeCustomerId"}>ID client Stripe</label>
										<input
											id={"stripeCustomerId"}
											name="stripeCustomerId"
											type="text"
											className={style["form-input"]}
											defaultValue={user?.stripeCustomerId || ""}
										/>
									</div>
									<div className={style["form-group"]}>
										<label htmlFor="categories">Type d'abonnement</label>
										<Select data={mapDataToPlans()} value={planSelected} onChange={(n) => setPlanSelected(n)} />
									</div>
								</Fragment>
							)}
							<div className={style["form-line"]}></div>
							<div className={style["form-title"]}>NOTIFICATION</div>
							<div className={style["form-multiple"]}>
								<div className={style["form-group"]}>
									<label>Souscription à la newsletter</label>
									<RadioButton
										name={"subscribedToNewsLetter"}
										data={ACTIVE}
										defaultValue={user?.subscribedToNewsLetter.toString()}
									/>
								</div>
								<div className={style["form-group"]}>
									<label> Notification par sms </label>
									<RadioButton name={"smsNotification"} data={ACTIVE} defaultValue={user?.smsNotification.toString()} />
								</div>
							</div>
							<div className={style["form-multiple"]}>
								<div className={style["form-group"]}>
									<label> Notification ordinateur</label>
									<RadioButton
										name={"desktopNotification"}
										data={ACTIVE}
										defaultValue={user?.desktopNotification.toString()}
									/>
								</div>
								<div className={style["form-group"]}>
									<label> Notification email </label>
									<RadioButton
										name={"emailNotification"}
										data={ACTIVE}
										defaultValue={user?.emailNotification.toString()}
									/>
								</div>
							</div>
						</div>
						<div className={style["form-button"]}>
							<Button
								text={"Annuler"}
								type={"button"}
								color={"#FFF"}
								backgroundColor={"#2D2C6C"}
								width={140}
								height={50}
								borderRadius={10}
								onClick={() => setShowModifyForm(false)}
							/>
							<Button
								text={user?.id ? "Mettre a jour" : "Ajouter"}
								type={"submit"}
								color={"#FFF"}
								backgroundColor={"#2D2C6C"}
								width={140}
								height={50}
								borderRadius={10}
							/>
						</div>
					</form>
				)}
			</React.Fragment>
		</>
	);
};

export default AddUser;
