// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormInput_input__pimE1 {
  width: 90%;
  padding: 14.47px 22.43px;
  max-width: 90%;
  border-radius: 7px;
  background: #ffffff;
}
.FormInput_input-label__-5xis {
  display: block;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/FormInput/FormInput.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,wBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AACF;AAYE;EACE,cAAA;EAUA,kBAAA;AAnBJ","sourcesContent":[".input {\n  width: 90%;\n  padding: 14.47px 22.43px;\n  max-width: 90%;\n  border-radius: 7px;\n  background: #ffffff;\n  //@include font(\n  //                $primaryColor,\n  //                17,\n  //                1.2rem,\n  //                400,\n  //                0\n  //);\n\n  &::placeholder {\n    //@include font(rgba(0, 0, 0, 0.65), 18, 1.2rem, 200, 0);\n  }\n\n  &-label {\n    display: block;\n    //@include font(\n    //                $firstTextColor,\n    //                22,\n    //                1.3rem,\n    //                600,\n    //                0,\n    //                #{\"Kumbh Sans\",\n    //                sans-serif}\n    //);\n    margin-bottom: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `FormInput_input__pimE1`,
	"input-label": `FormInput_input-label__-5xis`
};
export default ___CSS_LOADER_EXPORT___;
