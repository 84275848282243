// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_cta-btn__DsCvv {
  filter: drop-shadow(0px 4.5px 10.1px rgba(178, 178, 179, 0.1411764706));
  font-size: 0.65rem;
  padding-left: 5px;
  letter-spacing: 0.27px;
  flex-shrink: 0;
  max-width: 100%;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.Button_cta-btn--flex__\\+tGld {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Button_cta-btn__DsCvv .Button_suffix-icon__Hd5st {
  display: flex;
}
.Button_cta-btn__DsCvv .Button_prefix-icon__00MsS {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,uEAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,kCAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAGE;EACE,aAAA;AADJ;AAIE;EACE,aAAA;AAFJ","sourcesContent":[".cta-btn {\n  filter: drop-shadow(0px 4.5px 10.1px #b2b2b324);\n  font-size: 0.65rem;\n  padding-left: 5px;\n  letter-spacing: 0.27px;\n  flex-shrink: 0;\n  max-width: 100%;\n  cursor: pointer;\n  font-family: 'Poppins', sans-serif;\n\n  &--flex {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n\n  }\n\n  .suffix-icon {\n    display: flex;\n  }\n\n  .prefix-icon {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cta-btn": `Button_cta-btn__DsCvv`,
	"cta-btn--flex": `Button_cta-btn--flex__+tGld`,
	"suffix-icon": `Button_suffix-icon__Hd5st`,
	"prefix-icon": `Button_prefix-icon__00MsS`
};
export default ___CSS_LOADER_EXPORT___;
