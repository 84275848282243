import React, { useEffect, useRef, useState } from "react";
import style from "./CouponsList.module.scss";
import Pagination, { LIMIT_PAGINATION } from "../../IU/Pagination/Pagination";
import ListOptions from "../../ListOptions";
import {
  getCoupons,
  getCouponsVariables,
} from "src/graphql/queries/__generated__/getCoupons";
import { useQuery } from "@apollo/client";
import { GET_COUPONS } from "src/graphql/queries/coupon";
import Loader from "../../IU/Loader/Loader";
import { GET_PLAN_BY_STRIPE_PRODUCT } from "src/graphql/queries/plan";
import client from "src/graphql/client";
import { plan_plan } from "src/graphql/queries/__generated__/plan";

type CouponsListProps = {
  page: number;
  setPage: any;
};

interface Coupon {
  id?: string;
  code: string;
  type: string;
  value: number;
  plans: { plan: string }[];
  maximum_use: number;
  statut: boolean;
  createdAt: number;
}

const CouponsList: React.FC<CouponsListProps> = ({ setPage, page }) => {
  const options: any = useRef();
  const [couponPlans, setCouponPlans] = useState<any[]>([]);

  const [isOptionsActivated, setIsOptionsActivated] = useState(false);
  const {
    loading: couponsLoading,
    error: couponsErrors,
    data: coupons,
  } = useQuery<getCoupons, getCouponsVariables>(GET_COUPONS, {
    variables: {
      page: page,
      limit: LIMIT_PAGINATION,
    },
    fetchPolicy: "network-only",
  });
  const couponsList = coupons?.listCoupons;

  const fetchCouponProductsDetails = async () => {
    if (!couponsList) return;

    try {
      const updatedCoupons = await Promise.all(
        couponsList?.map(async (coupon) => {
          const productDetails = await Promise.all(
            coupon.products.map(async (productId) => {
              try {
                const { data: plan } = await client.query<plan_plan>({
                  query: GET_PLAN_BY_STRIPE_PRODUCT,
                  variables: {
                    stripePriceID: productId,
                  },
                  fetchPolicy: "network-only",
                });
                return plan;
              } catch (error) {
                console.error(
                  `Error fetching plan for product ID ${productId}:`,
                  error
                );
                return null;
              }
            })
          );
          return productDetails.filter(Boolean); // Remove null values
        })
      );

      setCouponPlans(updatedCoupons); // Set plans corresponding to each coupon
    } catch (err) {
      console.error("Error fetching product details:", err);
    }
  };

  useEffect(() => {
    if (couponsList) {
      fetchCouponProductsDetails();
    }
  }, [couponsList]);

  useEffect(() => {
    const onClickWindow = (e: any) => {
      if (options?.current?.contains(e?.target as Node)) {
        return;
      }

      setIsOptionsActivated(false);
    };
    window.addEventListener("click", onClickWindow);

    return () => window.removeEventListener("click", onClickWindow);
  }, []);

  if (couponsLoading)
    return (
      <div className={style["container"]}>
        <Loader />
      </div>
    );
  if (couponsErrors) return <div>`Error! ${couponsErrors.message}`</div>;

  const formatCreatedAtDate = (createdAt: number): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate: string = new Date(createdAt * 1000).toLocaleDateString(
      "fr-FR",
      options
    );
    return formattedDate;
  };

  const calculateDaysRemaining = (expiryDate: number | null): number => {
    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (expiryDate === null) {
      return -1;
    }
    return expiryDate - currentTimestamp;
  };

  return (
    <div className={style.container}>
      <table className={style["container-table"]} cellSpacing="0">
        <thead>
          <tr className={style["container-table-header"]}>
            <th className={style[""]}>Code</th>
            <th className={style[""]}>Type</th>
            <th className={style[""]}>Value</th>
            <th className={style[""]}>Plans</th>
            <th className={style[""]}>Max Use</th>
            <th className={style[""]}>Expiry Date</th>
            <th className={style[""]}>Statut</th>
            <th className={style[""]}>Date de création</th>
            <th className={style[""]}></th>
          </tr>
        </thead>
        <tbody>
          {couponsList?.map((coupon, index) => (
            <tr key={coupon?.id}>
              <td className={style["container-table-code"]}>{coupon?.name}</td>
              <td className={style[""]}>
                <div className={style["container-table-type"]}>
                  {coupon?.amountOff ? "Montant" : "Pourcentage"}
                </div>
              </td>
              <td className={style["container-table-value"]}>
                {coupon?.amountOff
                  ? `$${coupon?.amountOff}`
                  : `${coupon?.percentOff}%`}
              </td>
              <td className={style[""]}>
                {couponPlans[index]?.map((planObj, planIndex) => (
                  <span
                    key={planIndex}
                    className={style["container-table-plan"]}
                  >
                    {planObj.GetPlanByStripeProductID.name
                      .charAt(0)
                      .toUpperCase() +
                      planObj.GetPlanByStripeProductID.name.slice(1)}
                    {`($${planObj.GetPlanByStripeProductID.price})`}
                    {planIndex !== couponPlans[index].length - 1 && ", "}
                  </span>
                ))}
              </td>
              <td className={style[""]}>
                <div className={style["container-table-type"]}>
                  {coupon?.maxUserLimit}
                </div>
              </td>
              <td className={style[""]}>
                <div className={style["container-table-type"]}>
                  {formatCreatedAtDate(coupon?.durationInMonths)}
                </div>
              </td>
              <td className={style["container-table-statut"]}>
                <div
                  className={
                    calculateDaysRemaining(coupon?.durationInMonths) > 0
                      ? style["container-table-statut-active"]
                      : style["container-table-statut-disable"]
                  }
                >
                  {calculateDaysRemaining(coupon?.durationInMonths) > 0
                    ? "En cours"
                    : "Terminé"}
                </div>
              </td>
              <td className={style[""]}>
                {formatCreatedAtDate(coupon?.createdAt)}
              </td>
              <td className={style[""]} ref={options}>
                {/* Assuming ListOptions is a separate component */}
                <ListOptions data={coupon} page={page} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={15}>
              {/* Assuming Pagination is a separate component */}
              <Pagination
                page={page}
                setPage={setPage}
                isEmpty={!couponsList || couponsList?.length === 0}
                totalOfItems={couponsList?.length}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default CouponsList;
