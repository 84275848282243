import style from "./AuthBackground.module.scss";
import { useEffect, useRef } from "react";
import {Helmet} from "react-helmet";


interface IAuthBackgroundProps {
  children: JSX.Element;
}

export default function AuthBackground({ children }: IAuthBackgroundProps) {
  const imgContainer: any = useRef();

  useEffect(() => {
    let index = 0;

    imgContainer.current.children[index].classList.add(
      style["background-image--active"]
    );

    index++;

    const interval = setInterval(() => {
      if (index === imgContainer.current.children.length) {
        index = 0;
      }

      for (let i = 0; i < imgContainer.current.children.length; i++) {
        imgContainer.current.children[i].classList.remove(
          style["background-image--active"]
        );
      }

      imgContainer.current.children[index].classList.add(
        style["background-image--active"]
      );

      index++;
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  const images: any = [];

  for (let i = 1; i <= 3; i++) {
    images.push(
      <img
        key={i}
        className={style["background-image"]}
        src={`/img/auth_background_${i}.jpg`}
        alt="image voyage"
      />
    );
  }

  return (
    <div className={style["auth-section"]}>
      <Helmet>
        <title>Page de connexion</title>
      </Helmet>
      <div ref={imgContainer} className={style["background-image-container"]}>
        {images}
      </div>
      {children}
    </div>
  );
}
