import {gql} from "@apollo/client";



export const CREATE_AFFILIATE_TRIP = gql`
    mutation createAffiliateTrip($trip: CreateTripInput!) {
        createTrip(trip: $trip) {
          id
          createdAt
        }
    }`


export const CREATE_AUTOMATED_DEALS = gql`
    mutation createAutomatedDeals($deal: ScheduleDealCreationInput!) {
        createAutomatedDeals(search: $deal) {
            success
            message
        }
    }`



export const UPDATE_TRIP = gql`
    mutation updateTrip($trip: UpdateTripInput!, $id: String!) {
        updateTrip(trip: $trip, id: $id) {
            country{
                id
            }
            city{
                id
            }
            continent
            startDate
            endDate
            numberOfNights
            numberOfDays
            departure{
                id
            }
            arrival{
                id
            }
            shortDescription
            longDescription
            price
            departureStation
            type
            travelWith
            visibility
            provider
            numberOfPassengers
            medias {
                id
            }
        }
    }`

export const DELETE_TRIP = gql`
    mutation deleteTrip($id: String!) {
        deleteTrip(id: $id) {
            success
            message
        }
    }`


export const CREATE_TRIP_CATEGORY = gql`
    mutation createTripCategory($tripCategory: CreateTripCategoryInput!) {
        createTripCategory(tripCategory: $tripCategory) {
            id
            name
            media {
                id
            }
        }
    }`

export const UPDATE_TRIP_CATEGORY = gql`
    mutation updateTripCategory($tripCategory: UpdateTripCategoryInput!, $id: String!) {
        updateTripCategory(tripCategory: $tripCategory, id: $id) {
            name
            media {
                id
            }
        }
    }`

export const DELETE_TRIP_CATEGORY = gql`
    mutation deleteTripCategory($id: String!) {
        deleteTripCategory(id: $id) {
            success
            message
        }
    }`


export const CREATE_TRIP_CONTEXT = gql`
    mutation createTripContext($context: CreateTripContextInput!) {
        createTripContext(context: $context) {
            name
            media {
                id
            }
        }
    }`

export const UPDATE_TRIP_CONTEXT = gql`
    mutation updateTripContext($context: UpdateTripContextInput!, $id: String!) {
        updateTripContext(context: $context, id: $id) {
            name
            media {
                id
            }
        }
    }`

export const DELETE_TRIP_CONTEXT = gql`
    mutation deleteTripContext($id: String!) {
        deleteTripContext(id: $id) {
            success
            message
        }
    }`