// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_container__MF1wV {
  display: flex;
  height: 15px;
  justify-content: space-between;
  padding: 1rem;
}
.Pagination_container__MF1wV .Pagination_nbPage__mrs5E select {
  margin-right: 5px;
  padding: 2px 5px;
  font-size: 0.8rem;
  border: 0.2px solid #bbb4b4;
  box-shadow: 0px 2px 9px 0px rgba(155, 153, 153, 0.25);
}
.Pagination_container__MF1wV .Pagination_nbPage__mrs5E span {
  color: #888686;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.923px;
}
.Pagination_container__MF1wV .Pagination_buttons__nsbPv {
  display: flex;
  gap: 2rem;
}
.Pagination_container__MF1wV .Pagination_buttons__nsbPv .Pagination_button__ICcZA {
  font-weight: bold;
  cursor: pointer;
}
.Pagination_container__MF1wV .Pagination_buttons__nsbPv .Pagination_button--disable__tY2UZ {
  cursor: default;
  opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/Pagination/Pagination.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;AACF;AACI;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;EACA,qDAAA;AACN;AACI;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;AACN;AAGE;EACE,aAAA;EACA,SAAA;AADJ;AAGI;EACE,iBAAA;EACA,eAAA;AADN;AAGM;EACE,eAAA;EACA,YAAA;AADR","sourcesContent":[".container {\n  display: flex;\n  height: 15px;\n  justify-content: space-between;\n  padding: 1rem;\n  .nbPage {\n    select {\n      margin-right: 5px;\n      padding: 2px 5px;\n      font-size: 0.8rem;\n      border: 0.2px solid #bbb4b4;\n      box-shadow: 0px 2px 9px 0px rgba(155, 153, 153, 0.25);\n    }\n    span {\n      color: #888686;\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 16.923px;\n    }\n  }\n\n  .buttons {\n    display: flex;\n    gap: 2rem;\n\n    .button {\n      font-weight: bold;\n      cursor: pointer;\n\n      &--disable {\n        cursor: default;\n        opacity: 0.4;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Pagination_container__MF1wV`,
	"nbPage": `Pagination_nbPage__mrs5E`,
	"buttons": `Pagination_buttons__nsbPv`,
	"button": `Pagination_button__ICcZA`,
	"button--disable": `Pagination_button--disable__tY2UZ`
};
export default ___CSS_LOADER_EXPORT___;
