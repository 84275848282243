import style from "./NewLocation.module.scss"
import Frame from "../IU/Frame/Frame";
import LocationForm from "./LocationForm";
import {useState} from "react";
import {ValidateString} from "../../validators";
import {useMutation} from "@apollo/client";
import {createCity, createCityVariables} from "../../graphql/mutations/__generated__/createCity";
import {CREATE_CITY, CREATE_COUNTRY} from "../../graphql/mutations/city";
import {Toast} from "../Deals/DealFormAffiliate";
import {useNavigate} from "react-router-dom";
import Select from "../IU/Select/Select";
import CountryForm from "./CountryForm";
import {createCountry, createCountryVariables} from "../../graphql/mutations/__generated__/createCountry";

interface IFormData {
    [key: string]: any;
}
interface IFieldsValidator {
    [key: string]: (val: string) => boolean;
}

const LOCATION_TYPE =[
    {id:"city", name: "une ville"},
    {id:"country", name: "un pays"},
]


const NewLocation = () => {
    const navigate = useNavigate()
    const [errors, setErrors] = useState<any>({});
    const [locationTypeSelected, setLocationTypeSelected] = useState(LOCATION_TYPE[0]);
    const [createCityApollo]= useMutation<createCity, createCityVariables>(CREATE_CITY)
    const [createCountryApollo]= useMutation<createCountry, createCountryVariables>(CREATE_COUNTRY)

    const validateForm = (data: IFormData): boolean => {

        const errors: any = {};

        const fieldsValidator: IFieldsValidator = {
            state: (val: string): boolean => {
                return ValidateString(val, 1);
            },
            country: (val: string): boolean => {
                return ValidateString(val, 1);
            },
             city: (val: string): boolean => {
                return ValidateString(val, 1);
            },
             lat: (val: string): boolean => {
                return ValidateString(val, 1);
            },
             lon: (val: string): boolean => {
                return ValidateString(val, 1);
            },

        };

        for (const field in data) {
            const val = data[field];
            const isOk = ValidateString(val, 1);
            if (!isOk) {
                errors[field] = `${field} est invalide.`;
            }
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }
        setErrors({});
        return true;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const state = e.target["state"]?.value;
        const country = e.target["country"]?.value;
        const city = e.target["city"]?.value;
        const lat = e.target["lat"]?.value;
        const lon = e.target["lon"]?.value;

        const formData: IFormData = {
            stateName: state?.length > 0 ? state : null,
            countryName: country?.length > 0 ? country : null,
            name: city?.length > 0 ? city : null,
            latitude: lat?.length > 0 ? lat : null,
            longitude: lon?.length > 0 ? lon : null,
        };
        const isOk = validateForm(formData);
        if (!isOk) {
            return;
        }

        createCity(formData)

    }


    const handleCountrySubmit = (e, emoji) => {
        e.preventDefault();
        const name = e.target["country"]?.value;
        const countryCode = e.target["countryCode"]?.value;
        const phoneCode = e.target["phoneCode"]?.value;
        const lat = e.target["lat"]?.value;
        const lon = e.target["lon"]?.value;

        const formData: IFormData = {
            name: name?.length > 0 ? name : null,
            countryCode: countryCode?.length > 0 ? countryCode : null,
            phoneCode: phoneCode?.length > 0 ? phoneCode : null,
            latitude: lat?.length > 0 ? lat : null,
            longitude: lon?.length > 0 ? lon : null,
            emoji: emoji?.length > 0 ? emoji : null,
        };
        const isOk = validateForm(formData);
        if (!isOk) {
            return;
        }

        createCountry(formData)

    }

    const createCity = async (data) => {
        try{
            await createCityApollo({
                variables: {
                    city: data
                }
            })
            await Toast.fire({
                icon: 'success',
                title: 'Ville crée'
            })
            navigate('/locations')
        }catch (e) {
            console.log(e)
        }
    }

    const createCountry = async (data) => {
        try{
            await createCountryApollo({
                variables: {
                    country: data
                }
            })
            await Toast.fire({
                icon: 'success',
                title: 'Pays crée'
            })
            navigate('/locations')
        }catch (e) {
            console.log(e)
        }
    }

    return(
        <Frame borderRadius={"20px"} boxShadow={"0px 5px 19px 0px #DCDCDC"}>
            <div className={style['container--frame']}>

                <div className={style['header']}>
                    <div className={style["text"]}>
                        Ajouter <Select value={locationTypeSelected} onChange={(n)=>{setLocationTypeSelected(n)}} data={LOCATION_TYPE} isWithBorder={false}/>
                    </div>

                    <div className={style["line"]}></div>
                </div>
            </div>
            {locationTypeSelected?.id === LOCATION_TYPE[0].id &&
                <LocationForm handleSubmit={handleSubmit} formErrors={errors}/>
            }
            {locationTypeSelected?.id === LOCATION_TYPE[1].id &&
                <CountryForm handleSubmit={handleCountrySubmit} formErrors={errors}/>
            }

        </Frame>

    )
}

export default NewLocation
