import {
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    LinearScale,
    LineElement,
    PointElement,
    Filler,
    ScriptableContext
} from 'chart.js';
import { Line} from 'react-chartjs-2'
import {lineData} from "../../../data/TripsData";
import style from "./LineChart.module.scss";
ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    Filler,
    PointElement,
)

const LineChart = () => {



    const labels = lineData.map((d) =>{
        const date = new Date(d.newUsersDate)
        return date.getUTCDate() + ' ' +date.toLocaleDateString('fr-FR',{month:"short"})
    })
    const datas = lineData.map((d)=>{return d.newUsersNumber})
    const max = Math.max(...datas)
    const data = {
        labels: labels,
        datasets:[{
            data: datas,
            fill: {
                target: 'origin',
            },
            borderColor: '#6967DB',
            pointBackgroundColor: '#6967DB',
            tension: 0.5,
            backgroundColor: (context: ScriptableContext<"line">) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                gradient.addColorStop(0, "rgba(231, 231, 255, 1)");
                gradient.addColorStop(1, "rgba(231, 231, 255, 0)");
                return gradient;
            },

        }]
    }

    const options: ChartOptions<'line'> = {
        plugins:{
            legend:{
                display: false,
            }
        },
        scales:{
            x:{
                grid: {
                    display: false,
                }
            },
            y:{
                min:0,
                grace: '5%',
                grid:{
                    color: 'rgba(129, 129, 129,0.06)'
                }
            },
        },
        maintainAspectRatio: false,
    }

    return(
        <div className={style['line']}>
            <Line data={data} options={options} height={100}/>
        </div>
    )
}

export default LineChart;