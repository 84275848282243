import { gql } from "@apollo/client";

export const GET_COUPONS = gql`
  query getCoupons($page: Int!, $limit: Int!) {
    listCoupons(page: $page, limit: $limit) {
      id
      name
      stripeCouponId
      products
      createdAt
      percentOff
      amountOff
      durationInMonths
      maxUserLimit
    }
  }
`;

export const GET_COUPON = gql`
  query getCoupon($id: String!) {
    coupon(id: $id) {
      id
      name
      products
      percentOff
      amountOff
      durationInMonths
      maxUserLimit
    }
  }
`;
