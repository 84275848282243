import {useRef, useEffect, useCallback, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import styles from "./ImagesSlider.module.scss";

const ImageSlider = ({images, onClose}) => {
	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const swiperRef = useRef(null);
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);

	const updateNavigation = useCallback(() => {
		if (swiperRef.current && prevRef.current && nextRef.current) {
			swiperRef.current.params.navigation.prevEl = prevRef.current;
			swiperRef.current.params.navigation.nextEl = nextRef.current;
			swiperRef.current.navigation.init();
			swiperRef.current.navigation.update();
		}
	}, []);

	useEffect(() => {
		if (swiperRef.current) {
			updateNavigation();
		}
	}, [updateNavigation]);
	const handleSlideChange = (swiper) => {
		setIsBeginning(swiper.isBeginning);
		setIsEnd(swiper.isEnd);
	};
	return (
		<div className={styles.sliderContainer}>
			<Swiper
				modules={[Navigation]}
				spaceBetween={10}
				slidesPerView={1}
				onSwiper={(swiper) => {
					swiperRef.current = swiper;
					setTimeout(updateNavigation, 0);
				}}
				onSlideChange={handleSlideChange}
				className={styles.swiperContainer}
			>
				{images.map((image, index) => (
					<SwiperSlide key={index} className={styles.swiperSlide}>
						<img src={image} alt={`Slide ${index}`} className={styles.image} />
					</SwiperSlide>
				))}
			</Swiper>

			<button
				ref={prevRef}
				className={`${styles.customNavButton} ${styles.prevButton} ${isBeginning ? styles.disabled : ""}`}
				disabled={isBeginning}
			>
				<img src="/svg/prev-icon.svg" alt="Previous" />
			</button>

			<button
				ref={nextRef}
				className={`${styles.customNavButton} ${styles.nextButton} ${isEnd ? styles.disabled : ""}`}
				disabled={isEnd}
			>
				<img src="/svg/next-icon.svg" alt="Next" />
			</button>
			<button className={styles.closeButton} onClick={onClose}>
				<img src="/svg/close-white.svg" alt="Close" />
			</button>
		</div>
	);
};

export default ImageSlider;
