import Logo from "../Logos/BrokeAndAbroadLogo/Logo"
import style from "./AuthHeader.module.scss"

export default function AuthHeader() {
    return (
        <nav
          className={`${style["nav-container"]} ${style["nav-container--transparent"]} ${style["nav-container--center"]}`}
        >
          <Logo/>
        </nav>
      );
}
