// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Colors */
/* Mixin font */
/* Breakpoints */
/* Mixins media query */`, "",{"version":3,"sources":["webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAAA,WAAA;AA4BA,eAAA;AAgCA,gBAAA;AAOA,uBAAA","sourcesContent":["/* Colors */\n\n$blueRose: #2D2C6C;\n$goodKarma: #353472;\n$perfectDark: #343E92;\n$mandarinSorbet: #FDB242;\n$white: #fff;\n$emptiness: #FCFCFC;\n$dark: #000;\n$blueMarguerite: #6563AF;\n$doverGrey: #848486;\n$moonLanding: #A7A7A7;\n$redWarning: #DD4646;\n\n\n$primaryColor: $blueRose;\n$secondaryColor: $mandarinSorbet;\n$titleColor: $goodKarma;\n$subtitleColor: $perfectDark;\n$firstTextColor: $white;\n$secondTextColor: $dark;\n$colorLink: $emptiness;\n$inactiveDotColor: $blueMarguerite;\n$inactiveItemColor: $doverGrey;\n$placeholderColor: $moonLanding;\n$warning: $redWarning;\n\n\n/* Mixin font */\n\n@mixin font($color, $lineHeight, $fontSize, $fontWeight, $letterSpacing, $fontFamily: #{\"Poppins\", sans-serif}) {\n  font-family: $fontFamily;\n  font-size: $fontSize;\n  font-weight: $fontWeight;\n  color: $color;\n  letter-spacing: #{$letterSpacing}px;\n  line-height: #{$lineHeight}px;\n}\n\n\n@mixin title {\n  font-family: 'Kumbh Sans', sans-serif;\n  font-weight: 700;\n  font-size: clamp(2.2rem, 4.5vw, 2.7rem);\n  line-height: 30px;\n  letter-spacing: 3px;\n  text-transform: uppercase;\n  color: $titleColor;\n}\n\n@mixin subtitle {\n  font-family: 'Manrope', sans-serif;\n  font-weight: 400;\n  font-size: clamp(1.5rem, 4.5vw, 2rem);\n  line-height: 20px;\n  letter-spacing: 1px;\n  color: $subtitleColor;\n}\n\n\n/* Breakpoints */\n\n$mobileBreakpoint: 415px;\n$desktopBreakpoint: 1120px;\n\n\n\n/* Mixins media query */\n\n@mixin medium-screen {\n  @media all and (min-width: $mobileBreakpoint) {\n    @content\n  }\n}\n\n\n@mixin large-screen {\n  @media all and (min-width: $desktopBreakpoint) {\n    @content\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
