import style from './MonthPicker.module.scss'
import moment from "moment/moment";
import {MonthsArr} from "../../../../types/enums/months";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {IDepartureDateInput, setDepartureDateFilter} from "../../../../store/filter-trip-slice";
import {useState} from "react";

const MonthPicker = () => {

    const [date, setDate] = useState(moment().year())
    const dispatch = useDispatch();
    const {departureDateFilter} = useSelector((state: RootState) => state.filtersTrip)

    const updateDateDisplay = (change) => {
        const now = moment()
        if (change === '-'){
            if (date >2022){
                setDate(prevState => prevState-1)
            } else{
                return
            }

        }else{
            if (date < now.add(3,'year').year()){
                setDate(prevState => prevState+1)
            }
        }
    }

    const selectDepartureDate = (departureDate: IDepartureDateInput, month) => {
        if (!departureDateFilter.some(d => d.month === month && d.year === date)){
            dispatch(setDepartureDateFilter([...departureDateFilter, departureDate]))
        } else {
            const newDate = departureDateFilter.filter( c => (c.month !== departureDate.month && c.year !== departureDate.year))
            dispatch(setDepartureDateFilter(newDate))
        }
    }

    return(
        <div className={style['wrapper']}>
            <div className={style["header"]}>
                <span className={style["clickable"]} onClick={()=>updateDateDisplay('-')}>&#60;</span>
                <div className={style['year']}>{date}</div>
                <span className={style["clickable"]} onClick={()=>updateDateDisplay('+')}>&#62;</span>
            </div>
            <div className={style["body"]}>
                {MonthsArr.map((month, index)=>(
                    <div key={index} className={`${style["month"]} ${departureDateFilter.some(d => d.month === index+1 && d.year === date) && style['month--selected']}`} onClick={()=>{selectDepartureDate({month:index+1, year: date}, index+1)}}>
                        {month}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MonthPicker