import { gql } from "@apollo/client";

export const BUS_STATIONS = gql`
  query busStations($city: String!) {
    busStations(city: $city) {
      city
      trigram
      latitude
      longitude
      municipality_code
    }
  }
`;

export const TRAIN_STATIONS = gql`
  query trainStations($city: String!) {
    trainStations(city: $city) {
      city
      trigram
      latitude
      longitude
      municipality_code
    }
  }
`;
