import style from './FreePics.module.scss'
import React, {useRef, useState} from "react";
import Button from "../../../IU/Button/Button";
import {useLazyQuery, useMutation} from "@apollo/client";
import {SEARCH_PICTURES_UNSPLASH} from "../../../../graphql/queries/media";
import {SearchPictures, SearchPicturesVariables} from "../../../../graphql/queries/__generated__/SearchPictures";
import {ListMedias_listMedias} from "../../../../graphql/queries/__generated__/ListMedias";
import {
    UploadImageFromUnsplash,
    UploadImageFromUnsplashVariables
} from "../../../../graphql/mutations/__generated__/UploadImageFromUnsplash";
import {UPLOAD_IMAGE_FROM_UNSPLASH} from "../../../../graphql/mutations/media";
import {MySwal} from "../../../Deals/DealFormAffiliate";


const CATEGORIES = [
    {id: "Experimental", name: "Experimental" },
    {id: "3DRenders", name: "3D Renders" },
    {id: "Architecture", name: "Architecture & Interiors" },
    {id: "Fashion", name: "Fashion & Beauty" },
    {id: "events", name: "Current events" },
    {id: "Nature", name: "Nature" },
    {id: "Food", name: "Food & Drink" },
    {id: "Film", name: "Film" },
    {id: "People", name: "People" },
]

const FreePics = ({setImages, setOpenModal}: any) => {
    const searchRef:any = useRef()
    const [searchPictureUnsplash, {loading, error, data}] = useLazyQuery<SearchPictures, SearchPicturesVariables>(SEARCH_PICTURES_UNSPLASH)
    const [uploadImageFromUnsplash, {loading: loadingUnsplash, error: errorUnsplash,data: dataUnsplash}] = useMutation<UploadImageFromUnsplash, UploadImageFromUnsplashVariables>(UPLOAD_IMAGE_FROM_UNSPLASH)
    const [mediaSelected, setMediaSelected] = useState<ListMedias_listMedias[]>([])

    const isMediaSelected = (mediaSelected, id) =>{

        return mediaSelected?.findIndex( m=> m?.name === id) !== -1;
    }
    const clickHandler = async() =>{
        if (searchRef.current.value.length > 0){
            try{
                await searchPictureUnsplash({variables: {page: 50, search: searchRef.current.value}})

            } catch (e) {
                console.log(e)
                await MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    // @ts-ignore
                    text: 'Something went wrong: \n ' + e.message,
                })
            }

        }
    }

    const clickImageHandler = (image) => {
        if (mediaSelected){
            if ( mediaSelected){
                if (isMediaSelected(mediaSelected, image.name) ){
                    setMediaSelected(mediaSelected?.filter(m => m.name !== image.name))
                }
                if (!isMediaSelected(mediaSelected, image.name)) {
                    setMediaSelected(prevState => [...prevState, image])
                }
            }
            else {
                if (!isMediaSelected(mediaSelected, image.name)){
                    setMediaSelected([image])
                }
            }
        }
        else{
            setMediaSelected([image])
        }
    }

    const submitImageHandler = async() => {
        const requests = mediaSelected.map(image => {
            return uploadImageFromUnsplash({variables: {photoID: image.name}})
        })

            try {
                const responses = await Promise.all(requests)
                responses.map(response => {
                    setImages(prev => [...prev, response?.data?.createRandomPicture])
                    setOpenModal(false)
                })
            } catch (e) {
                console.log(e)
                await MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    // @ts-ignore
                    text: 'Something went wrong: \n ' + e.message,
                })
            }






    }

    return(
        <div className={style['wrapper']}>
            <div className={style['wrapper-header']}>
                <img className={style['wrapper-header-logo'] } src={'/img/unsplash-logo.png'}/>
                <div className={style['wrapper-header-input']}>

                    <input type='text' id='search' placeholder="Rechercher" className={style['']} ref={searchRef}/>
                    <img className={style['wrapper-header-input-svg'] } src={'/svg/search.svg'}/>
                </div>
                <Button
                    text={"Valider"}
                    onClick={clickHandler}
                    color={"#FFF"}
                    backgroundColor={"#2D2C6C"}
                    width={140}
                    height={50}
                    borderRadius={10}
                />
            </div>
            <div className={style['wrapper-body']}>
                {data?.searchPictures.map(((image,index) =>(
                    <div key={index} className={`${style['image']}  `}  onClick={()=>{clickImageHandler(image)}}>
                        <img src={image.url} alt={image.name} className={`${(isMediaSelected(mediaSelected, image.name) )  && style['image-selected']} `} />
                    </div>
                )))
                }
            </div>
            {mediaSelected.length !== 0 &&
                <div className={style['submit']}>
                    <Button
                        text={"Valider selection"}
                        onClick={submitImageHandler}
                        color={"#FFF"}
                        backgroundColor={"#2D2C6C"}
                        width={140}
                        height={50}
                        borderRadius={10}
                    />
                </div>
            }

        </div>
    )
}
export default FreePics;