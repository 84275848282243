import _default, {
    ArcElement,
    Chart as ChartJS,
    ChartOptions,
    BarElement,
    PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend,
} from "chart.js";
import{Bar} from "react-chartjs-2";
import style from "./BarChart.module.scss";
import {viewsData} from "../../../data/TripsData";
import {useState} from "react";

const BarChart:React.FC<{ endDate: string }> = (props)  => {
    ChartJS.register(
        BarElement,
        LinearScale,
        CategoryScale,
        Title,
        Tooltip,
        Legend
    );

    const views = viewsData.map((views) => {return views.views})
    const labels = viewsData.map((views) => {
        const date = new Date(views.viewsDate)
        return `${date.toLocaleDateString('fr-FR',{weekday:"short"})}  ${date.getDay()}`
    })
    const max = Math.max(...views)
    const [data, setData] = useState({
        datasets: [{
            data:views,
            backgroundColor: '#E7EFFF',
            borderRadius:10,
        }],
        labels,
    })


    const options: ChartOptions<'bar'> = {
        responsive: true,
        plugins:{
            legend:{
                display: false,
            },
            tooltip:{
                displayColors:false,
                boxWidth:0,
                backgroundColor:'transparent',
                bodyColor: '#707ADA',
                textDirection: 'ltr',
                bodyFont: {
                    family: 'Poppins',
                    size:30
                },
                titleColor: 'transparent'
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font:{
                        size:8
                    }
                }
            },
            y: {
                min: 0,
                grace: '5%',
                grid: {
                    color: 'rgba(129, 129, 129,0.06)'
                },
                ticks: {
                    font:{
                        size:8,

                    }
                }
            },
        },
        maintainAspectRatio: false,
    }



    return (
        <div className={style['bar']}>
            <div className={style['bar-infos']}>
                <div className={`${style['bar-infos-articles']} ${style['bar-infos-info']}`}>
                    <div className={style['bar-infos-articles-header']}>
                        <div className={style['bar-infos-articles-header-svg']}>
                            <svg  width="12" height="9" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.35019 2.1999H7.25019M6.35019 3.9999H7.25019M7.25019 5.7999H2.7502M2.7502 2.1999V3.9999H4.55019V2.1999H2.7502ZM1.8502 7.5999H8.15019C8.38889 7.5999 8.61781 7.50508 8.78659 7.3363C8.95537 7.16752 9.05019 6.9386 9.05019 6.6999V1.2999C9.05019 1.06121 8.95537 0.832289 8.78659 0.663506C8.61781 0.494723 8.38889 0.399902 8.15019 0.399902H1.8502C1.6115 0.399902 1.38258 0.494723 1.2138 0.663506C1.04502 0.832289 0.950195 1.06121 0.950195 1.2999V6.6999C0.950195 6.9386 1.04502 7.16752 1.2138 7.3363C1.38258 7.50508 1.6115 7.5999 1.8502 7.5999Z" stroke="#EA52B6" strokeWidth="0.710526" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <span>Articles publiés</span>
                    </div>
                    <div className={style['bar-infos-articles-container']}>
                        36
                    </div>
                    <div className={style['bar-infos-views-footer']}>
                        {props.endDate}
                    </div>
                </div>
                <div className={`${style['bar-infos-views']} ${style['bar-infos-info']} `}>
                    <div className={style['bar-infos-views-header']}>
                        <div className={style['bar-infos-views-header-svg']}>
                            <svg width="12" height="9" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.707764 4.00022C1.20521 3.02445 1.96275 2.20521 2.89667 1.63304C3.83058 1.06087 4.90451 0.758057 5.99976 0.758057C7.09502 0.758057 8.16894 1.06087 9.10286 1.63304C10.0368 2.20521 10.7943 3.02445 11.2918 4.00022C10.7943 4.97599 10.0368 5.79523 9.10286 6.36739C8.16894 6.93956 7.09502 7.24238 5.99976 7.24238C4.90451 7.24238 3.83058 6.93956 2.89667 6.36739C1.96275 5.79523 1.20521 4.97599 0.707764 4.00022ZM5.99976 6.16022C6.57263 6.16022 7.12204 5.93265 7.52711 5.52757C7.93219 5.12249 8.15976 4.57308 8.15976 4.00022C8.15976 3.42735 7.93219 2.87795 7.52711 2.47287C7.12204 2.06779 6.57263 1.84022 5.99976 1.84022C5.4269 1.84022 4.87749 2.06779 4.47241 2.47287C4.06733 2.87795 3.83976 3.42735 3.83976 4.00022C3.83976 4.57308 4.06733 5.12249 4.47241 5.52757C4.87749 5.93265 5.4269 6.16022 5.99976 6.16022ZM5.99976 5.08022C5.71333 5.08022 5.43863 4.96643 5.23609 4.76389C5.03355 4.56135 4.91976 4.28665 4.91976 4.00022C4.91976 3.71378 5.03355 3.43908 5.23609 3.23654C5.43863 3.034 5.71333 2.92022 5.99976 2.92022C6.2862 2.92022 6.5609 3.034 6.76344 3.23654C6.96598 3.43908 7.07976 3.71378 7.07976 4.00022C7.07976 4.28665 6.96598 4.56135 6.76344 4.76389C6.5609 4.96643 6.2862 5.08022 5.99976 5.08022Z" fill="#4255FD"/>
                            </svg>
                        </div>
                        <span>Visite au total</span>
                    </div>
                    <div className={style['bar-infos-views-container']}>
                        4024
                    </div>
                    <div className={style['bar-infos-views-footer']}>
                        {props.endDate}
                    </div>
                </div>
            </div>
            <div className={style['bar-chart']}>
                <Bar data={data} options={options} height={100}/>
            </div>

        </div>
    );
}

export default BarChart;