import React, { useEffect, useState } from "react";
import style from "./Coupons.module.scss";
import Path from "../../Path/Path";
import Button from "../../IU/Button/Button";
import CouponsList from "./CouponsList";
import { useNavigate } from "react-router-dom";

type CouponsProps = {
  // Add any necessary props here
};

/**
 * Renders the Coupons component.
 *
 * @param props - The component props.
 * @returns The rendered Coupons component.
 */
const Coupons: React.FC<CouponsProps> = (props) => {
  const navigate = useNavigate();
  const newCouponHandler = () => {
    navigate("/coupon/new-coupon");
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const p = queryParameters.get("p");
  const [page, setPage] = useState(p ? parseInt(p) - 1 : 0);

  useEffect(() => {
    if (p === null) {
      setPage(0);
    }
  }, [p]);

  return (
    <div className={style["wrapper"]}>
      <div className={style["wrapper-header"]}>
        <Path />
        <div className={style["wrapper-header-buttons"]}>
          <Button
            text={"Ajouter un nouveau coupon"}
            color={"#FFF"}
            width={220}
            borderRadius={10}
            backgroundColor={"#343E92"}
            prefixIcon={{
              url: "/svg/add.svg",
              alt: "add",
            }}
            onClick={newCouponHandler}
          />
        </div>
      </div>
      <CouponsList page={page} setPage={setPage} />
    </div>
  );
};

export default Coupons;
