import style from './DealsFilters.module.scss'
import Button from "../IU/Button/Button";
import Filter from "./Filter";
import React, {Fragment, useEffect, useState} from "react";
import FilterItem from "./FilterItem";
import {useSelector} from "react-redux";
import {RootState} from "../../store";


const FILTER_ITEMS = [
    {
        type: 'departure',
        label: 'Ville de départ',
        icon: '/svg/plane-blue.svg'
    },
    {
        type: 'continent',
        label: 'Continent',
        icon: '/svg/world-blue.svg'
    },
    {
        type: 'category',
        label: 'Catégorie',
        icon: '/svg/category-blue.svg'
    },
    {
        type: 'calendar',
        label: 'Période',
        icon: '/svg/calendar-blue.svg'
    },
    {
        type: 'price',
        label: 'Gamme de prix',
        icon: '/svg/price-filter-blue.svg'
    }
]
export interface IFilter{
    continent: string[],
    month: number,
    months: number[],
    departureCity: string[],
    priceMax: number,
    priceMin: number
}


const DealsFilters = ({ showFilter, setShowFilter}) => {

    return(
        <div className={style['container']}>
            <Button
                text={"Filtrer"}
                color={"#343E92"}
                width={90}
                height={40}
                borderRadius={10}
                borderColor={"#343E92"}
                backgroundColor={"transparent"}
                prefixIcon={{
                    url:"/svg/filter.svg",
                    alt:"filter",
                }}
                onClick={()=>{setShowFilter(!showFilter)}}
            />
            {
                showFilter &&
                <div className={style['dropdown-container']}>
                    <div className={style['dropdown']}>
                        <div className={style['header']}>
                            <span>Filtre</span>
                            <img src={`/svg/filter.svg`} alt={'filter icone'} />
                        </div>

                        {FILTER_ITEMS.map((item, k) =>
                            <Fragment key={k}>
                                <FilterItem label={item.label} icon={item.icon} type={item.type} />
                            </Fragment>

                        )}



                    </div>
                </div>
            }
        </div>
    )
}

export default DealsFilters