// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListOptions_options__moj1T {
  position: absolute;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 1px 15px 3px rgba(0, 0, 0, 0.1);
  padding: 5px;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);
}
.ListOptions_options-option__bST6z {
  display: flex;
  cursor: pointer;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  font-weight: 300;
}
.ListOptions_options-option__bST6z:hover {
  background: #F5F5F5;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/ListOptions.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,+CAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AACF;AAAE;EACE,aAAA;EACA,eAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AACE;EACE,mBAAA;EACA,gBAAA;AACJ","sourcesContent":[".options{\n  position: absolute;\n  background: white;\n  border-radius: 10px;\n  box-shadow: 2px 1px 15px 3px rgb(0 0 0 / 10%);\n  padding: 5px;\n  display: flex;\n  flex-direction: column;\n  transform: translateX(-50%);\n  &-option{\n    display: flex;\n    cursor: pointer;\n    padding: 10px;\n    gap: 10px;\n    border-radius: 10px;\n    font-weight: 300;\n  }\n\n  &-option:hover{\n    background: #F5F5F5;\n    font-weight: 500;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options": `ListOptions_options__moj1T`,
	"options-option": `ListOptions_options-option__bST6z`
};
export default ___CSS_LOADER_EXPORT___;
