import {gql} from "@apollo/client";


export const UPLOAD_IMAGE = gql`
    mutation UploadImage($file: UploadFile!, $fileType: FileType!) {
        uploadFile(file: $file, fileType: $fileType) {
           id
           name
           directory
           url
        }
    }`


export const UPLOAD_IMAGE_FROM_UNSPLASH = gql`
    mutation UploadImageFromUnsplash($photoID: String!) {
        createRandomPicture(photoID: $photoID) {
           id
           name
           url
           directory
        }
    }`
