import {gql} from "@apollo/client";

export const LIST_MEDIAS = gql`
    query ListMedias($id: Int!, $limit: Int!, $sort: SortAndFilter) {
        listMedias(page: $id, limit: $limit, sort: $sort) {
            id
            directory
            url
            mimeType
            type
            name
        }
    }`


export const SEARCH_PICTURES_UNSPLASH = gql`
    query SearchPictures($search: String!, $page: Int!) {
        searchPictures(search: $search, page: $page) {
            url
            name
        }
    }`


