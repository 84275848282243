// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__VLNmt {
  position: relative;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  border: 4px solid #f1f3fe;
}
.Loader_loader-track__u1PhY {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 4px solid transparent;
  border-bottom-color: #2d2c6c;
  border-left-color: #2d2c6c;
  border-radius: 50%;
  transform: rotate(-45deg);
  animation: Loader_spin__qwBYk 0.8s linear infinite;
}
.Loader_loader-track__u1PhY::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 4px;
  height: 4px;
  background-color: #2d2c6c;
  border-radius: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.Loader_loader-track__u1PhY img {
  position: absolute;
  top: 86%;
  right: -2.5px;
  transform: translateY(-50%) rotate(45deg);
}
.Loader_loader-container__vLFGd {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 17px;
}

@keyframes Loader_spin__qwBYk {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/IU/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AACF;AACE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,6BAAA;EACA,4BAAA;EACA,0BAAA;EACA,kBAAA;EACA,yBAAA;EACA,kDAAA;AACJ;AACI;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,+CAAA;AACN;AAEI;EACE,kBAAA;EACA,QAAA;EACA,aAAA;EACA,yCAAA;AAAN;AAIE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AAFJ;;AAOA;EACE;IACE,uBAAA;EAJF;EAOA;IACE,0BAAA;EALF;AACF","sourcesContent":[".loader {\n  position: relative;\n  width: 49px;\n  height: 49px;\n  border-radius: 50%;\n  border: 4px solid #f1f3fe;\n\n  &-track {\n    position: absolute;\n    top: -4px;\n    right: -4px;\n    bottom: -4px;\n    left: -4px;\n    border: 4px solid transparent;\n    border-bottom-color: #2d2c6c;\n    border-left-color: #2d2c6c;\n    border-radius: 50%;\n    transform: rotate(-45deg);\n    animation: spin 0.8s linear infinite;\n\n    &::before {\n      content: \"\";\n      position: absolute;\n      top: 5px;\n      left: 4px;\n      width: 4px;\n      height: 4px;\n      background-color: #2d2c6c;\n      border-radius: 50%;\n      transform: translate(-50%, -50%) rotate(-45deg);\n    }\n\n    img {\n      position: absolute;\n      top: 86%;\n      right: -2.5px;\n      transform: translateY(-50%) rotate(45deg);\n    }\n  }\n\n  &-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    row-gap: 17px;\n  }\n\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(-360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__VLNmt`,
	"loader-track": `Loader_loader-track__u1PhY`,
	"spin": `Loader_spin__qwBYk`,
	"loader-container": `Loader_loader-container__vLFGd`
};
export default ___CSS_LOADER_EXPORT___;
