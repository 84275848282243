import style from "./BestTripTable.module.scss";
import {bestTrips} from "../../data/TripsData";
import React from "react";

const BestTripTable = () => {


    return (
        <div className={style['container']}>
            <table>
                <thead className={style['container-header']}>
                <tr>
                    <th className={style['container-header-first']}> </th>
                    <th className={style['container-header-second']}>Destinations</th>
                    <th className={style['container-header-third']}>Taux de clique</th>
                    <th className={style['container-header-fourth']}>Nombre de réservations</th>
                    <th className={style['container-header-fifth']}>Type</th>
                </tr>
                </thead>

                <tbody className={style['container-body']}>
                {bestTrips!.map((trip) => (
                        <tr key={trip.id} className={style['container-body-row']}>
                            <td className={style['']}>
                                <div className={`${style[`container-body-row-rank-r${trip.id}`]} ${style['container-body-row-rank']}`}>
                                    {trip.id}
                                </div>

                            </td>
                            <td className={style['container-body-row-destination']}>
                                {trip.city.name}, {trip.country.name}
                            </td>
                            <td className={style['container-body-row-click']}>
                                {trip.clickRate}
                            </td>
                            <td className={style['container-body-row-booking']}>
                                {trip.nbBooking}
                            </td>
                            <td className={style['container-body-row-type']}>
                                {trip.type}
                            </td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
        </div>
    )
}

export default BestTripTable;