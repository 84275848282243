import {gql} from "@apollo/client";

export const GET_ALL_COUNTRIES = gql`
query countriesLocation( $page: Int!, $limit: Int!, $sort: SortAndFilter){
    countriesLocation( page: $page, limit: $limit, sort: $sort){
        countries{
            id
            name
            countryCode
            latitude
            longitude
            phoneCode
            emoji
        }
        totalCount
    }
}`

export const GET_COUNTRIES = gql`
query countries($country: String){
    countries(country: $country){
            id
            name
            countryCode
           
        
    }
}`

export const GET_COUNTRY = gql`
query country($countryID: String!){
    country(countryID: $countryID){
            id
            name
            countryCode
            latitude
            longitude
            phoneCode
            emoji
    }
}`
