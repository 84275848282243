import style from "./NewLocation.module.scss"
import Frame from "../IU/Frame/Frame";
import {useState} from "react";
import {ValidateString} from "../../validators";
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_COUNTRY, UPDATE_COUNTRY} from "../../graphql/mutations/city";
import {MySwal, Toast} from "../Deals/DealFormAffiliate";
import {useNavigate, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import {LIMIT_PAGINATION} from "../IU/Pagination/Pagination";
import {GET_ALL_COUNTRIES, GET_COUNTRIES, GET_COUNTRY} from "../../graphql/queries/country";
import {updateCountry, updateCountryVariables} from "../../graphql/mutations/__generated__/updateCountry";
import {deleteCountry, deleteCountryVariables} from "../../graphql/mutations/__generated__/deleteCountry";
import CountryForm from "./CountryForm";
import {country, countryVariables} from "../../graphql/queries/__generated__/country";

interface IFormData {
    [key: string]: any;
}
const Country = () => {
    const navigate = useNavigate()
    const params = useParams();
    const [errors, setErrors] = useState<any>({});
    const {loading, error, data} = useQuery<country, countryVariables>(GET_COUNTRY, { variables: { countryID: params?.id! }, fetchPolicy: "network-only"})
    const [updateCountry]= useMutation<updateCountry, updateCountryVariables>(UPDATE_COUNTRY)
    const [deleteCountry]= useMutation<deleteCountry, deleteCountryVariables>(DELETE_COUNTRY)

    const validateForm = (data: IFormData): boolean => {

        const errors: any = {};


        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }


        for (const field in data) {
            const val = data[field];
            const isOk = ValidateString(val, 1);
            if (!isOk) {
                errors[field] = `${field} est invalide.`;
            }
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }
        setErrors({});
        return true;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const countryCode = e.target["countryCode"]?.value;
        const country = e.target["country"]?.value;
        const emoji = e.target["emoji"]?.value;
        const phoneCode = e.target["phoneCode"]?.value;
        const lat = e.target["lat"]?.value;
        const lon = e.target["lon"]?.value;

        const formData: IFormData = {
            countryCode: countryCode?.length > 0 ? countryCode : null,
            name: country?.length > 0 ? country : null,
            emoji: emoji?.length > 0 ? emoji : null,
            phoneCode: phoneCode?.length > 0 ? phoneCode : null,
            latitude: lat?.length > 0 ? lat : null,
            longitude: lon?.length > 0 ? lon : null,
        };
        const isOk = validateForm(formData);
        if (!isOk) {
            return;
        }


       updateData(formData, data?.country?.id)

    }

    const updateData = async (data, id) => {
        try{
            await updateCountry({
                variables: {
                    country: data,
                    id: id
                }
            })
            await Toast.fire({
                icon: 'success',
                title: 'Pays mis à jour'
            })
            navigate('/locations')
        }catch (e) {
            console.log(e)
        }
    }

    const deleteLocationHandler = (id) => {
        Swal.fire({

            title: 'Voulez-vous vraiment supprimé ce pays ?',
            text: "Il sera impossible d'annuler cette action!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD4646',
            cancelButtonColor: '#2D2C6C',
            confirmButtonText: 'Supprimer !',
            cancelButtonText: 'Annuler !',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    deleteCountry({
                        variables: {
                            id: id
                        }, refetchQueries: [{
                            query: GET_ALL_COUNTRIES, variables:{id: 0, limit: LIMIT_PAGINATION},fetchPolicy: "network-only"}]
                    })
                    Swal.fire(
                        'Supprimé!',
                        'Le pays a bien été supprimé.',
                        'success'
                    )
                    navigate('/locations')
                }
                catch (e:any) {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong: \n '+ e.message,
                    })
                }

            }
        })

    }

    return(
        <Frame borderRadius={"20px"} boxShadow={"0px 5px 19px 0px #DCDCDC"}>
            <div className={style['container--frame']}>

                <div className={style['header']}>
                    Mettre à jour un pays
                    <div className={style["line"]}></div>
                </div>
            </div>
            <CountryForm handleSubmit={handleSubmit} formErrors={errors} country={data?.country} deleteLocationHandler={deleteLocationHandler}/>
        </Frame>

    )
}

export default Country
