import style from './Loader.module.scss'

interface ILoaderProps {
    text?: string;
    height?: number
}

export default function Loader({ text, height }: ILoaderProps) {
  return (
    <div className={style["loader-container"]}>
        <div className={style["loader"]} style={{
            height: height && height,
            width: height && height
        }}>
            <div className={style["loader-track"]}>
                <img src="/svg/plane_loader.svg" alt="icône avion" />
            </div>
        </div>
        {text && <div className={style["loader-text"]}>{text}</div>}
    </div>
  )
}
