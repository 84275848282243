import { useController } from "react-hook-form";
import style from "./InputField.module.scss";

interface IInputFieldProps {
  id: string;
  name?: string;
  type?: string;
  placeholder?: string;
  height?: number;
  clas?: string;
  autoComplete?: string;
  errorClass?: string;
  control?: any;
}

const InputField = ({
  id = "",
  name = "",
  type = "text",
  placeholder = "",
  height = 0,
  clas = "",
  autoComplete = "on",
  errorClass = "",
  control,
}: IInputFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  return (
    <div>
      <input
        {...field}
        className={clas}
        style={{ height: height ? height : "auto" }}
        type={type}
        id={id}
        name={field.name}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />

      {error && <p className={errorClass}>{error.message}</p>}
    </div>
  );
};

export default InputField;
