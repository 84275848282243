
import moment from "moment/moment";

export const ValidateEmail = (email: string): boolean => {
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(email)
}

export const ValidateString = (val: string, len: number = 2): boolean => {
    if (!val) {
        return false
    }

    return val.replace(/\s/g, '').length > len
}



export const ValidateBirthdate = (date: number): boolean => {
    const currentDate = moment()

    const birthdate = moment.unix(date)
    birthdate.set({hours: 13, minutes: 30})

    const birthdayDuration = moment.duration(currentDate.diff(birthdate));
    const diffBirthdayYears = birthdayDuration.asYears();

    return diffBirthdayYears > 17
}