import style from "./Modal.module.scss";

interface IModalProps {
	children: JSX.Element | JSX.Element[];
	setOpenModal: any;
	backgroundColor?: string;
	boxShadow?: string;
}

const Modal = ({
	setOpenModal,
	backgroundColor = "white",
	boxShadow = "rgba(0, 0, 0, 0.35) 0px 5px 15px",
	children,
}: IModalProps) => {
	const clickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.currentTarget === e.target) {
			setOpenModal(false);
		}
	};

	return (
		<div className={style["modal"]} onClick={clickHandler} id="outside">
			<div
				className={style["modal-wrapper"]}
				style={{...(backgroundColor && {backgroundColor: backgroundColor}), ...(boxShadow && {boxShadow: boxShadow})}}
			>
				{children}
			</div>
		</div>
	);
};
export default Modal;
