import style from "./Localisations.module.scss"
import Path from "../Path/Path";
import React, {Fragment, useEffect, useRef, useState} from "react";
import Button from "../IU/Button/Button";
import {useNavigate} from "react-router-dom";
import {useLazyQuery, useQuery} from "@apollo/client";
import {LIMIT_PAGINATION} from "../IU/Pagination/Pagination";
import {GET_ALL_CITIES} from "../../graphql/queries/city";
import {
    citiesLocation,
    citiesLocationVariables
} from "../../graphql/queries/__generated__/citiesLocation";
import LocationList from "./LocationList";
import Loader from "../IU/Loader/Loader";
import {SortAndFilter} from "../../../__generated__/globalTypes";
import {setCityIDFilter} from "../../store/filter-trip-slice";
import AutoComplete from "../IU/AutoCompleteInput/AutoComplete";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import Select from "../IU/Select/Select";
import CountryList from "./CountryList";
import {GET_ALL_COUNTRIES} from "../../graphql/queries/country";
import {countriesLocation, countriesLocationVariables} from "../../graphql/queries/__generated__/countriesLocation";

const LOCATION_TYPE =[
    {id:"city", name: "villes"},
    {id:"country", name: "pays"},
]
const Localisations = () => {
    const navigate = useNavigate()
    const queryParameters = new URLSearchParams(window.location.search)
    const p = queryParameters.get("p")
    const [page, setPage] = useState(p ? parseInt(p)-1 : 0)
    const [locationTypeSelected, setLocationTypeSelected] = useState(LOCATION_TYPE[0]);
    const dispatch = useDispatch();
    const { cityIDFilter } = useSelector((state: RootState) => state.filtersTrip)
    const [filters, setFilters] = useState<SortAndFilter>( {
        fields: 'created_at=+'
    })
    const [listCities,{loading: loadingCities, error: errorCities, data: dataCities}] = useLazyQuery<citiesLocation, citiesLocationVariables>(GET_ALL_CITIES)
    const [listCountries,{loading, error, data}] = useLazyQuery<countriesLocation, countriesLocationVariables>(GET_ALL_COUNTRIES)
    useEffect(()=>{
        if (locationTypeSelected === LOCATION_TYPE[0]){
            listCities({variables: { page: (page*LIMIT_PAGINATION), limit:LIMIT_PAGINATION, sort: filters}}).catch(e=>console.log(e))
        }else{
            listCountries({variables: { page: (page*LIMIT_PAGINATION), limit:LIMIT_PAGINATION}}).catch(e=>console.log(e))
        }
    },[locationTypeSelected, page, p, cityIDFilter])
    useEffect(() => {
        if (locationTypeSelected === LOCATION_TYPE[0]){
            if (cityIDFilter?.name && cityIDFilter?.name !== ""){
                listCities({variables: { page: (page*LIMIT_PAGINATION), limit:LIMIT_PAGINATION, sort: {
                            fields: 'created_at=+',
                            filters: 'name='+cityIDFilter.name
                        }}}).catch(e=>console.log(e))
                setFilters({fields: 'created_at=+',filters: 'name='+cityIDFilter.name})
            }else{
                setFilters({
                    fields: 'created_at=+'
                })
            }
        } else{

        }
    },[cityIDFilter])

    if (loading || loadingCities) return <div className={style['container']}><Loader/></div>;
    if (error || errorCities) {
        return <div className={style['container']}>`Error! `</div>;
    }

    const newLocationHandler = () =>{
        navigate('/locations/new-location')
    }

    return(
        <div className={style['wrapper']}>
            <div className={style['wrapper-header']}>
                <Path/>
                <Button
                    text={"Ajouter une localisation"}
                    color={"#FFF"}
                    width={200}
                    height={43}
                    borderRadius={10}
                    backgroundColor={"#343E92"}
                    prefixIcon={{
                        url:"/svg/add.svg",
                        alt:"add",
                    }}
                    onClick={newLocationHandler}
                />
            </div>
            <div className={style['header']}>
                <div className={style["text"]}>
                    Liste des <Select value={locationTypeSelected} onChange={(n)=>{setLocationTypeSelected(n)}} data={LOCATION_TYPE} isWithBorder={false}/>
                </div>


            </div>


            {
                locationTypeSelected === LOCATION_TYPE[0] &&
                <Fragment>
                    <div className={style['search']}>
                        <AutoComplete name={"searchCity"} data={cityIDFilter} setData={(city)=>{dispatch(setCityIDFilter(city))}} type={"city"} placeholder={"Entrer une ville"} defaultValue={cityIDFilter.name}/>
                        <Button
                            text={"Reinitialiser"}
                            color={"#FFF"}
                            width={110}
                            height={40}
                            borderRadius={10}
                            backgroundColor={"#343E92"}
                            onClick={()=>{dispatch(setCityIDFilter(""));setFilters({})}}
                        />
                    </div>
                    <LocationList locations={dataCities?.citiesLocation} page={page} setPage={setPage}/>
                </Fragment>
            }
            {
                locationTypeSelected === LOCATION_TYPE[1] &&
                <CountryList locations={data?.countriesLocation} page={page} setPage={setPage}/>
            }

        </div>
    )

}
export default Localisations