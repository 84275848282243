import style from './Download.module.scss'
import Button from "../../../IU/Button/Button";
import {useCallback, useState} from "react";
import { useMutation} from "@apollo/client";
import {FileRejection, useDropzone} from "react-dropzone";
import {UPLOAD_IMAGE} from "../../../../graphql/mutations/media";
import {LIST_MEDIAS} from "../../../../graphql/queries/media";
import InputError from "../../../IU/InputError/InputError";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Download = ({setIsGalleryActive, setIsDownloadActive, fileType}: any) => {
    const [errors, setErrors] = useState<string>();
    const [uploadImage] = useMutation(UPLOAD_IMAGE);
    const maxSize = 1000000
    const MySwal = withReactContent(Swal)
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    const onDrop = useCallback((acceptedFiles:File[], fileRejections: FileRejection[]) => {
        acceptedFiles.map((file: File) => {
            uploadImage({
                variables: {
                    file:{
                        file: file
                    }, fileType
                }, onCompleted:() => {

                }, refetchQueries:[{
                    query: LIST_MEDIAS, variables: {id: 0, limit: 200}
                }]
            }).then(()=>{
                Toast.fire({
                    icon: 'success',
                    title: 'Media crée'
                })
                setTimeout(() => {setIsDownloadActive(false);
                setIsGalleryActive(true);},1000)})
                .catch(e=>
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong: \n '+ e.message,
                    }))
        })
        fileRejections.map((file) => {
            const error = 'L\'image '+ file.file.name + ' fait plus qu\'1MB'
            setErrors(error)
        })


    }, [setIsDownloadActive,setIsGalleryActive])

    const {getRootProps, getInputProps,  isDragAccept, isDragReject} = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.png', '.jpeg', '.jpg']
        },
        // @ts-ignore
        maxSize,
    })

    return (
        <div className={style['download']}>
            <div className={style['download-wrapper']} >

                <div className={style['download-wrapper-content']}>
                    <div className={style['download-wrapper-content']} {...getRootProps()}>
                        <div className={style['download-wrapper-content-title']}>
                            Dépôt de fichiers par glisser ou déposer
                        </div>
                        <Button
                            text={"Télécharger"}
                            color={"#FFF"}
                            backgroundColor={"#343E92"}
                            width={180}
                            height={52}
                            borderRadius={10}
                            prefixIcon={{
                                url:"/svg/download.svg",
                                alt:"Télécharger",
                                style:{}
                            }}
                        />
                        <input {...getInputProps()} />
                        <div className={style['download-wrapper-content-footer']}>PNG, JGP ou PDF sont inférieurs que 1 MB</div>
                    </div>
                </div>
            </div>

            {isDragAccept && (<p>All files will be accepted</p>)}
            {isDragReject && (<p>Some files will be rejected</p>)}
            {errors &&
                <InputError message={errors} />
            }

        </div>
    )


}
export default Download