import React, {useState} from "react";
import DatePicker from "../IU/DatePicker/DatePicker";
import DoughnutChart from "./Doughnut/DoughnutChart";
import style from "./Chart.module.scss"
import BarChart from "./Bar/BarChart";
import LineChart from "./Line/LineChart";
import BarChartUsers from "./Bar/BarChartUsers";
import Button from "../IU/Button/Button";
import BestTripTable from "../Tables/BestTripTable";
import BestArticleTable from "../Tables/BestArticleTable";

const Chart: React.FC<{ title: string, width: string, height: string, typeOfChart: string }> = (props) => {

    const [startDate, setStartDate] = useState<Date>(new Date("10/01/2022"));
    const [endDate, setEndDate] = useState<Date>(new Date("10/19/2022"));
    const [showDate, setShowDate]: any = useState(false);

    let displayDate = "";
    const showDateHandler = () => {
        setShowDate(!showDate)
    }

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        displayDate = `${startDate.toLocaleDateString('fr-FR', {day: "numeric"})} - ${endDate.toLocaleDateString('fr-FR', {
            day: "numeric",
            month: 'short',
            year: 'numeric'
        })}`
    } else if (startDate.getFullYear() === endDate.getFullYear()) {
        displayDate = `${startDate.toLocaleDateString('fr-FR', {
            day: "numeric",
            month: "short"
        })} - ${endDate.toLocaleDateString('fr-FR', {day: "numeric", month: 'short', year: 'numeric'})}`
    } else {
        displayDate = `${startDate.toLocaleDateString('fr-FR', {
            day: "numeric",
            month: 'short',
            year: 'numeric'
        })} - ${endDate.toLocaleDateString('fr-FR', {day: "numeric", month: 'short', year: 'numeric'})}`
    }


    return (
        <div className={style['chart']}
             style={{
                 width: props.width ? `${props.width}` : "auto",
                 height: props.height ? `${props.height}` : "auto",
             }}>
            <div className={style['chart-header']}>
                <div className={style['chart-header-title']}>
                    {props.title}
                </div>
                <div className={style['chart-header-date']}>
                    <Button
                        text={displayDate}
                        width={150}
                        height={30}
                        fontSize={0.6}
                        color={"#1562D6"}
                        borderRadius={10}
                        backgroundColor={"rgba(142, 187, 255,.4)"}
                        fontWeight={"bold"}
                        onClick={showDateHandler}
                        suffixIcon={{
                            url: "/svg/arrow-down.svg",
                            alt: "arrow down",
                            style: {
                                marginLeft: "5px",
                                paddingRight: "5px"
                            }
                        }}
                    />

                    {showDate && <DatePicker
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    />}

                </div>
            </div>
            <div className={style['chart-container']}>
                {(props.typeOfChart === 'doughnut') &&
                <DoughnutChart/>
                }
                {(props.typeOfChart === 'bar') &&
                <BarChart endDate={endDate.toLocaleDateString()}/>
                }
                {(props.typeOfChart === 'barUsers') &&
                <BarChartUsers endDate={endDate.toLocaleDateString()}/>
                }
                {(props.typeOfChart === 'line') &&
                <LineChart/>
                }
                {(props.typeOfChart === 'bestTrips') &&
                <BestTripTable/>
                }
                {(props.typeOfChart === 'bestArticles') &&
                <BestArticleTable/>
                }

            </div>
        </div>
    );
}

export default Chart;