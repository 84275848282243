import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import AddCoupon from "./addCoupon/AddCoupon";
import {
  getCoupon,
  getCouponVariables,
} from "src/graphql/queries/__generated__/getCoupon";
import { GET_COUPON } from "src/graphql/queries/coupon";

const Coupon = () => {
  const params = useParams();
  const { loading, error, data } = useQuery<getCoupon, getCouponVariables>(
    GET_COUPON,
    { variables: { id: params?.id! }, fetchPolicy: "network-only" }
  );
  if (loading) return <div>Loading...</div>;
  if (error) return <div>`Error! ${error.message}`</div>;

  return <AddCoupon coupon={data?.coupon} />;
};

export default Coupon;
