import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser($user: CreateUserInput!) {
    createUser(user: $user) {
      user {
        id
      }
      token
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!, $id: String!) {
    updateUser(user: $user, id: $id) {
      id
      firstName
      lastName
    }
  }
`;
