// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Path_path__hglR5 {
  width: auto;
  height: 2vh;
  padding-left: 0.5vw;
  border-left: 3px solid #FDB242;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #888686;
}
.Path_path-details__VXYCa {
  display: flex;
  gap: 10px;
}
.Path_path-details__VXYCa img {
  width: 5px;
}
.Path_path--active__s0WAD {
  color: #2D2C6C;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Path/Path.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;AACJ;AAAI;EACE,UAAA;AAEN;AACE;EACE,cAAA;EACA,iBAAA;AACJ","sourcesContent":[".path{\n  width: auto;\n  height: 2vh;\n  padding-left: .5vw;\n  border-left: 3px solid #FDB242;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  color: #888686;\n\n  &-details{\n    display: flex;\n    gap: 10px;\n    img{\n      width: 5px;\n    }\n  }\n  &--active{\n    color: #2D2C6C;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"path": `Path_path__hglR5`,
	"path-details": `Path_path-details__VXYCa`,
	"path--active": `Path_path--active__s0WAD`
};
export default ___CSS_LOADER_EXPORT___;
