import style from './TextEditor.module.scss'
import React, { useState} from "react";
import Button from "../Button/Button";
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const getInitialState = (defaultValue) => {
    if (defaultValue) {
        const blocksFromHtml = htmlToDraft(defaultValue)
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        )
        return EditorState.createWithContent(contentState)
    } else {
        return EditorState.createEmpty()
    }
}

const TextEditor = ({text, setText, closeModal, toolbarClassName = "toolbar-down"}: any) => {

    const [editorState, setEditorState] = useState<EditorState>( getInitialState(text))

    const htmlText = draftToHtml(convertToRaw(editorState?.getCurrentContent()))
    return (
        <div>

            <Editor
                wrapperClassName={style['wrapper']}
                editorClassName={style['editor']}
                toolbarClassName={style[toolbarClassName]}
                editorState={editorState}
                onEditorStateChange={newEditorState => setEditorState(newEditorState)}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'history'],
                    inline: {inDropdown: false,options:['bold','italic','underline']},
                    font: {className: style['disable']},
                    list: { inDropdown: false, className:  style['disable']},
                    textAlign: {  },
                    link: {  },
                    history: { inDropdown: true, className: style['disable']},
                }}
            />
            <div className={style['footer']}>
                <Button
                    text={"Brouillon"}
                    color={"#2D2C6C"}
                    width={120}
                    height={50}
                    borderRadius={10}
                    backgroundColor={"#FFF"}
                    onClick={()=>{}}
                    borderColor={"#2D2C6C"}/>
                <Button
                    text={"Soumettre"}
                    color={"#FFF"}
                    backgroundColor={"#2D2C6C"}
                    width={140}
                    height={50}
                    onClick={()=>{setText(htmlText);closeModal(false)}}
                    borderRadius={10}
                />
            </div>
        </div>

    );
}

export default TextEditor