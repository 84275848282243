import style from "./SidebarItem.module.scss";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MapPathToURL } from "../../enums/path";

const SidebarItem = ({ item, isChild = false }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const isSelectedItems = (path) => {
    if (item.childrens) {
      return location.pathname.includes(path);
    }
    if (path === "/") {
      return location.pathname === path;
    } else return location.pathname.includes(`${path}`);
  };

  if (item.childrens) {
    return (
      <div className={` ${isOpen && style["open"]}`}>
        <NavLink
          to={MapPathToURL[item.value]}
          className={` ${style["sidebar-item"]} ${style["plain"]} ${
            isSelectedItems(MapPathToURL[item.value]) && style["active"]
          }`}
        >
          <span>
            {item.icon && (
              <img
                className={` ${style["sidebar-menu-item-image"]} `}
                src={
                  isSelectedItems(MapPathToURL[item.value])
                    ? item.iconSelected
                    : item.icon
                }
                alt={"icon"}
              />
            )}
            {item.label}
          </span>
          <img
            src={`/svg/chevron-down.svg`}
            className={` ${style["toggle-btn"]} ${
              isOpen && style["toggle-btn__open"]
            }`}
            onClick={() => setIsOpen(!isOpen)}
            alt={"chevron"}
          />
        </NavLink>
        <div className={style["sidebar-content"]}>
          {item.childrens.map((child, index) => (
            <SidebarItem key={index} item={child} isChild={true} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        to={MapPathToURL[item.value]}
        className={`${isChild && style["submenu-item"]} ${
          style["sidebar-item"]
        } ${style["plain"]} ${
          isSelectedItems(MapPathToURL[item.value]) &&
          style["submenu-item__active"]
        }`}
      >
        {isChild && (
          <div className={`${isChild && style["submenu-item-border"]}`}></div>
        )}
        {item.icon && (
          <img
            className={` ${style["sidebar-menu-item-image"]} `}
            src={
              isSelectedItems(MapPathToURL[item.value])
                ? item.iconSelected
                : item.icon
            }
            alt={"icone"}
          ></img>
        )}
        <span>{item.label}</span>
      </NavLink>
    );
  }
};

export default SidebarItem;
