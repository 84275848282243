// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateRangeInput_wrapper__oJtN1 {
  border: 0.5px solid #888686;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
}
.DateRangeInput_wrapper__input__0to-u {
  outline: none;
  border: none;
  -webkit-appearance: none;
  caret-color: transparent;
  cursor: pointer;
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
.DateRangeInput_wrapper__oJtN1 img {
  padding: 10px;
  border-left: 0.5px solid #888686;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/DateRangeInput/DateRangeInput.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,YAAA;EACA,wBAAA;EACA,wBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACE;EACE,aAAA;EACA,gCAAA;AACJ","sourcesContent":[".wrapper{\n  border: 0.5px solid #888686;\n  border-radius: 8px;\n  box-sizing: border-box;\n  display: flex;\n\n  &__input{\n    outline: none;\n    border: none;\n    -webkit-appearance: none;\n    caret-color: transparent;\n    cursor: pointer;\n    width: 100%;\n    padding: 12px 20px;\n    box-sizing: border-box;\n    border-radius: 10px;\n  }\n  img{\n    padding: 10px;\n    border-left: 0.5px solid #888686;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DateRangeInput_wrapper__oJtN1`,
	"wrapper__input": `DateRangeInput_wrapper__input__0to-u`
};
export default ___CSS_LOADER_EXPORT___;
