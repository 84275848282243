import style from "./FormInput.module.scss";

interface IFormInputProps {
  id: string;
  type?: string;
  labelName: string;
  placeholder?: string;
  height?: number;
  defaultValue?: string;
}

const FormInput = ({
  id,
  type,
  labelName,
  placeholder,
  height,
  defaultValue,
}: IFormInputProps) => {
  return (
    <div>
      <label className={style["input-label"]} htmlFor={id}>
        {labelName}
      </label>
      <input
        className={style["input"]}
        style={{ height: height ? height : "auto" }}
        type={type ? type : "text"}
        id={id}
        placeholder={placeholder ? placeholder : ""}
        defaultValue={defaultValue && defaultValue}
      />
    </div>
  );
};

export default FormInput;
