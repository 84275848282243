// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.CheckboxRound_CheckboxRound__dCOa7 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}
.CheckboxRound_CheckboxRound__dCOa7 input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.CheckboxRound_CheckboxRound__dCOa7 label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
}
.CheckboxRound_CheckboxRound__dCOa7 label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #000000;
  padding: 5px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50%;
}
.CheckboxRound_CheckboxRound__dCOa7 input:checked + label {
  padding-left: 17px;
}
.CheckboxRound_CheckboxRound__dCOa7 input:checked + label:before {
  content: "";
  display: block;
  position: absolute;
  top: -6px;
  left: 0px;
  width: 12px;
  height: 12px;
  background: #2bd747;
  border-radius: 50%;
}
.CheckboxRound_CheckboxRound__dCOa7 input:checked + label:before {
  border: 1px solid #2bd747;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/Checkbox-round/CheckboxRound.module.scss"],"names":[],"mappings":"AAAA;;EAIE,sBAAA;EACA,SAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,UAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;AACJ;AACE;EACE,kBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;AACJ;AACI;EACE,WAAA;EACA,wBAAA;EACA,6BAAA;EACA,yBAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACN;AAEE;EACE,kBAAA;AAAJ;AAEE;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ","sourcesContent":[":before,\n*:after {\n  -webkit-box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\n.CheckboxRound {\n  position: relative;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n\n  input {\n    padding: 0;\n    height: initial;\n    width: initial;\n    margin-bottom: 0;\n    display: none;\n    cursor: pointer;\n  }\n  label {\n    position: relative;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    padding: 0;\n\n    &:before {\n      content: \"\";\n      -webkit-appearance: none;\n      background-color: transparent;\n      border: 1px solid #000000;\n      padding: 5px;\n      display: inline-block;\n      position: relative;\n      cursor: pointer;\n      margin-right: 5px;\n      border-radius: 50%;\n    }\n  }\n  input:checked + label {\n    padding-left: 17px;\n  }\n  input:checked + label:before {\n    content: \"\";\n    display: block;\n    position: absolute;\n    top: -6px;\n    left: 0px;\n    width: 12px;\n    height: 12px;\n    background: #2bd747;\n    border-radius: 50%;\n  }\n  input:checked + label:before {\n    border: 1px solid #2bd747;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CheckboxRound": `CheckboxRound_CheckboxRound__dCOa7`
};
export default ___CSS_LOADER_EXPORT___;
