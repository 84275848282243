// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DoughnutChart_doughnut__1eM29 {
  width: 100%;
  height: 85%;
  padding: 1rem 0rem;
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Charts/Doughnut/DoughnutChart.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".doughnut{\n  width: 100%;\n  height: 85%;\n  padding: 1rem 0rem;\n  margin-left: auto;\n  margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"doughnut": `DoughnutChart_doughnut__1eM29`
};
export default ___CSS_LOADER_EXPORT___;
