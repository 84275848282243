import Path from "../Path/Path";
import Chart from "../Charts/Chart";
import style from "./Home.module.scss"
import Tables from "../Tables/Tables";
import {Helmet} from "react-helmet";

const HomePage = ()  => {


    return (
        <div>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <Path/>
            <div className={style['row']}>
                <Chart title={"Nombre de bons plans"} width={"40%"} height={"33vh"} typeOfChart={"doughnut"}/>
                <Tables title={"Top bons plans visités"} width={"54%"} height={"33vh"} typeOfTable={"bestTrip"}/>
            </div>
            <div className={style['row']}>
                <Chart title={"Nombre d'utilisateurs actifs"} width={"42%"} height={"38vh"} typeOfChart={"barUsers"}/>
                <Chart title={"L'évolution des nouveaux utilisateurs"} width={"54%"} height={"38vh"} typeOfChart={"line"}/>
            </div>
            <div className={style['row']}>
                <Chart title={"Nombre des articles"} width={"40%"} height={"39vh"} typeOfChart={"bar"}/>
                <Tables title={"Top des articles visités"} width={"57%"} height={"39vh"} typeOfTable={"bestArticle"}/>
            </div>


        </div>
    );
}

export default HomePage;