import {createSlice} from "@reduxjs/toolkit";


const initialState ={
    isLoggedIn: false,
    user:{},
    token: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getCredentials: (state) => {
            return {user: state.user, token: state.token, isLoggedIn: state.isLoggedIn}
        },
        setCredentials: (state, action) => {
            const { user, token } = action.payload;
            state.user = user;
            state.token = token;
            state.isLoggedIn = true
        },
        logout:(state) =>{
            localStorage.clear()
            state.user = initialState.user;
            state.token = initialState.token
            state.isLoggedIn = false
        }
    }
})


export const authAction = authSlice.actions;
export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user