import {ApolloClient, ApolloLink, from,  InMemoryCache} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {BNA_AUTH_TOKEN} from "../globals";
import { createUploadLink } from "apollo-upload-client";
import {GRAPH_SERVER_URL} from "../env";

const httpLink = createUploadLink({uri: GRAPH_SERVER_URL})

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({message, locations, path}) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ))
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
})

const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({headers}: any) => {
        const h = {
            headers: {
                ...headers
            }
        }

        // Authenticate request only if token exist
        const token = localStorage.getItem(BNA_AUTH_TOKEN)
        if (token) {
            h.headers['Authorization'] = token
        }

        return h
    });
    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, authLink, httpLink])
});




export default client