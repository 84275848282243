import { useNavigate, useLocation } from "react-router-dom";
import style from "./Path.module.scss";
import { MapPathToString, PathEnum } from "../../enums/path";

const Path = () => {
  const location = useLocation();

  const path = location.pathname.split("/");
  let pathFiltered: string[] = path.filter((p) => {
    return p != null && p != "";
  });

  return (
    <div className={style["path"]}>
      Tableau de bord
      {pathFiltered.map((p, i) => (
        <div className={style["path-details"]} key={i}>
          <img src="/svg/chevron-right-blue.svg" alt="chevron" />
          <span
            className={
              pathFiltered.length === i + 1 ? style["path--active"] : ""
            }
          >
            {MapPathToString[p]}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Path;
