export enum PathEnum {
  Dashboard = "dashboard",
  Users = "users",
  Deals = "deals",
  Contexts = "contexts",
  Categories = "categories",
  BlogCategories = "blogCategories",
  Articles = "articles",
  Location = "locations",
  Coupons = "coupon",
  NewDeal = "new-deal",
  NewUser = "new-user",
  NewCoupon = "new-coupon",
  NewArticle = "new-article",
  AddDescription = "add-description",
  AddAnecdote = "add-anecdote",
}

export const MapPathToString: { [key: string]: string } = {
  [PathEnum.Dashboard]: "Dashboard",
  [PathEnum.Users]: "Gestion d'utilisateurs",
  [PathEnum.Deals]: "Tous les bons plans",
  [PathEnum.Articles]: "Tous les articles",
  [PathEnum.Location]: "Localisations",
  [PathEnum.Coupons]: "Tous les coupons",
  [PathEnum.NewCoupon]: "Ajouter un nouveau coupon",
  [PathEnum.NewDeal]: "Ajouter un bon plan",
  [PathEnum.NewUser]: "Ajouter un utilisateur",
  [PathEnum.NewArticle]: "Ajouter un article",
  [PathEnum.AddDescription]: "Rédiger une description",
  [PathEnum.AddAnecdote]: "Rédiger une Brok'Anecdote",
};

export const MapPathToURL: { [key: string]: string } = {
  [PathEnum.Dashboard]: "/",
  [PathEnum.Users]: "/users",
  [PathEnum.Deals]: "/deals",
  [PathEnum.Categories]: "/deals/categories",
  [PathEnum.Contexts]: "/deals/contexts",
  [PathEnum.Articles]: "/articles",
  [PathEnum.BlogCategories]: "/articles/categories",
  [PathEnum.Location]: "/locations",
  [PathEnum.Coupons]: "/coupon",
  [PathEnum.NewCoupon]: "/coupon/new-coupon",
};
