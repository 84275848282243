// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageModal_modal__Orts5 {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-family: "Poppins", sans-serif;
}
.ImageModal_modal-wrapper__LEBwl {
  width: 90vw;
  height: 90vh;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.ImageModal_modal-wrapper-header__JOz9p {
  display: flex;
  width: 100%;
  height: 7vh;
  border-bottom: 0.5px solid #C3BFBF;
}
.ImageModal_modal-wrapper-header__JOz9p div {
  cursor: pointer;
}
.ImageModal_modal-wrapper-header-titles__TcWOG {
  padding-left: 3vw;
  padding-top: 4.2vh;
  display: flex;
  gap: 3vw;
}
.ImageModal_modal-wrapper-header-titles-title__1eojT {
  color: #C6C4C4;
}
.ImageModal_modal-wrapper-header-titles-active__JgSmt {
  font-weight: bold;
  color: #343E92;
  border-bottom: 2px solid #FDB242;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Images/ImageModal.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,qCAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,kCAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,4CAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;AACJ;AACI;EACE,aAAA;EACA,WAAA;EACA,WAAA;EACA,kCAAA;AACN;AACM;EACE,eAAA;AACR;AACM;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,QAAA;AACR;AAAQ;EACE,cAAA;AAEV;AAAQ;EACE,iBAAA;EACA,cAAA;EACA,gCAAA;AAEV","sourcesContent":[".modal{\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0,0,0,0.75);\n  position: fixed;\n  left: 0;\n  top: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 2;\n  font-family: \"Poppins\", sans-serif;\n\n  &-wrapper{\n    width: 90vw;\n    height: 90vh;\n    border-radius: 12px;\n    background-color: white;\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n    display: flex;\n    flex-direction: column;\n    z-index: 3;\n\n    &-header {\n      display: flex;\n      width: 100%;\n      height: 7vh;\n      border-bottom: .5px solid #C3BFBF;\n\n      div{\n        cursor: pointer;\n      }\n      &-titles{\n        padding-left: 3vw;\n        padding-top: 4.2vh;\n        display: flex;\n        gap: 3vw;\n        &-title{\n          color: #C6C4C4;\n        }\n        &-active{\n          font-weight: bold;\n          color:#343E92;\n          border-bottom: 2px solid #FDB242;\n        }\n      }\n\n    }\n\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `ImageModal_modal__Orts5`,
	"modal-wrapper": `ImageModal_modal-wrapper__LEBwl`,
	"modal-wrapper-header": `ImageModal_modal-wrapper-header__JOz9p`,
	"modal-wrapper-header-titles": `ImageModal_modal-wrapper-header-titles__TcWOG`,
	"modal-wrapper-header-titles-title": `ImageModal_modal-wrapper-header-titles-title__1eojT`,
	"modal-wrapper-header-titles-active": `ImageModal_modal-wrapper-header-titles-active__JgSmt`
};
export default ___CSS_LOADER_EXPORT___;
