// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthHeader_nav-container__tisyJ {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 320px;
  z-index: 999;
}
.AuthHeader_nav-container--transparent__8LYLT {
  padding: 15px 25px;
  background-color: transparent;
}
.AuthHeader_nav-container--center__jdFRA {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/AuthHeader/AuthHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;EACA,6BAAA;AACJ;AAGE;EACE,uBAAA;AADJ","sourcesContent":[".nav-container {\n  display: flex;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  min-width: 320px;\n  z-index: 999;\n\n  &--transparent {\n    padding: 15px 25px;\n    background-color: transparent;\n\n  }\n\n  &--center {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-container": `AuthHeader_nav-container__tisyJ`,
	"nav-container--transparent": `AuthHeader_nav-container--transparent__8LYLT`,
	"nav-container--center": `AuthHeader_nav-container--center__jdFRA`
};
export default ___CSS_LOADER_EXPORT___;
