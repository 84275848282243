import style from "./NewLocation.module.scss"
import InputText from "../IU/Input/InputText";
import Loader from "../IU/Loader/Loader";
import {useState} from "react";
import Button from "../IU/Button/Button";
import {createCountry_createCountry} from "../../graphql/mutations/__generated__/createCountry";


interface ILocationForm {
    handleSubmit: any,
    formErrors: any,
    country?: createCountry_createCountry,
    deleteLocationHandler?: any
}
const CountryForm = ({ handleSubmit, formErrors, country, deleteLocationHandler}: ILocationForm) => {
    const [isLoading, setIsLoading] = useState(false);


    return(

        <form onSubmit={(e)=>{handleSubmit(e)}}>
            <div className={style["container"]}>

                <div className={style["body"]}>
                    <InputText name={"country"} label={"Pays"} isCorrect={!formErrors.hasOwnProperty('name')} defaultValue={country?.name}/>
                    <div className={style["group"]}>
                        <InputText name={"countryCode"} label={"Code Pays"} isCorrect={!formErrors.hasOwnProperty('countryCode')} defaultValue={country?.countryCode}/>
                        <InputText name={"phoneCode"} label={"Indicatif Telephonique du pays"} isCorrect={!formErrors.hasOwnProperty('phoneCode')} defaultValue={country?.phoneCode}/>
                    </div>

                    <InputText name={"lat"} label={"Latitude"} isCorrect={!formErrors.hasOwnProperty('latitude')} defaultValue={country?.latitude}/>
                    <InputText name={"lon"} label={"Longitude"} isCorrect={!formErrors.hasOwnProperty('longitude')} defaultValue={country?.longitude}/>
                    <InputText name={"emoji"} label={"Emoji"} isCorrect={!formErrors.hasOwnProperty('emoji')} defaultValue={country?.emoji}/>

                </div>
                <div className={style['buttons']}>
                    { country?.id &&
                        <Button
                            text={"Supprimer"}
                            color={"#FFF"}
                            backgroundColor={"#DD4646"}
                            width={140}
                            height={50}
                            onClick={() => {
                                deleteLocationHandler(country?.id)
                            }}
                            borderRadius={10}
                        />}
                    <button type={"submit"} className={`${style['btn']} ${(isLoading) && style['btn__disabled']}`} disabled={isLoading}>
                        Soumettre
                        {isLoading &&
                            <div className={style['btn-loader']}>
                                <Loader height={30}/>
                            </div>
                        }
                    </button>
                </div>
            </div>
        </form>
    )
}

export default CountryForm

