// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BestTripTable_container__ZMbze table {
  font-weight: bold;
  border-spacing: 0rem 0.2rem;
  font-size: 0.6rem;
  width: 100%;
}
.BestTripTable_container-header__-sBc8 {
  color: #646464;
  font-size: 0.5rem;
}
.BestTripTable_container-header-first__64hzZ {
  width: 6%;
}
.BestTripTable_container-header-second__HcqxY {
  width: 30%;
}
.BestTripTable_container-header-third__c3\\+GL {
  width: 16%;
}
.BestTripTable_container-header-fourth__oIyFs {
  width: 26%;
}
.BestTripTable_container-header-fifth__F0udz {
  width: 20%;
}
.BestTripTable_container-body-row__-rtvJ {
  text-align: center;
  box-shadow: 0 9px 41px rgba(90, 82, 82, 0.14);
  border-radius: 10px;
  line-height: 2.5rem;
}
.BestTripTable_container-body-row-rank__jDjda {
  border-radius: 100px;
  width: 1.3vw;
  height: 2vh;
  margin-left: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BestTripTable_container-body-row-rank-r1__c2gIm {
  background: #FFEDC7;
  color: #FF9900;
}
.BestTripTable_container-body-row-rank-r2__lWubp {
  background: #F3EBFF;
  color: #8338F8;
}
.BestTripTable_container-body-row-rank-r3__yvYKF {
  background: #ECF4FF;
  color: #1562D6;
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/BestTripTable.module.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,2BAAA;EACA,iBAAA;EACA,WAAA;AAAJ;AAEE;EACE,cAAA;EACA,iBAAA;AAAJ;AACI;EACE,SAAA;AACN;AACI;EACE,UAAA;AACN;AACI;EACE,UAAA;AACN;AACI;EACE,UAAA;AACN;AACI;EACE,UAAA;AACN;AAGI;EACE,kBAAA;EACA,6CAAA;EACA,mBAAA;EACA,mBAAA;AADN;AAGM;EACE,oBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADR;AAGQ;EACE,mBAAA;EACA,cAAA;AADV;AAIQ;EACE,mBAAA;EACA,cAAA;AAFV;AAKQ;EACE,mBAAA;EACA,cAAA;AAHV","sourcesContent":[".container{\n  table{\n    font-weight: bold;\n    border-spacing: 0rem .2rem;\n    font-size: 0.6rem;\n    width: 100%;\n  }\n  &-header{\n    color: #646464;\n    font-size: 0.5rem;\n    &-first{\n      width: 6%;\n    }\n    &-second{\n      width: 30%;\n    }\n    &-third{\n      width: 16%;\n    }\n    &-fourth{\n      width: 26%;\n    }\n    &-fifth{\n      width: 20%;\n    }\n  }\n  &-body{\n    &-row{\n      text-align: center;\n      box-shadow: 0 9px 41px rgba(90, 82, 82, 0.14);\n      border-radius: 10px;\n      line-height: 2.5rem;\n\n      &-rank{\n        border-radius: 100px;\n        width: 1.3vw;\n        height: 2vh;\n        margin-left: 0.8vw;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n\n        &-r1{\n          background: #FFEDC7;\n          color: #FF9900;\n        }\n\n        &-r2{\n          background: #F3EBFF;\n          color: #8338F8;\n        }\n\n        &-r3{\n          background: #ECF4FF;\n          color: #1562D6;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BestTripTable_container__ZMbze`,
	"container-header": `BestTripTable_container-header__-sBc8`,
	"container-header-first": `BestTripTable_container-header-first__64hzZ`,
	"container-header-second": `BestTripTable_container-header-second__HcqxY`,
	"container-header-third": `BestTripTable_container-header-third__c3+GL`,
	"container-header-fourth": `BestTripTable_container-header-fourth__oIyFs`,
	"container-header-fifth": `BestTripTable_container-header-fifth__F0udz`,
	"container-body-row": `BestTripTable_container-body-row__-rtvJ`,
	"container-body-row-rank": `BestTripTable_container-body-row-rank__jDjda`,
	"container-body-row-rank-r1": `BestTripTable_container-body-row-rank-r1__c2gIm`,
	"container-body-row-rank-r2": `BestTripTable_container-body-row-rank-r2__lWubp`,
	"container-body-row-rank-r3": `BestTripTable_container-body-row-rank-r3__yvYKF`
};
export default ___CSS_LOADER_EXPORT___;
