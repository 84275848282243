import style from './AddContext.module.scss'
import {useMutation} from "@apollo/client";
import {CREATE_TRIP_CONTEXT, UPDATE_TRIP_CONTEXT} from "../../graphql/mutations/trip";
import { useEffect, useState} from "react";
import FormInput from "../IU/FormInput/FormInput";
import Button from "../IU/Button/Button";
import InputError from "../IU/InputError/InputError";
import {CreateTripContextInput} from "../../../__generated__/globalTypes";
import ImageModal from "../Modal/Images/ImageModal";

import {GET_TRIP_CONTEXTS} from "../../graphql/queries/trip";
import {createTripContext, createTripContextVariables} from "../../graphql/mutations/__generated__/createTripContext";
import {updateTripContext, updateTripContextVariables} from "../../graphql/mutations/__generated__/updateTripContext";
import {LIMIT_PAGINATION} from "../IU/Pagination/Pagination";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

type errors = {
    [key: string]: boolean;
};

const FORM_NAME = "name";
const FORM_NAME_LABEL = "Nom de la catégorie";
const FORM_NAME_PLACEHOLDER = "Entrer le nom de la catégorie";
const FORM_MEDIA = "mediaTripContext";

const AddContext = ({context, setContext, setTripContexts, page}: any) => {
    const [createTripContext, ] = useMutation<createTripContext, createTripContextVariables>(CREATE_TRIP_CONTEXT)
    const [updateTripCategory, ] = useMutation<updateTripContext, updateTripContextVariables>(UPDATE_TRIP_CONTEXT)
    const [openImageModal, setOpenImageModal] = useState(false);
    const [errors, setErrors] = useState<errors>({});
    const [media, setMedia] = useState<any[]>(context ? [context.media] : []);
    const MySwal = withReactContent(Swal)
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const resetForm = () => {
        setContext()
        setMedia([])
    }

    useEffect(()=>{
        if (context){
        }else{
            resetForm()
        }

    },[context])



    const formHandler = async (e: any) => {
        e.preventDefault();
        const formData: CreateTripContextInput= {
            name: e.target[FORM_NAME].value,
            mediaId: media[0]?.id ? media[0].id : null,
        }
        if (!context){
            if (media ){

                createTripContext({variables: {context: formData}, refetchQueries: [{
                        query: GET_TRIP_CONTEXTS, variables:{page: page, limit: LIMIT_PAGINATION}}]
                })
                    .then(()=> {
                        Toast.fire({
                            icon: 'success',
                            title: 'Contexte crée'
                        })
                        resetForm()
                        e.target[FORM_NAME].value = ''
                    })
                    .catch(e=>
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong: \n '+ e.message,
                        }))
            }
        }else {


            if (context.name !== formData.name || context?.media?.id !== formData?.mediaId ){
                updateTripCategory({variables: {id: context.id,context: formData}, refetchQueries: [{
                        query: GET_TRIP_CONTEXTS, variables:{page: page, limit: LIMIT_PAGINATION}}]
                })
                    .then(()=> {
                        Toast.fire({
                            icon: 'success',
                            title: 'Contexte mis à jour'
                        })
                        resetForm()
                        e.target[FORM_NAME].value = ''
                        setTripContexts([])
                    })
                    .catch(e=>
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong: \n '+ e.message,
                        }))
            }else{
            }
        }
    }



    return(
        <div className={style['wrapper']}>
            <form className={style['wrapper-form']} onSubmit={formHandler}>
                {openImageModal && <ImageModal setOpenModal={setOpenImageModal} images={media} setImages={setMedia} isMultiple={false} fileType={'tripContext'}/>}

                <FormInput id={FORM_NAME} labelName={FORM_NAME_LABEL} placeholder={FORM_NAME_PLACEHOLDER} defaultValue={context ? context.name :  ''}/>

                <div className={style['wrapper-form-group']}>
                    <div className={style['wrapper-form-field']}>
                        <label htmlFor="images">Icône</label>
                        <Button
                            text={"Télécharger depuis l'ordinateur"}
                            color={"#2E3FD8"}
                            width={250}
                            height={50}
                            borderRadius={10}
                            backgroundColor={"rgba(224, 230, 251, 0.33)"}
                            prefixIcon={{
                                url: "/svg/image-icon.svg",
                                alt: "icône image",
                                style: {},
                            }}
                            onClick={() => {
                                setOpenImageModal(true)
                            }}
                            borderColor={"#5663DA"}/>
                        {errors.hasOwnProperty(FORM_MEDIA) && (
                            <InputError message="Selectionné ou importé une icone pour le context" />
                        )}
                    </div>
                </div>
                <div className={style['wrapper-form-btn']} >
                    <Button
                        text={"Soumettre"}
                        type={"submit"}
                        color={"#FFF"}
                        backgroundColor={"#2D2C6C"}
                        width={140}
                        height={50}
                        borderRadius={10}
                    />
                </div>

            </form>
        </div>
    )
}

export default AddContext