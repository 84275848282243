// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputError_input-error__wRqLr {
  display: flex;
  align-items: center;
  column-gap: 5px;
  background-color: #ffeeee;
  width: 100%;
  max-width: 417px;
  padding: 0 10px;
  margin-top: 5px;
}
.InputError_input-error__text__rOL81 {
  color: #d40404;
}
.InputError_input-error__wRqLr .InputError_warning-icon__q0C44 {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/InputError/InputError.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AACF;AACE;EAEE,cAAA;AAAJ;AAGE;EACE,aAAA;AADJ","sourcesContent":[".input-error {\n  display: flex;\n  align-items: center;\n  column-gap: 5px;\n  background-color: #ffeeee;\n  width: 100%;\n  max-width: 417px;\n  padding: 0 10px;\n  margin-top: 5px;\n\n  &__text {\n    //@include font(#d40404, 20, 1.3rem, 500, 0, #{\"Mulish\", sans-serif});\n    color: #d40404;\n  }\n\n  .warning-icon {\n    display: flex;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-error": `InputError_input-error__wRqLr`,
	"input-error__text": `InputError_input-error__text__rOL81`,
	"warning-icon": `InputError_warning-icon__q0C44`
};
export default ___CSS_LOADER_EXPORT___;
