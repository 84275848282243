// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_arrow__hTZpO {
  min-width: 10px;
  height: 8px;
  position: absolute;
  right: 2%;
  top: 3.2%;
}

.Navbar_header__Jtw9p {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px outset;
  border-color: rgba(255, 255, 255, 0.3);
  padding: 1rem 0rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.Navbar_header-user__5A3oa {
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
}
.Navbar_header-user-background__sw39P {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.Navbar_header-username__8D-Xu {
  font-weight: bold;
}

.Navbar_dropdown__s\\+zF7 {
  position: absolute;
  background: white;
  border-radius: 10px;
  box-shadow: 2px 1px 15px 3px rgba(0, 0, 0, 0.1);
  padding: 5px;
  display: flex;
  flex-direction: column;
  transform: translateY(70%);
  z-index: 3;
}
.Navbar_dropdown-option__zMesg {
  display: flex;
  cursor: pointer;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
  font-weight: 300;
}
.Navbar_dropdown-option__zMesg:hover {
  background: #f5f5f5;
  font-weight: 500;
}

.Navbar_icon__5D6Kl {
  min-width: 25px;
  margin-left: 1vw;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,yBAAA;EACA,sCAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAAE;EACE,eAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AAEJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAEN;AACE;EACE,iBAAA;AACJ;;AAGA;EACE,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,+CAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,0BAAA;EACA,UAAA;AAAF;AAEE;EACE,aAAA;EACA,eAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,gBAAA;AADJ;;AAKA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAFF","sourcesContent":[".arrow {\n  min-width: 10px;\n  height: 8px;\n  position: absolute;\n  right: 2%;\n  top: 3.2%;\n}\n\n.header {\n  display: flex;\n  justify-content: flex-end;\n  border-bottom: 1px outset;\n  border-color: rgba(255, 255, 255, 0.3);\n  padding: 1rem 0rem;\n  margin-bottom: 1rem;\n  margin-right: 1rem;\n  &-user {\n    cursor: pointer;\n    display: flex;\n    gap: 5px;\n    align-items: center;\n\n    &-background {\n      width: 40px;\n      height: 40px;\n      border-radius: 50px;\n    }\n  }\n  &-username {\n    font-weight: bold;\n  }\n}\n\n.dropdown {\n  position: absolute;\n  background: white;\n  border-radius: 10px;\n  box-shadow: 2px 1px 15px 3px rgb(0 0 0 / 10%);\n  padding: 5px;\n  display: flex;\n  flex-direction: column;\n  transform: translateY(70%);\n  z-index: 3;\n\n  &-option {\n    display: flex;\n    cursor: pointer;\n    padding: 10px;\n    gap: 10px;\n    border-radius: 10px;\n    font-weight: 300;\n  }\n\n  &-option:hover {\n    background: #f5f5f5;\n    font-weight: 500;\n  }\n}\n\n.icon {\n  min-width: 25px;\n  margin-left: 1vw;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": `Navbar_arrow__hTZpO`,
	"header": `Navbar_header__Jtw9p`,
	"header-user": `Navbar_header-user__5A3oa`,
	"header-user-background": `Navbar_header-user-background__sw39P`,
	"header-username": `Navbar_header-username__8D-Xu`,
	"dropdown": `Navbar_dropdown__s+zF7`,
	"dropdown-option": `Navbar_dropdown-option__zMesg`,
	"icon": `Navbar_icon__5D6Kl`
};
export default ___CSS_LOADER_EXPORT___;
