import {Navigate, useNavigate} from "react-router-dom";
import {getAuthToken, getTokenDuration} from "../util/auth";
import {useEffect} from "react";
import {authAction} from "../store/auth-slice";
import {useDispatch} from "react-redux";

const ProtectedLayout = ({  children }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = getAuthToken()
    useEffect(() => {
        if (token === 'EXPIRED') {
            dispatch(authAction.logout())
            navigate('/login')
        }
        const tokenDuration = getTokenDuration();
        setTimeout(()=> {
            dispatch(authAction.logout())
            navigate('/login')
        }, tokenDuration)
    }, [token,navigate]);
    if (!token) {
        return <Navigate replace={true} to='/login' />
    }
    return children;
};

export default ProtectedLayout