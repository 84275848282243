// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListCategories_container__jIGal {
  width: 100%;
}
.ListCategories_container-table__jprfs {
  width: 100%;
  font-size: 0.8em;
  border-spacing: 0 1rem;
}
.ListCategories_container-table-options__1ngMu {
  width: 7%;
}
.ListCategories_container__jIGal thead {
  background: #F5F5F5;
  color: #858484;
}
.ListCategories_container__jIGal thead tr th {
  height: 7vh;
  padding: 0 0.7rem;
}
.ListCategories_container__jIGal tbody tr {
  height: 7vh;
  box-shadow: 2px 1px 15px 3px rgba(0, 0, 0, 0.1);
}
.ListCategories_container__jIGal tbody tr td {
  padding: 1rem 0;
  text-align: center;
}

.ListCategories_icon__PuqU9 {
  max-height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/Category/ListCategories.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAI;EACE,WAAA;EACA,gBAAA;EACA,sBAAA;AAEN;AAAM;EACE,SAAA;AAER;AACE;EACE,mBAAA;EACA,cAAA;AACJ;AACM;EACE,WAAA;EACA,iBAAA;AACR;AAII;EACE,WAAA;EACA,+CAAA;AAFN;AAGM;EACE,eAAA;EACA,kBAAA;AADR;;AAOA;EACE,gBAAA;AAJF","sourcesContent":[".container{\n  width: 100%;\n    &-table {\n      width: 100%;\n      font-size: 0.8em;\n      border-spacing: 0 1rem;\n\n      &-options{\n        width: 7%;\n      }\n    }\n  thead{\n    background: #F5F5F5;\n    color: #858484;\n    tr{\n      th{\n        height: 7vh;\n        padding: 0 .7rem;\n      }\n    }\n  }\n  tbody{\n    tr{\n      height: 7vh;\n      box-shadow: 2px 1px 15px 3px rgb(0 0 0 / 10%);\n      td{\n        padding: 1rem 0;\n        text-align: center;\n      }\n    }\n  }\n}\n\n.icon{\n  max-height: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ListCategories_container__jIGal`,
	"container-table": `ListCategories_container-table__jprfs`,
	"container-table-options": `ListCategories_container-table-options__1ngMu`,
	"icon": `ListCategories_icon__PuqU9`
};
export default ___CSS_LOADER_EXPORT___;
