import style from './TextEditor.module.scss'
import React from "react";
import TextEditor from "../../IU/TextEditor/TextEditor";
import Modal from "../Modal";



const AddDealText  = ({text, setText, closeModal, type}: any) =>{

    return(
        <Modal setOpenModal={closeModal}>
            <div className={style['wrapper-header']}>
                <div className={style['wrapper-header-title']}>
                    <img src={type.icon.url} alt={type.icon.alt} />
                    {type.title}
                </div>
                <img src='/svg/close.svg' className={style['wrapper-header-close']} alt='close' onClick={()=>{closeModal(false)}}/>
            </div>

            <TextEditor text={text} setText={setText} closeModal={closeModal} />

        </Modal>
    )
}

export default AddDealText