// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputWithIcon_wrapper__w4i7x {
  border: 0.5px solid #888686;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
}
.InputWithIcon_wrapper__w4i7x .InputWithIcon_suffix-icon__5QsdY {
  display: flex;
  border-left: 0.5px solid #888686;
}
.InputWithIcon_wrapper__w4i7x .InputWithIcon_prefix-icon__VfmxD {
  display: flex;
  border-right: 0.5px solid #888686;
}
.InputWithIcon_wrapper__input__buUK3 {
  outline: none;
  border: none;
  -webkit-appearance: none;
  caret-color: transparent;
  cursor: pointer;
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
.InputWithIcon_wrapper__w4i7x img {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/InputWithIcon/InputWithIcon.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,gCAAA;AACJ;AAEE;EACE,aAAA;EACA,iCAAA;AAAJ;AAGE;EACE,aAAA;EACA,YAAA;EACA,wBAAA;EACA,wBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;AADJ;AAGE;EACE,aAAA;AADJ","sourcesContent":[".wrapper{\n  border: 0.5px solid #888686;\n  border-radius: 8px;\n  box-sizing: border-box;\n  display: flex;\n\n  .suffix-icon {\n    display: flex;\n    border-left: 0.5px solid #888686;\n  }\n\n  .prefix-icon {\n    display: flex;\n    border-right: 0.5px solid #888686;\n  }\n\n  &__input{\n    outline: none;\n    border: none;\n    -webkit-appearance: none;\n    caret-color: transparent;\n    cursor: pointer;\n    width: 100%;\n    padding: 12px 20px;\n    box-sizing: border-box;\n    border-radius: 10px;\n  }\n  img{\n    padding: 10px;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `InputWithIcon_wrapper__w4i7x`,
	"suffix-icon": `InputWithIcon_suffix-icon__5QsdY`,
	"prefix-icon": `InputWithIcon_prefix-icon__VfmxD`,
	"wrapper__input": `InputWithIcon_wrapper__input__buUK3`
};
export default ___CSS_LOADER_EXPORT___;
