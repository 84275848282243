import style from './Categories.module.scss'
import AddCategory from "./AddCategory";
import ListCategories from "./ListCategories";
import React, {useEffect, useState} from "react";
import {
    getTripCategories,
    getTripCategories_tripCategories, getTripCategoriesVariables
} from "../../graphql/queries/__generated__/getTripCategories";
import {useLazyQuery} from "@apollo/client";
import {GET_TRIP_CATEGORIES} from "../../graphql/queries/trip";
import Loader from "../IU/Loader/Loader";
import {LIMIT_PAGINATION} from "../IU/Pagination/Pagination";

const Categories = () => {

    const [currentCategory, setCurrentCategory] = useState<getTripCategories_tripCategories>()
    const [page, setPage] = useState(0)
    const [getTripCategories, {loading, error, data}] = useLazyQuery<getTripCategories, getTripCategoriesVariables>(GET_TRIP_CATEGORIES, { variables: {page: page*LIMIT_PAGINATION, limit: LIMIT_PAGINATION}})
    const [tripCategories, setTripCategories] = useState<getTripCategories_tripCategories[]>()

    useEffect(()=>{
        getTripCategories()

    },[tripCategories])
    if (loading) return <div className={style['container']}><Loader/></div>;
    if (error) {
        return <div className={style['container']}>`Error! ${error.message}`</div>;
    }
    return(
        <div className={style['container']}>
            <AddCategory category={currentCategory} setCategory={setCurrentCategory} setTripCategories={setTripCategories} page={page}/>
            <ListCategories setCurrentCategory={setCurrentCategory} tripCategories={data?.tripCategories} setTripCategories={setTripCategories} page={page} setPage={setPage} />
        </div>
    )
}

export default Categories