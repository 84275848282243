// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BestArticleTable_container__M7GRy table {
  border-spacing: 0rem 0.2rem;
  font-size: 0.6rem;
  width: 100%;
}
.BestArticleTable_container-header__1PJPs {
  color: #646464;
  font-size: 0.5rem;
  background: rgba(236, 236, 236, 0.33);
}
.BestArticleTable_container-header-first__uf7H4 {
  width: 5%;
}
.BestArticleTable_container-header-second__ZwH7T {
  width: 11%;
}
.BestArticleTable_container-header-third__tEX1- {
  width: 26%;
}
.BestArticleTable_container-header-fourth__7\\+JOY {
  width: 13%;
}
.BestArticleTable_container-header-fifth__vkS5X {
  width: 7%;
}
.BestArticleTable_container-header-sixth__b6wYu {
  width: 10%;
}
.BestArticleTable_container-body-row__dccU3 {
  text-align: center;
  line-height: 2.5rem;
}
.BestArticleTable_container-body-row-createdAt__uhrP4 {
  color: #9D9C9C;
}
.BestArticleTable_container-body-row-id__FEtdc, .BestArticleTable_container-body-row-title__H3gFy, .BestArticleTable_container-body-row-views__eldCD, .BestArticleTable_container-body-row-comments__NXanS {
  font-weight: bold;
}
.BestArticleTable_container-body-row-rank__-vkfY {
  border-radius: 100px;
  width: 1.3vw;
  height: 2vh;
  margin-left: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BestArticleTable_container-body-row-rank-r1__KFj2q {
  background: #FFEDC7;
  color: #FF9900;
}
.BestArticleTable_container-body-row-rank-r2__VFAlG {
  background: #F3EBFF;
  color: #8338F8;
}
.BestArticleTable_container-body-row-rank-r3__rbYXs {
  background: #ECF4FF;
  color: #1562D6;
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/BestArticleTable.module.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;EACA,iBAAA;EACA,WAAA;AAAJ;AAEE;EACE,cAAA;EACA,iBAAA;EACA,qCAAA;AAAJ;AACI;EACE,SAAA;AACN;AACI;EACE,UAAA;AACN;AACI;EACE,UAAA;AACN;AACI;EACE,UAAA;AACN;AACI;EACE,SAAA;AACN;AACI;EACE,UAAA;AACN;AAGI;EACE,kBAAA;EAEA,mBAAA;AAFN;AAGM;EACE,cAAA;AADR;AAGM;EACE,iBAAA;AADR;AAGM;EACE,oBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADR;AAGQ;EACE,mBAAA;EACA,cAAA;AADV;AAIQ;EACE,mBAAA;EACA,cAAA;AAFV;AAKQ;EACE,mBAAA;EACA,cAAA;AAHV","sourcesContent":[".container{\n  table{\n    border-spacing: 0rem .2rem;\n    font-size: 0.6rem;\n    width: 100%;\n  }\n  &-header{\n    color: #646464;\n    font-size: 0.5rem;\n    background: rgba(236, 236, 236, 0.33);\n    &-first{\n      width: 5%;\n    }\n    &-second{\n      width: 11%;\n    }\n    &-third{\n      width: 26%;\n    }\n    &-fourth{\n      width: 13%;\n    }\n    &-fifth{\n      width: 7%;\n    }\n    &-sixth{\n      width: 10%;\n    }\n  }\n  &-body{\n    &-row{\n      text-align: center;\n\n      line-height: 2.5rem;\n      &-createdAt{\n        color: #9D9C9C;\n      }\n      &-id, &-title, &-views, &-comments{\n        font-weight: bold;\n      }\n      &-rank{\n        border-radius: 100px;\n        width: 1.3vw;\n        height: 2vh;\n        margin-left: 0.8vw;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n\n        &-r1{\n          background: #FFEDC7;\n          color: #FF9900;\n        }\n\n        &-r2{\n          background: #F3EBFF;\n          color: #8338F8;\n        }\n\n        &-r3{\n          background: #ECF4FF;\n          color: #1562D6;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BestArticleTable_container__M7GRy`,
	"container-header": `BestArticleTable_container-header__1PJPs`,
	"container-header-first": `BestArticleTable_container-header-first__uf7H4`,
	"container-header-second": `BestArticleTable_container-header-second__ZwH7T`,
	"container-header-third": `BestArticleTable_container-header-third__tEX1-`,
	"container-header-fourth": `BestArticleTable_container-header-fourth__7+JOY`,
	"container-header-fifth": `BestArticleTable_container-header-fifth__vkS5X`,
	"container-header-sixth": `BestArticleTable_container-header-sixth__b6wYu`,
	"container-body-row": `BestArticleTable_container-body-row__dccU3`,
	"container-body-row-createdAt": `BestArticleTable_container-body-row-createdAt__uhrP4`,
	"container-body-row-id": `BestArticleTable_container-body-row-id__FEtdc`,
	"container-body-row-title": `BestArticleTable_container-body-row-title__H3gFy`,
	"container-body-row-views": `BestArticleTable_container-body-row-views__eldCD`,
	"container-body-row-comments": `BestArticleTable_container-body-row-comments__NXanS`,
	"container-body-row-rank": `BestArticleTable_container-body-row-rank__-vkfY`,
	"container-body-row-rank-r1": `BestArticleTable_container-body-row-rank-r1__KFj2q`,
	"container-body-row-rank-r2": `BestArticleTable_container-body-row-rank-r2__VFAlG`,
	"container-body-row-rank-r3": `BestArticleTable_container-body-row-rank-r3__rbYXs`
};
export default ___CSS_LOADER_EXPORT___;
