// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddCategory_wrapper__9X9ox {
  width: 100%;
}
.AddCategory_wrapper-form__BWIoA {
  display: flex;
  flex-direction: column;
  min-height: 545px;
  margin-bottom: 20px;
  gap: 2rem;
}
.AddCategory_wrapper-form-group__yJaFB {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem 0 0;
}
.AddCategory_wrapper-form-preview__a29gb {
  max-width: 100%;
  max-height: 20vh;
}
.AddCategory_wrapper-form-field__L6wZH {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.AddCategory_wrapper-form-btn__bAFnm {
  display: flex;
  justify-content: end;
  padding-right: 1rem;
  margin-top: 30rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Category/AddCategory.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,SAAA;AAEJ;AAAI;EACE,aAAA;EACA,8BAAA;EACA,sBAAA;AAEN;AAGI;EACE,eAAA;EACA,gBAAA;AADN;AAII;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAFN;AAKI;EACE,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,iBAAA;AAHN","sourcesContent":[".wrapper{\n  width: 100%;\n  &-form{\n    display: flex;\n    flex-direction: column;\n    min-height: 545px;\n    margin-bottom: 20px;\n    gap: 2rem;\n\n    &-group{\n      display: flex;\n      justify-content: space-between;\n      padding: 0rem 1rem 0 0;\n\n\n    }\n\n    &-preview{\n      max-width: 100%;\n      max-height: 20vh;\n    }\n\n    &-field{\n      display: flex;\n      flex-direction: column;\n      gap: .5rem;\n    }\n\n    &-btn{\n      display: flex;\n      justify-content: end;\n      padding-right: 1rem;\n      margin-top: 30rem;\n    }\n\n  }\n\n\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AddCategory_wrapper__9X9ox`,
	"wrapper-form": `AddCategory_wrapper-form__BWIoA`,
	"wrapper-form-group": `AddCategory_wrapper-form-group__yJaFB`,
	"wrapper-form-preview": `AddCategory_wrapper-form-preview__a29gb`,
	"wrapper-form-field": `AddCategory_wrapper-form-field__L6wZH`,
	"wrapper-form-btn": `AddCategory_wrapper-form-btn__bAFnm`
};
export default ___CSS_LOADER_EXPORT___;
