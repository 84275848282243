// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationList_wrapper__LaCB6 {
  width: 100%;
  font-size: 0.8em;
  border-spacing: 0 1rem;
}
.LocationList_wrapper__LaCB6 .LocationList_id__iv0Xt {
  width: 15%;
}
.LocationList_wrapper__LaCB6 thead {
  background: #F5F5F5;
  color: #858484;
}
.LocationList_wrapper__LaCB6 thead tr th {
  height: 7vh;
  padding: 0 0.7rem;
}
.LocationList_wrapper__LaCB6 tbody {
  text-align: center;
}
.LocationList_wrapper__LaCB6 tbody tr {
  height: 7vh;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.05);
  padding: 1rem 0rem;
}

.LocationList_input__irPod {
  width: 100%;
  height: 45px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid rgba(136, 134, 134, 0.5);
  border-radius: 10px;
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Localisations/LocationList.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,sBAAA;AACJ;AAAE;EACE,UAAA;AAEJ;AAAI;EACE,mBAAA;EACA,cAAA;AAEN;AAAQ;EACE,WAAA;EACA,iBAAA;AAEV;AAEI;EACE,kBAAA;AAAN;AACM;EACE,WAAA;EACA,gDAAA;EACA,kBAAA;AACR;;AAOA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,0CAAA;EACA,mBAAA;EACA,gBAAA;AAJF","sourcesContent":[".wrapper{\n    width: 100%;\n    font-size: 0.8em;\n    border-spacing:0 1rem;\n  .id{\n    width: 15%;\n  }\n    thead{\n      background: #F5F5F5;\n      color: #858484;\n      tr{\n        th{\n          height: 7vh;\n          padding: 0 .7rem;\n        }\n      }\n    }\n    tbody{\n      text-align: center;\n      tr{\n        height: 7vh;\n        box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.05);\n        padding: 1rem 0rem;\n      }\n    }\n\n  }\n\n\n\n.input{\n  width: 100%;\n  height: 45px;\n  padding: 12px 20px;\n  box-sizing: border-box;\n  border: 1px solid rgba(136, 134, 134, .5);\n  border-radius: 10px;\n  margin-top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LocationList_wrapper__LaCB6`,
	"id": `LocationList_id__iv0Xt`,
	"input": `LocationList_input__irPod`
};
export default ___CSS_LOADER_EXPORT___;
