import {gql} from "@apollo/client";

export const GET_CITIES = gql`
query cities($city: String!, $page: Int!, $limit: Int!){
  cities(city:$city, page: $page, limit: $limit){
    id
    name
    countryName
    stateName
  }
}`

export const GET_CITY = gql`
query getCity($id: String!) {
    city(cityID: $id) {
        id
        name
        countryName
        stateName
        latitude
        longitude  
    }
}`

export const GET_FLIGHT_CITY = gql`
query flightCities($city: String!){
  flightCities(city:$city){
    id
    name
    iataCode
    iataCityCode
    cityName
    countryName
  }
}`

export const GET_ALL_CITIES = gql`
query citiesLocation($page: Int!, $limit: Int!, $sort: SortAndFilter){
    citiesLocation(page: $page, limit: $limit, sort: $sort){
        cities {
            id
            name
            countryName
            stateName
            latitude
            longitude
        }
        totalCount
    }

}`

