import {BNA_AUTH_TOKEN} from "../globals";
import jwt from 'jwt-decode'

export const getTokenDuration = () => {
    const token = localStorage.getItem(BNA_AUTH_TOKEN)
    if (token) {
        const data: any = jwt(token)
        const expiryDate = new Date(data?.exp * 1000)
        const now = new Date()
        return expiryDate.getTime() - now.getTime()
    }
    return 0
}

export const getAuthToken = () => {
    const token = localStorage.getItem(BNA_AUTH_TOKEN);
    const tokenDuration = getTokenDuration();

    if (tokenDuration < 0){
        return 'EXPIRED'
    }
    return token
}



