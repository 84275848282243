// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Coupons_wrapper-header__oShLP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.Coupons_wrapper-header-buttons__h52Rp {
  height: 40px;
  display: flex;
  justify-content: end;
  gap: 1rem;
  width: 35%;
  margin-right: 1rem;
}
.Coupons_wrapper-stats__0UX2n {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.Coupons_wrapper__t8wHn .Coupons_input__jKuqZ {
  width: 100%;
  height: 45px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid rgba(136, 134, 134, 0.5);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Coupons/Coupons/Coupons.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AACI;EACE,YAAA;EACA,aAAA;EACA,oBAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;AACN;AAEE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,0CAAA;EACA,mBAAA;AADJ","sourcesContent":[".wrapper {\n  &-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 1rem;\n    &-buttons {\n      height: 40px;\n      display: flex;\n      justify-content: end;\n      gap: 1rem;\n      width: 35%;\n      margin-right: 1rem;\n    }\n  }\n  &-stats {\n    display: flex;\n    gap: 1rem;\n    margin-bottom: 1rem;\n  }\n\n  .input {\n    width: 100%;\n    height: 45px;\n    padding: 12px 20px;\n    box-sizing: border-box;\n    border: 1px solid rgba(136, 134, 134, 0.5);\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper-header": `Coupons_wrapper-header__oShLP`,
	"wrapper-header-buttons": `Coupons_wrapper-header-buttons__h52Rp`,
	"wrapper-stats": `Coupons_wrapper-stats__0UX2n`,
	"wrapper": `Coupons_wrapper__t8wHn`,
	"input": `Coupons_input__jKuqZ`
};
export default ___CSS_LOADER_EXPORT___;
