// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputText_container__6eaLH {
  width: 100%;
}
.InputText_container__6eaLH input {
  border: 1px solid rgba(136, 134, 134, 0.5);
  margin-top: 0.5rem;
  width: calc(100% - 0.5rem);
  height: 38px;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/Input/InputText.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,0CAAA;EACA,kBAAA;EACA,0BAAA;EACA,YAAA;EACA,kBAAA;AACJ","sourcesContent":[".container {\n  width: 100%;\n\n  input{\n    border: 1px solid rgba(136, 134, 134, 0.5);\n    margin-top: 0.5rem;\n    width: calc(100% - 0.5rem);\n    height: 38px;\n    border-radius: 8px;\n  }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InputText_container__6eaLH`
};
export default ___CSS_LOADER_EXPORT___;
