import style from './Stats.module.scss'

const  Stats = ({title, number, variation}: any) => {

    const isIncrease = variation.charAt(0) === '-'

    return (
        <div className={style['container']}>
            <div className={style['container-title']}>
                {title}
            </div>
            <div className={style['container-body']}>
                <div className={style['container-body-number']}>
                    {number}
                </div>
                <div className={style['container-body-variation']}>
                    <img className={`${isIncrease ? style['container-body-variation-increase'] : style['container-body-variation-decrease']}`} src={'/svg/full-arrow-orange.svg'}/>{variation}
                </div>
            </div>
        </div>
    )
}
export default Stats