import style from "./DatePicker.module.scss";
import {useRef, useEffect, useState} from "react";
import {MapIndexToMonth} from "../../../types/enums/months";

interface ISelectedDate {
    day: number;
    month: number;
    year: number;
    months: string[];
    init: boolean;
    isSetStartDate: boolean
}

let firstDate = new Date();
let secondDate = new Date()
export default function DatePicker({startDate,  endDate, setStartDate, setEndDate, isFuture = false, setShowDate}: any) {

    const calendar: any = {
        date: new Date(), // objet Date
        calendarElement: useRef(), // reference pour target l'élément calendar
        days: ["Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam.", "Dim."], // jours de la semaine
        dayStart: 6, // index à partir duquel commencer à écrire les dates du calendrier
        weekDaysConversion: {0: 6, 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5}, // conversion des index des jours de la semaine pour placer correctement le premier jour de chaque mois dans la grille
        getNbDaysInMonth: (year: any,
                           month: any // permet de récup le nb de jours dans 1 mois
        ) => new Date(year, month, 0).getDate(),
        getFirstDayOfMonth: (
            year: any,
            month: any // permet de savoir à quel jour de la semaine est associé le premier du mois
        ) => new Date(`${month}, 1, ${year}`).getDay(),
    };
    const currMonth = new Date(calendar.date.getFullYear(), calendar.date.getMonth() + 1, 0).getDate() === calendar.date.getDate() ? calendar.date.getMonth() : calendar.date.getMonth() + 1;

    const [selectedDate, setSelectedDate] = useState<ISelectedDate>({
        day: 0,
        month: currMonth,
        year: calendar.date.getFullYear(),
        months: [],
        init: true,
        isSetStartDate: true,
    });

    useEffect(() => {

         renderCalendar();
        setDatesFromProps();
        if (!selectedDate.init) {
            updateDate();
        }
    }, [ selectedDate]);


    const setDatesFromProps = () => {
        firstDate = startDate;
        secondDate = endDate;
    }
    setDatesFromProps();
    const updateDate = () => {

        const theDate = new Date(  `${selectedDate.month}/${selectedDate.day}/${selectedDate.year}`)
        // 0 indique que la date est reset..
        if (selectedDate.day !== 0) {
            if (!selectedDate.isSetStartDate ) {
                setStartDate(theDate)
                renderCalendar()
                return;
            }else{

                if (theDate.getTime() < startDate.getTime()){
                    setEndDate(startDate)
                    setStartDate(theDate)
                    renderCalendar()
                    return;
                }
                setEndDate(theDate)
                setShowDate(false)
                renderCalendar()
                return;
            }
        }

    };


    const renderCalendar = () => {
        let dayCounter: any = 0;
        let dayDate: any = 0; // jour du calendrier
        let firstDay = calendar.getFirstDayOfMonth(
            selectedDate.year,
            selectedDate.month
        );
        let nbOfDaysInMonth = calendar.getNbDaysInMonth(
            selectedDate.year,
            selectedDate.month
        );

        const nbOfCasesToGenerate =
            7 + nbOfDaysInMonth + calendar.weekDaysConversion[firstDay];

        calendar.calendarElement.current.innerHTML = "";
        for (
            let caseCounter = 0;
            caseCounter < nbOfCasesToGenerate;
            caseCounter++
        ) {
            const gridCase = document.createElement("div");

            if (caseCounter >= 0 && caseCounter <= 6) {
                gridCase.setAttribute("class", `${style["week-day"]}`);
                gridCase.textContent = calendar.days[caseCounter];
            }

            if (caseCounter > calendar.dayStart) {
                if (
                    dayCounter >= calendar.weekDaysConversion[firstDay] &&
                    dayDate !== nbOfDaysInMonth
                ) {
                    dayDate++;
                    gridCase.textContent = dayDate;
                    if (
                        dayDate <= calendar.date.getDate() &&
                        selectedDate.month === calendar.date.getMonth() + 1 &&
                        selectedDate.year === calendar.date.getFullYear()
                    ) {
                        gridCase.setAttribute("class", style["day--disabled"]);
                    } else {
                        gridCase.setAttribute("class", style["day"]);
                        gridCase.setAttribute(
                            "data-month",
                            MapIndexToMonth[selectedDate?.month]
                        );
                        gridCase.setAttribute("data-year", `${selectedDate.year}`);

                        if (
                            gridCase.textContent === firstDate.toLocaleDateString('fr-FR',{day:"numeric"}) &&
                            gridCase.dataset.month === MapIndexToMonth[firstDate.getMonth()+1] &&
                            gridCase.dataset.year === firstDate.getFullYear().toString()
                        ) {
                            gridCase.classList.add(style["day--active"]);
                        }
                        if (selectedDate.isSetStartDate ){
                                     if (selectedDate.month === firstDate.getMonth()+1 && selectedDate.month === secondDate.getMonth()+1){
                                        if (dayDate > parseInt(startDate.toLocaleDateString('fr-FR',{day:"numeric"})) && dayDate < parseInt(endDate.toLocaleDateString('fr-FR',{day:"numeric"}))){
                                            gridCase.classList.add(style["day--between"])
                                        }
                                        if (dayDate === parseInt(startDate.toLocaleDateString('fr-FR',{day:"numeric"})) || dayDate === parseInt(endDate.toLocaleDateString('fr-FR',{day:"numeric"}))){
                                            gridCase.classList.add(style["day--active"])
                                        }
                                     }
                                    if (
                                        selectedDate.month > firstDate.getMonth()+1
                                    ){
                                        if (selectedDate.month <= secondDate.getMonth()+1){
                                            if (dayDate === parseInt(secondDate.toLocaleDateString('fr-FR',{day:"numeric"})) && selectedDate.month === secondDate.getMonth()+1){
                                                gridCase.classList.add(style["day--active"])
                                            } else if ((selectedDate.month === secondDate.getMonth()+1 && dayDate < parseInt(secondDate.toLocaleDateString('fr-FR',{day:"numeric"}))) || selectedDate.month < secondDate.getMonth()+1){
                                                gridCase.classList.add(style["day--between"])
                                            }
                                        }
                                    }
                                    if (
                                        selectedDate.month < secondDate.getMonth()+1
                                    ) {
                                        if (selectedDate.month >= firstDate.getMonth()+1){
                                            if (dayDate === parseInt(firstDate.toLocaleDateString('fr-FR',{day:"numeric"})) && selectedDate.month === firstDate.getMonth()+1){
                                                gridCase.classList.add(style["day--active"])
                                            } else if ((selectedDate.month === firstDate.getMonth()+1 && dayDate > parseInt(firstDate.toLocaleDateString('fr-FR',{day:"numeric"}))) || selectedDate.month > firstDate.getMonth()+1){
                                                gridCase.classList.add(style["day--between"])
                                            }
                                        }
                                    }


                        }
                        // else {
                        //     gridCase.classList.remove(style["day--active"])
                        // }
                        gridCase.addEventListener("click", (e: any) => {
                            Array.from(calendar.calendarElement.current.children).forEach(
                                (gridCase: any) =>
                                    gridCase.classList.remove(style["day--active"])
                            );
                            setSelectedDate({
                                ...selectedDate,
                                day: parseInt(e.target.textContent, 10),
                                month: selectedDate.month,
                                year: selectedDate.year,
                                months: [],
                                init:false,
                                isSetStartDate: !selectedDate.isSetStartDate,
                            });
                        });
                    }
                }

                dayCounter++;
            }
            calendar.calendarElement.current.appendChild(gridCase);
        }
    };



    return (
        <div className={style["date-options"]}>

            <div className={style["fixed-date-option"]}>
                <div className={style["calendar"]}>
                    <div className={style["calendar-navigation"]}>
                        <div
                            style={{
                                display:
                                    selectedDate.month === currMonth &&
                                    selectedDate.year === calendar.date.getFullYear() &&
                                    isFuture
                                        ? "none"
                                        : "block",
                            }}
                            onClick={() => {
                                if (isFuture){
                                    if (selectedDate.month === currMonth &&
                                        selectedDate.year === calendar.date.getFullYear()) {
                                        return
                                    }
                                }
                                if (selectedDate.month > 1) {
                                    setSelectedDate({
                                        ...selectedDate,
                                        month: selectedDate.month - 1,
                                        init: true,
                                    })
                                } else {
                                    setSelectedDate({
                                        ...selectedDate,
                                        month: 12,
                                        year: selectedDate.year - 1,
                                        init: true,
                                    })
                                }
                            }}
                            className={style["arrow-icon"]}
                        >
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.96875 1.28125L1.03125 5L4.96875 8.71875" stroke="#2D2C6C"
                                      strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div
                            className={style["month-selection"]}>{`${MapIndexToMonth[selectedDate.month]} ${selectedDate.year}`}</div>
                        <div
                            style={{
                                display:
                                    selectedDate.month === currMonth &&
                                    selectedDate.year === calendar.date.getFullYear() &&
                                        !isFuture
                                        ? "none"
                                        : "block",
                            }}
                            onClick={() => {
                                if (!isFuture){
                                    if (selectedDate.month === currMonth &&
                                        selectedDate.year === calendar.date.getFullYear()) {
                                        return
                                    }
                                }

                                if (selectedDate.month <= 11) {
                                    setSelectedDate({
                                        ...selectedDate,
                                        month: selectedDate.month + 1,
                                        init: true,
                                    })
                                } else {
                                    setSelectedDate({
                                        ...selectedDate,
                                        month: 1,
                                        year: selectedDate.year + 1,
                                        init: true,
                                    })
                                }
                            }}
                            className={style["arrow-icon"]}
                        >
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.03125 1.28125L4.96875 5L1.03125 8.71875" stroke="#2D2C6C"
                                      strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                        </div>
                    </div>
                    <div ref={calendar.calendarElement} className={style.days}></div>
                </div>
            </div>

        </div>
    );
}
