import {PropsWithChildren, useState} from "react";
import {Doughnut, Pie} from "react-chartjs-2";
import {
    Chart as ChartJS,
    DoughnutController,
    Title,
    Tooltip,
    Legend, ArcElement, LegendItem, ChartOptions,
} from 'chart.js';
import style from './DoughnutChart.module.scss';
import {tripData} from "../../../data/TripsData";


const DoughnutChart = (props: PropsWithChildren) => {
    const details = tripData.details.map((trip: { value: number,label: string; })=>{return trip.value})
    const labels = tripData.details.map((trip:{ value: number, label: string; })=>{return `${trip.value}% ${trip.label}`})
    const [data, setData] = useState({
        datasets: [{
            data: details,
            backgroundColor: [
                'rgb(126, 159, 106)',
                'rgb(179, 213, 219)',
                'rgb(255, 177, 61)',
                'rgb(178, 126, 183)',
            ],
            cutout:'70%'
        }],
        labels,
    })


    ChartJS.register(
        ArcElement,
        DoughnutController,
        Title,
        Tooltip,
        Legend
    );

    const plugins: any = [{
        beforeDraw: function(chart: { width: any; height: any; ctx: any; }) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 100).toFixed(2);


            ctx.font = "bold " + fontSize + "rem sans-serif ";
            var text = tripData.total.toString(),
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 3.2;
            ctx.fillText(text, textX, textY);
            ctx.textBaseline = "top";
            fontSize = (height / 200).toFixed(2);
            var text2= "au total",
                text2Y = height / 2.2;
            ctx.font = fontSize + "rem sans-serif ";
            ctx.fillText(text2, textX, text2Y);
            ctx.save();
        }
    }]

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                title:{
                },
                position: "bottom",
                align:"center",
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    boxWidth:10,
                    padding:20,
                    font:{
                        family: 'Poppins',
                        size:8
                    }

                }
            },
            title:{
                display: false
            },
        },
    }


    return (
        <div className={style.doughnut}>
            <Doughnut options={options} data={data} plugins={plugins} width={"60%"} height={100}/>
        </div>
    );
}

export default DoughnutChart;