import ListOptions from "src/components/ListOptions";
import style from "./ListUserGroups.module.scss";
import Button from "src/components/IU/Button/Button";
import {useEffect, useRef, useState} from "react";
import GroupDetails from "./GroupDetails";

export interface IListUserGroupsProps {
	showCreatedGroups?: boolean;
}

const ListUserGroups = ({showCreatedGroups}: IListUserGroupsProps) => {
	const options: any = useRef();
	const [groups, setGroups] = useState<any>([]);
	const queryParameters = new URLSearchParams(window.location.search);
	const p = queryParameters.get("p");
	const [page, setPage] = useState(p ? parseInt(p) - 1 : 0);

	useEffect(() => {
		if (p === null) {
			setPage(0);
		}
	}, [p]);

	useEffect(() => {
		if (showCreatedGroups) {
			setGroups([
				{
					id: "1",
					tripDetails: {
						city: "TOKYO",
						country: "JAPAN",
						tripType: "Vols A/R",
						startDate: "12 Mai",
						endDate: "22 Mai 2024",
						price: "1000",
						thumbnail: "/img/group-thumbnail.jpg",
					},
					groupName: "Carnaval Trip Colorado",
					members: "4",
					author: "Sarah Dasha",
					status: true,
					date: "05/05/2024",
				},
				{
					id: "1",
					tripDetails: {
						city: "TOKYO",
						country: "JAPAN",
						tripType: "Vols A/R",
						startDate: "12 Mai",
						endDate: "22 Mai 2024",
						price: "1000",
						thumbnail: "/img/group-thumbnail.jpg",
					},
					groupName: "Carnaval Trip Colorado",
					members: "4",
					author: "Sarah Dasha",
					status: false,
					date: "05/05/2024",
				},
			]);
		} else {
			setGroups([
				{
					id: "1",
					tripDetails: {
						city: "TOKYO",
						country: "JAPAN",
						tripType: "Vols A/R",
						startDate: "12 Mai",
						endDate: "22 Mai 2024",
						price: "1000",
						thumbnail: "/img/group-thumbnail.jpg",
					},
					groupName: "Carnaval Trip Colorado",
					members: "4",
					author: "Sarah Dasha",
					status: true,
					date: "05/05/2024",
				},
			]);
		}
	}, [showCreatedGroups]);

	const formatCreatedAtDate = (createdAt: number): string => {
		const options: Intl.DateTimeFormatOptions = {
			day: "2-digit",
			month: "short",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
		};
		const formattedDate: string = new Date(createdAt * 1000).toLocaleDateString("fr-FR", options);
		return formattedDate;
	};

	const onOpenOptions = () => {};

	return (
		<div className={style["container"]}>
			<table className={style["container-table"]} cellSpacing="0">
				<thead>
					<tr className={style["container-table-header"]}>
						<th className={style[""]}>Destination</th>
						<th className={style[""]}>Nom de groupe</th>
						<th className={style[""]}>Nbre de pers.</th>
						<th className={style[""]}>Créateur</th>
						<th className={style[""]}>Statut</th>
						<th className={style[""]}>Date</th>
						<th className={style[""]}></th>
					</tr>
				</thead>
				<tbody>
					{groups?.map((group, index) => (
						<GroupDetails group={group} key={index} />
					))}
				</tbody>
				{/* <tfoot>
            <tr>
                <td colSpan={15}>
                
                    <Pagination page={page} setPage={setPage} isEmpty={false} totalOfItems={2} />
                </td>
            </tr>
        </tfoot> */}
			</table>
		</div>
	);
};

export default ListUserGroups;
