// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_logo-container__f1pxg a {
  display: block;
}
.Logo_logo-container__f1pxg img {
  width: 60px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Logos/BrokeAndAbroadLogo/Logo.module.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AAGI;EACI,WAAA;EACA,YAAA;AADR","sourcesContent":[".logo-container {\n    a {\n        display: block;\n    }\n\n    img {\n        width: 60px;\n        height: auto;\n\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-container": `Logo_logo-container__f1pxg`
};
export default ___CSS_LOADER_EXPORT___;
