// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Stats_container__NadyJ {
  display: flex;
  flex-direction: column;
  border: 1px solid #D8D8D8;
  border-radius: 12px;
  background: #FFFFFF;
  color: #2D2C6C;
  padding: 1.5rem 2rem 2rem 2rem;
  width: 100%;
  height: 8vh;
  gap: 5px;
}
.Stats_container-title__A\\+QuE {
  font-weight: 500;
}
.Stats_container-body__CRQjt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Stats_container-body-number__O0nA5 {
  font-size: 3rem;
  font-weight: bold;
}
.Stats_container-body-variation__TLjnV {
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #EDFCF5;
  border-radius: 8.57143px;
  color: #149761;
}
.Stats_container-body-variation__TLjnV img {
  width: 10px;
  height: 15px;
  fill: #149761;
}

.Stats_container__NadyJ:hover {
  background: #2D2C6C;
  color: #FFFFFF;
  border: 1px solid #2D2C6C;
  border-radius: 12px;
}

.Stats_container__NadyJ:hover .Stats_container-body-variation__TLjnV {
  background: #3A4396;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Stats/Stats.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,8BAAA;EACA,WAAA;EACA,WAAA;EACA,QAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;AADI;EACE,eAAA;EACA,iBAAA;AAGN;AADI;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,mBAAA;EACA,wBAAA;EACA,cAAA;AAGN;AAFM;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAIR;;AAGA;EACE,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,mBAAA;AAAF;;AAIA;EACE,mBAAA;EACA,YAAA;AADF","sourcesContent":[".container{\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #D8D8D8;\n  border-radius: 12px;\n  background: #FFFFFF;\n  color: #2D2C6C;\n  padding: 1.5rem 2rem 2rem 2rem;\n  width: 100%;\n  height: 8vh;\n  gap: 5px;\n  &-title{\n    font-weight: 500;\n  }\n  &-body{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    &-number{\n      font-size: 3rem;\n      font-weight: bold;\n    }\n    &-variation{\n      width: 60px;\n      height: 30px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      gap: 5px;\n      background: #EDFCF5;\n      border-radius: 8.57143px;\n      color: #149761;\n      img{\n        width: 10px;\n        height: 15px;\n        fill: #149761;\n      }\n    }\n  }\n}\n\n\n.container:hover{\n  background: #2D2C6C;\n  color: #FFFFFF;\n  border: 1px solid #2D2C6C;\n  border-radius: 12px;\n\n}\n\n.container:hover .container-body-variation{\n  background: #3A4396;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Stats_container__NadyJ`,
	"container-title": `Stats_container-title__A+QuE`,
	"container-body": `Stats_container-body__CRQjt`,
	"container-body-number": `Stats_container-body-number__O0nA5`,
	"container-body-variation": `Stats_container-body-variation__TLjnV`
};
export default ___CSS_LOADER_EXPORT___;
