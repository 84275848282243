import style from './Frame.module.scss';


interface IFrameProps {
    border?: string,
    borderRadius?: string,
    boxShadow?: string,
    children: any
}

const Frame = ({border, borderRadius, boxShadow, children}: IFrameProps) => {

    return(
        <div
            className={style['container']}
            style={{
                border: border && border,
                borderRadius: borderRadius && borderRadius,
                boxShadow: boxShadow && boxShadow
            }}
        >
            {children}
        </div>
    )
}

export default Frame