// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextEditor_wrapper__7MEWU {
  border: 1px solid #BCBCBC;
  border-radius: 15px;
  height: 60vh;
  display: flex;
  flex-direction: column;
}
.TextEditor_wrapper-input__WbuYY {
  padding: 1rem;
  height: 85%;
  width: calc(100% - 2rem);
  border-bottom: 1px solid #BCBCBC;
}
.TextEditor_wrapper-input__WbuYY:focus {
  outline: none;
}

.TextEditor_disable__wavKP {
  display: none;
}

.TextEditor_editor__Gdr27 {
  order: 1;
}

.TextEditor_toolbar-up__ehI5z {
  order: 0;
  border: 0 !important;
  border-top: 1px solid #F1F1F1 !important;
  border-radius: 0 0 15px 15px !important;
}

.TextEditor_toolbar-down__ZIkAH {
  order: 2;
  border: 0 !important;
  border-top: 1px solid #F1F1F1 !important;
  border-radius: 0 0 15px 15px !important;
}

.TextEditor_footer__Q\\+jme {
  margin: 1rem 0rem;
  display: flex;
  gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/IU/TextEditor/TextEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EAAe,sBAAA;AAEjB;AADE;EACE,aAAA;EACA,WAAA;EACA,wBAAA;EACA,gCAAA;AAGJ;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE,aAAA;AACF;;AACA;EACE,QAAA;AAEF;;AACA;EACE,QAAA;EACA,oBAAA;EACA,wCAAA;EACA,uCAAA;AAEF;;AACA;EACE,QAAA;EACA,oBAAA;EACA,wCAAA;EACA,uCAAA;AAEF;;AAAA;EACE,iBAAA;EACA,aAAA;EACA,SAAA;AAGF","sourcesContent":[".wrapper{\n  border: 1px solid #BCBCBC;\n  border-radius: 15px;\n  height: 60vh;\n  display: flex; flex-direction: column;\n  &-input{\n    padding: 1rem;\n    height: 85%;\n    width: calc(100% - 2rem);\n    border-bottom: 1px solid #BCBCBC;\n  }\n\n  &-input:focus{\n    outline: none;\n  }\n}\n\n.disable{\n  display: none;\n}\n.editor{\n  order:1;\n}\n\n.toolbar-up{\n  order: 0;\n  border: 0 !important;\n  border-top:1px solid #F1F1F1 !important;\n  border-radius: 0 0 15px 15px!important;\n}\n\n.toolbar-down{\n  order: 2;\n  border: 0 !important;\n  border-top:1px solid #F1F1F1 !important;\n  border-radius: 0 0 15px 15px!important;\n}\n.footer{\n  margin: 1rem 0rem;\n  display: flex;\n  gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TextEditor_wrapper__7MEWU`,
	"wrapper-input": `TextEditor_wrapper-input__WbuYY`,
	"disable": `TextEditor_disable__wavKP`,
	"editor": `TextEditor_editor__Gdr27`,
	"toolbar-up": `TextEditor_toolbar-up__ehI5z`,
	"toolbar-down": `TextEditor_toolbar-down__ZIkAH`,
	"footer": `TextEditor_footer__Q+jme`
};
export default ___CSS_LOADER_EXPORT___;
