import AuthHeader from "../components/AuthHeader/AuthHeader";

export default function AuthLayout({ children }: any) {
  return (
    <>
      <AuthHeader />
      {children}
    </>
  );
}
