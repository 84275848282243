import style from "./Pagination.module.scss";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

export const LIMIT_PAGINATION = 50;
const Pagination = ({
  page,
  setPage,
  isEmpty,
  totalOfItems,
  backgroundColor = "#F8F8F8",
}: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const nbPages = Math.ceil(totalOfItems / LIMIT_PAGINATION);
  const clickHandle = (direction) => {
    if (direction === "-") {
      if (page > 0) {
        setPage((p) => p - 1);
        navigate({
          pathname: location.pathname,
          search: `?${createSearchParams([["p", page]])}`,
        });
      }
    } else {
      if (totalOfItems) {
        if (page < nbPages - 1) {
          setPage((p) => p + 1);
        }
      } else {
        if (!isEmpty) {
          setPage((p) => p + 1);
        }
      }
      navigate({
        pathname: location.pathname,
        search: `?${createSearchParams([["p", page + 2]])}`,
      });
    }
  };

  const onChangeHandler = (e) => {
    setPage(e.target.value - 1);
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams([["p", e.target.value.toString()]])}`,
    });
  };

  const getPageContent = () => {
    let content: any = [];

    for (let i = 1; i <= nbPages; i++) {
      content.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return content;
  };

  return (
    <div
      className={style["container"]}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={style["nbPage"]}>
        {totalOfItems && (
          <div className={style[""]}>
            <select value={page + 1} onChange={onChangeHandler}>
              {getPageContent()}
            </select>
            <span>/ {nbPages} pages</span>
          </div>
        )}
        {!totalOfItems && <span>Page {page + 1}</span>}
      </div>
      <div className={style["buttons"]}>
        <div
          className={`${style["button"]} ${
            page <= 0 && style["button--disable"]
          }`}
          onClick={clickHandle.bind(false, "-")}
        >
          Précédent
        </div>
        <div
          className={`${style["button"]} ${
            (isEmpty || page >= nbPages - 1) && style["button--disable"]
          }`}
          onClick={clickHandle.bind(false, "+")}
        >
          Suivant
        </div>
      </div>
    </div>
  );
};

export default Pagination;
