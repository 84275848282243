import style from './InputText.module.scss'
import InputError from "../InputError/InputError";


interface IInputText {
    name: string,
    isCorrect: boolean,
    label?: string,
    placeholder?: string,
    customClass?: any,
    defaultValue?: string,
    message?: string,
    onChange?: any,
    inputRef?: any

}
const InputText = ({name, label, defaultValue, placeholder, customClass, isCorrect, message, onChange, inputRef}: IInputText) => {



    return(
        <div className={style['container']}>
            { label &&
                <label htmlFor={name}>{label}</label>
            }
            <input type="text" name={name} className={"input"} defaultValue={defaultValue} placeholder={placeholder && placeholder} onChange={onChange} ref={inputRef}/>
            {!isCorrect &&
                <InputError message={message ? message : `${label} est invalide.`}/>
            }
        </div>
    )
}

export default InputText